/* tslint:disable:no-console */
/* tslint:disable:no-parameter-reassignment */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { find } from 'lodash';
import * as moment from 'moment';

import { deploymentConfig } from '@src/client/config/deploymentConfig';
import { getFileSize } from '@submodules/cawst-angularjs/utils/file/getFileSize';

angular.module('app.controllers').controller('HwtsItemCtrl', [
  '$http',
  '$q',
  '$rootScope',
  '$sce',
  '$scope',
  '$state',
  '$stateParams',
  '$timeout',
  '$uibModal',
  '$window',
  'HWTS_Utilities',
  'SweetAlertUtil',
  function(
    $http,
    $q,
    $rootScope,
    $sce,
    $scope,
    $state,
    $stateParams,
    $timeout,
    $uibModal,
    $window,
    HWTS_Utilities,
    SweetAlertUtil,
  ) {
    // /////////////////////////////////////////////////////////////////////
    $rootScope.options = {};
    // /////////////////////////////////////////////////////////////////////
    $scope.replaceResearchItem = false;
    // /////////////////////////////////////////////////////////////////////
    $scope.loading = true;
    $scope.state = $state.$current.name;
    $scope.type = $scope.state.split(/[. ]+/)[1];
    $scope.action = $scope.state.split(/[. ]+/)[2];
    $scope.admin = true;
    $scope.typeText = toTitleCase($scope.type);
    $scope.headerText = toTitleCase($scope.action) + ' ' + formatTitle($scope.typeText);
    $scope.saving = false;
    // /////////////////////////////////////////////////////////////////////
    $scope.references = [];
    $scope.referencesHtml = '';
    $scope.summeroptions = {
      height: 400,
      toolbar: [
        ['insert', ['link']],
        ['headline', ['style']],
        ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'clear']],
        ['alignment', ['ul', 'ol']],
        ['edit', ['undo', 'redo']],
        ['view', ['fullscreen', 'codeview']],
      ],
    };
    // /////////////////////////////////////////////////////////////////////
    // push 'reference' to insert config on summeroptions toolbar variable
    $scope.summeroptionsWithReferences = JSON.parse(JSON.stringify($scope.summeroptions));
    $scope.summeroptionsWithReferences.toolbar[0][1].push('reference');
    // /////////////////////////////////////////////////////////////////////
    $rootScope.treatmentProcesses = [
      { key: 'Disinfection' },
      { key: 'Filtration' },
      { key: 'Sedimentation' },
      { key: 'Safe Storage' },
      { key: 'Source Protection' },
    ];
    // /////////////////////////////////////////////////////////////////////
    $rootScope.prodTechEffectiveness = [
      { key: 'Not Effective (<80%)', caption: 'Not Effective (<80%)' },
      { key: 'Somewhat Effective (>80%)', caption: 'Somewhat Effective (>80%)' },
      { key: 'Effective (>90%)', caption: 'Effective (>90%)' },
      { key: 'Very Effective (>99%)', caption: 'Very Effective (>99%)' },
    ];
    // /////////////////////////////////////////////////////////////////////
    $rootScope.waterQualityParameters = [
      {
        groupName: 'Chemicals',
        items: [
          { key: 'Arsenic', caption: 'Arsenic' },
          { key: 'Fluoride', caption: 'Fluoride' },
          { key: 'Heavy Metals', caption: 'Heavy Metals' },
          { key: 'Chromium', caption: '- Chromium' },
          { key: 'Lead', caption: '- Lead' },
          { key: 'Iron', caption: 'Iron' },
          { key: 'Manganese', caption: 'Manganese' },
          { key: 'Nitrate', caption: 'Nitrate' },
          { key: 'Pesticides', caption: 'Pesticides' },
          { key: 'Salt', caption: 'Salt' },
        ],
      },
      {
        groupName: 'Microbiological',
        items: [
          { key: 'Bacteria', caption: 'Bacteria' },
          { key: 'Cholera', caption: '- Cholera' },
          { key: 'Helminths', caption: 'Helminths' },
          { key: 'Protozoa', caption: 'Protozoa' },
          { key: 'Cryptosporidium', caption: '- Cryptosporidium' },
          { key: 'Giardia', caption: '- Giardia' },
          { key: 'Toxoplasma oocysts', caption: '- Toxoplasma oocysts' },
          { key: 'Viruses', caption: 'Viruses' },
        ],
      },
      {
        groupName: 'Physical',
        items: [
          { key: 'Colour', caption: 'Colour' },
          { key: 'Hardness', caption: 'Hardness' },
          { key: 'Odour', caption: 'Odour' },
          { key: 'Organic matter', caption: 'Organic matter' },
          { key: 'pH', caption: 'pH' },
          { key: 'Suspended particles', caption: 'Suspended particles' },
          { key: 'Taste', caption: 'Taste' },
          { key: 'Turbidity', caption: 'Turbidity' },
        ],
      },
    ];
    // /////////////////////////////////////////////////////////////////////
    $scope.researchTypes = [
      'Artwork',
      'Audiovisual',
      'Bill',
      'Book',
      'Book Chapter',
      'Broadcast',
      'Case',
      'Chart',
      'Classical',
      'Conference Paper',
      'Conference Proceedings',
      'Database',
      'Film',
      'Government Report',
      'Hearing',
      'Journal',
      'Journal Article',
      'Legal Ruling',
      'Magazine Article',
      'Manuscript',
      'Map',
      'Miscellaneous',
      'Miscellaneous Section',
      'Newspaper Article',
      'Patent',
      'Personal',
      'Presentation',
      'Report',
      'Software',
      'Statute',
      'Thesis',
      'Unpublished',
      'Web Article',
      'Web project page',
      'Web service',
      'Website',
    ];
    // /////////////////////////////////////////////////////////////////////
    $scope.bsfModels = [
      'Concrete square - Version 8',
      'Concrete square - Version 9',
      'Concrete square - Version 10',
      'Concrete round (bush proof)',
      'Plastic',
      'Aluminium round',
      'Hydraid',
      'Kanchan',
      'PVC',
      'Stainless Steel Round',
    ];
    // /////////////////////////////////////////////////////////////////////
    $scope.producerTypes = ['Mold', 'Sand'];
    // /////////////////////////////////////////////////////////////////////
    $scope.fileListFocusIndex = 0;
    $rootScope.item = null;
    // /////////////////////////////////////////////////////////////////////
    $timeout(() => {
      if ($stateParams.id) {
        $scope.getItem($stateParams.id);
      } else {
        if ($scope.type === 'prodtech') {
          $scope.waitingForProdTechType = true;
        }
        $scope.loading = false;
        $rootScope.item = { publicationStatus: 'Draft', deleted: false };
        $scope.resetPristine();
      }
    });
    // /////////////////////////////////////////////////////////////////////
    $scope.getItem = function(itemId) {
      HWTS_Utilities.get($scope.type, itemId, (response) => {
        if (response) {
          $scope.loadItem(response);
          $scope.loading = false;
        } else {
          SweetAlertUtil.alert('Item unavailable');
        }
        $scope.resetPristine();
      });
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.resetPristine = function() {
      const itemFormScope = angular.element(window.document.getElementById('itemForm')).scope();
      if (itemFormScope) {
        // @ts-ignore
        itemFormScope.resetPristine();
      }
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.setAsProjectOwner = function(index) {
      if (!$rootScope.item.ownerId) $rootScope.item.ownerId = [];

      $rootScope.item.ownerId.push($rootScope.item.claimedBy[index]);
      $rootScope.item.claimedBy.splice(index, 1);
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.removeProjectOwner = function(index) {
      if (!$rootScope.item.claimedBy) $rootScope.item.claimedBy = [];

      $rootScope.item.claimedBy.push($rootScope.item.ownerId[index]);
      $rootScope.item.ownerId.splice(index, 1);
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onClickProdTechType = function(type) {
      $scope.waitingForProdTechType = false;
      $rootScope.item.type = type;
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.loadItem = function(item) {
      $rootScope.item = $scope.loadDateProperties(item);

      if ($rootScope.imageController) $rootScope.imageController.onLoadItem();

      if ($rootScope.item.location) $rootScope.item.location = [$rootScope.item.location];

      if ($rootScope.item.organizationId) {
        $rootScope.item.organizationId = [$rootScope.item.organizationId];
      }

      $scope.checkTagChange();
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.loadDateProperties = function(item) {
      for (const i of Object.keys($rootScope.dateProperties)) {
        const prop = $rootScope.dateProperties[i];
        if (item[prop]) {
          item[prop] = moment(item[prop]).toDate();
        }
      }
      return item;
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.saveDateProperties = function(item) {
      for (const i of Object.keys($rootScope.dateProperties)) {
        const prop = $rootScope.dateProperties[i];
        if (item[prop]) {
          item[prop] = moment(item[prop]).toISOString();
        }
      }
      return item;
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onRemoveAuthor = function(author, $index) {
      if (!$window.confirm('Are you sure you want to remove this author?')) return;
      $rootScope.item.authors.splice($index, 1);
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onAddAuthor = function() {
      if (!$rootScope.item.authors) $rootScope.item.authors = [];
      $rootScope.item.authors.push({ firstName: '', lastName: '', initials: '' });
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.delete = function() {
      if (
        !$window.confirm(
          'Are you sure you want to delete this item? This action is not reversible.',
        )
      ) {
        return;
      }
      HWTS_Utilities.delete($scope.type, $rootScope.item, (response) => {
        if (!response) SweetAlertUtil.alert('Unable to delete item.');
        else $state.go('^', {}, { reload: true });
      });
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onTreatmentCapacityRemove = function($index) {
      if (!$window.confirm('Are you sure you want to remove this treatment capacity entry?')) {
        return;
      }
      $rootScope.item.technicalInformation.treatmentCapacity.splice($index, 1);
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onTreatmentCapacityAdd = function() {
      if (!$rootScope.item.technicalInformation) $rootScope.item.technicalInformation = {};
      if (!$rootScope.item.technicalInformation.treatmentCapacity) {
        $rootScope.item.technicalInformation.treatmentCapacity = [];
      }
      $rootScope.item.technicalInformation.treatmentCapacity.push({
        parameter: '',
        effectiveness: '',
        laboratoryEfficiency: [],
        fieldEfficiency: [],
      });
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.showEfficiencyDialog = function(type, parentIndex, index, oldEntry) {
      let entry = null;
      if (oldEntry) {
        entry = JSON.parse(JSON.stringify(oldEntry));
      }

      const efficiencyDialog = $uibModal.open({
        size: 'lg',
        templateUrl: 'views/hwts/templates/efficiencyDialog.html',
        controller: 'HwtsEfficiencyDialogCtrl',
        resolve: {
          entry() {
            return entry;
          },
        },
      });

      efficiencyDialog.result.then(
        (result) => {
          if (index >= 0) {
            $rootScope.item.technicalInformation.treatmentCapacity[parentIndex][type][
              index
            ] = result;
          } else {
            $rootScope.item.technicalInformation.treatmentCapacity[parentIndex][type].push(result);
          }
          // $scope.refreshReferences();
        },
        () => {
          // $scope.refreshReferences();
        },
      );
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onContactRemove = function(contact, $index) {
      if (!$window.confirm('Are you sure you want to remove this contact entry?')) {
        return;
      }
      $rootScope.item.contacts.splice($index, 1);
    };

    $scope.onContactAdd = function() {
      if (!$rootScope.item.contacts) {
        $rootScope.item.contacts = [];
      }
      $rootScope.item.contacts.push({ fullName: '', email: '', organization: '' });
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onWebsiteRemove = function(website, $index) {
      if (!$window.confirm('Are you sure you want to remove this website entry?')) return;
      $rootScope.item.websites.splice($index, 1);
    };

    $scope.onWebsiteAdd = function() {
      if (!$rootScope.item.websites) {
        $rootScope.item.websites = [];
      }
      $rootScope.item.websites.push({ url: '', title: '' });
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onPhoneNumberRemove = function(phoneNumber, $index) {
      if (!$window.confirm('Are you sure you want to remove this phone number entry?')) {
        return;
      }
      $rootScope.item.phoneNumbers.splice($index, 1);
    };

    $scope.onPhoneNumberChange = function(phoneNumber, $index) {
      $rootScope.item.phoneNumbers[$index] = phoneNumber;
    };

    $scope.onPhoneNumberAdd = function() {
      if (!$rootScope.item.phoneNumbers) {
        $rootScope.item.phoneNumbers = [];
      }
      $rootScope.item.phoneNumbers.push('');
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onEmailRemove = function(email, $index) {
      if (!$window.confirm('Are you sure you want to remove this email entry?')) {
        return;
      }
      $rootScope.item.emails.splice($index, 1);
    };

    $scope.onEmailChange = function(email, $index) {
      $rootScope.item.emails[$index] = email;
    };

    $scope.onEmailAdd = function() {
      if (!$rootScope.item.emails) {
        $rootScope.item.emails = [];
      }
      $rootScope.item.emails.push('');
    };
    // /////////////////////////////////////////////////////////////////////
    // Resource Reference
    $scope.resourceReference = {
      addingResource: false,

      newResource: {},

      add() {
        this.addingResource = true;
        if (!$rootScope.item.resourceReferences) {
          $rootScope.item.resourceReferences = [];
        }
      },

      cancel() {
        this.addingResource = false;
        this.newResource = {};
      },

      remove(reference, index) {
        console.log(reference, index);
        if (!$window.confirm('Are you sure you want to remove this reference entry?')) {
          return;
        }
        $rootScope.item.resourceReferences.splice(index, 1);
        console.log($rootScope.item.resourceReferences);
      },

      save() {
        $rootScope.item.resourceReferences.push({
          title: this.newResource.title,
          url: this.newResource.url,
        });
        this.newResource = {};
        this.addingResource = false;
        console.log($rootScope.item.resourceReferences);
      },
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.getCoordinates = function() {
      $http
        .get(
          deploymentConfig.api.baseUrls.hwts +
            '/geolocation/guess?countryCode=' +
            $rootScope.item.locations[0].countryCode +
            '&city=' +
            $rootScope.item.locations[0].city,
        )
        .then(
          (response) => {
            updateFieldsForLatLng(response.data);
          },
          (_err) => {
            updateFieldsForLatLng(null);
          },
        );
    };
    // /////////////////////////////////////////////////////////////////////
    function updateFieldsForLatLng(entry) {
      if (entry && $rootScope.item.locations[0].geolocation) {
        $rootScope.item.locations[0].geolocation.coordinates[0] = entry.coordinates[0];
        $rootScope.item.locations[0].geolocation.coordinates[1] = entry.coordinates[1];
      } else {
        $rootScope.item.locations[0].geolocation = {
          coordinates: [],
        };
        updateFieldsForLatLng(entry);
      }
    }
    // /////////////////////////////////////////////////////////////////////
    $scope.toggleBsfSelection = function(bsfModel) {
      if (!$rootScope.item.bsfModels) {
        $rootScope.item.bsfModels = [];
      }
      const index = $rootScope.item.bsfModels.indexOf(bsfModel);
      if (index !== -1) {
        $rootScope.item.bsfModels.splice(index, 1);
      } else {
        $rootScope.item.bsfModels.push(bsfModel);
      }
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onDoiSearch = function(search) {
      $http.get(deploymentConfig.api.baseUrls.hwts + '/lookup?doi=' + search).then(
        (response) => {
          updateFieldsForDoiEntry(response.data);
        },
        (_err) => {
          updateFieldsForDoiEntry(null);
        },
      );

      function updateFieldsForDoiEntry(entry) {
        if (!entry) {
          $rootScope.item = null;
          $scope.doiSearchInvalid = true;
          return;
        }
        $scope.doiSearchInvalid = false;
        $rootScope.item = $rootScope.item || {};

        if ($rootScope.item.abstract) {
          delete $rootScope.item.abstract;
        }
        if ($rootScope.item.pubmed) {
          delete $rootScope.item.pubmed;
        }

        for (const key of Object.keys(entry)) {
          $rootScope.item[key] = entry[key];
        }

        if ($rootScope.item.publicationDate) {
          $rootScope.item.publicationDate = new Date($rootScope.item.publicationDate);
        }

        if ($scope.researchTypes.indexOf(entry.publicationType) === -1) {
          $scope.researchTypes.push(entry.publicationType);
        }
      }
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onPmidSearch = function(search) {
      $http.get(deploymentConfig.api.baseUrls.hwts + '/lookup?pmid=' + search).then(
        (response) => {
          updateFieldsForPmidEntry(response.data);
        },
        (_err) => {
          updateFieldsForPmidEntry(null);
        },
      );

      function updateFieldsForPmidEntry(entry) {
        if (!entry) {
          $rootScope.item = null;
          $scope.pmidSearchInvalid = true;
          return;
        }
        $scope.pmidSearchInvalid = false;
        $rootScope.item = $rootScope.item || {};

        if ($rootScope.item.abstract) {
          delete $rootScope.item.abstract;
        }

        if ($rootScope.item.url) {
          delete $rootScope.item.url;
        }

        for (const key of Object.keys(entry)) {
          $rootScope.item[key] = entry[key];
        }
        if ($rootScope.item.publicationDate) {
          $rootScope.item.publicationDate = new Date($rootScope.item.publicationDate);
        }

        if ($scope.researchTypes.indexOf(entry.publicationType) === -1) {
          $scope.researchTypes.push(entry.publicationType);
        }
      }
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onManualEntry = function() {
      $scope.doiSearch = null;
      $scope.doiSearchInvalid = null;
      $scope.pmidSearch = null;
      $scope.pmidSearchInvalid = null;
      $rootScope.item = null;
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.getEfficiencyEntry = function(efficiencyEntry) {
      const value = efficiencyEntry.value;
      const refIds = [];
      for (const i of Object.keys(efficiencyEntry.references)) {
        refIds.push('*');
      }
      return value + ' (' + refIds.join(',') + ')';
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.showReferenceDialog = function(ref, cb) {
      const referenceDialog = $uibModal.open({
        templateUrl: 'views/hwts/templates/summernote-reference-dialog.html',
        controller: 'SummernoteReferenceDialogCtrl',
        resolve: {
          referenceId() {
            return ref.referenceId;
          },
          referenceGuid() {
            return ref.referenceGuid;
          },
          referenceType() {
            return ref.referenceType;
          },
        },
      });
      referenceDialog.result.then(
        (reference) => {
          cb(reference);
        },
        () => {
          cb(null);
        },
      );
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.save = function(item) {
      $scope.saving = true;

      item = JSON.parse(JSON.stringify($rootScope.item));

      if (item.location) {
        if (item.location.length > 1) {
          SweetAlertUtil.alert('The "Location" field is restrictied to only one location.');
          return;
        }
        if (item.location.length === 1) item.location = item.location[0];
        else item.location = null;
      }

      item = $scope.saveDateProperties(item);

      // console.log('-----> ITEM SAVE',item);

      if ($scope.action === 'edit') {
        HWTS_Utilities.modify($scope.type, item, (response) => {
          if (response) {
            angular
              .element(window.document.getElementById('itemForm'))
              .scope()
              // @ts-ignore
              .clearCachedEdits();
            $rootScope.allowNavigation();
            $state.go('^', {}, { reload: true });
          } else {
            SweetAlertUtil.alert('Unable to save changes');
          }
          $scope.saving = false;
        });
      }
      if ($scope.action === 'add') {
        HWTS_Utilities.add($scope.type, item, (response) => {
          if (response) {
            angular
              .element(window.document.getElementById('itemForm'))
              .scope()
              // @ts-ignore
              .clearCachedEdits();
            $rootScope.allowNavigation();
            $state.go('^', {}, { reload: true });
          } else {
            SweetAlertUtil.alert('Unable to save changes');
          }
          $scope.saving = false;
        });
      }
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.searchPublications = function(value) {
      if (value.indexOf('10.') === 0) {
        return $http
          .get('https://api.crossref.org/works/' + value, { skipAuthorization: true })
          .then((response) => {
            const hits = [response.data.message];
            return hits;
          });
      }
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.getUser = function(value) {
      return $http
        .get(deploymentConfig.api.baseUrls.hwts + '/user/search?title=' + value)
        .then((response) => {
          for (const i of Object.keys(response.data)) {
            response.data[i].name = response.data[i].lastName + ', ' + response.data[i].firstName;
          }
          return response.data;
        });
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.checkTagChange = function() {
      $rootScope.hasBsfTag = true;
      if (!find($rootScope.item.prodTechs, { id: '074f5f20-1070-40c7-b581-d8e9935e85fc' })) {
        $rootScope.hasBsfTag = false;
        $rootScope.item.bsfModel = null;
      }
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.searchItems = function(type, value) {
      return $http
        .get(deploymentConfig.api.baseUrls.hwts + '/' + type + '/search?title=' + value)
        .then((response) => {
          return response.data;
        });
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.getKeyItems = function(value) {
      return $http
        .get(deploymentConfig.api.baseUrls.hwts + '/keyItems/search?title=' + value)
        .then((response) => {
          return response.data;
        });
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.getTheme = function(value) {
      return $http
        .get(deploymentConfig.api.baseUrls.hwts + '/theme/search?title=' + value)
        .then((response) => {
          const list = [];
          for (const i of Object.keys(response.data)) {
            const item = response.data[i];
            list.push({
              id: item.id,
              title: item.title,
            });
          }
          return list;
        });
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.getOrganization = function(value, query) {
      return $http
        .get(deploymentConfig.api.baseUrls.hwts + '/organization/search?title=' + query)
        .then((response) => {
          const list = [];
          for (const i of Object.keys(response.data)) {
            const item = response.data[i];
            let title = item.name;
            if (
              item.location &&
              item.location.countryName &&
              item.location.countryName.length > 0
            ) {
              title = item.name + ' (' + item.location.countryName + ')';
            }
            list.push({
              id: item.id,
              title,
            });
          }
          return response.data;
        });
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.getLocation = HWTS_Utilities.getLocation;
    // /////////////////////////////////////////////////////////////////////
    $scope.getFileSize = getFileSize;
    // /////////////////////////////////////////////////////////////////////
    $scope.getFilenameFromUri = function(uri) {
      if (uri.lastIndexOf('-') !== -1) {
        uri = uri.substr(0, uri.lastIndexOf('-'));
      }
      uri = uri.substr(uri.lastIndexOf('/') + 1);
      return decodeURIComponent(uri);
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.formatProgress = function(decimal) {
      return Math.round(decimal * 100) + '%';
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.formatUploadTime = function(str) {
      const date = moment(str);
      return date.format('LLLL');
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.onPublicationDateChanged = function() {
      const bundle = [];
      const year = parseInt($rootScope.publicationDateYear, 10);
      if (year) {
        bundle.push(year);
        const month = parseInt($rootScope.publicationDateMonth, 10);
        if (month) {
          bundle.push(month - 1);
          const day = parseInt($rootScope.publicationDateDay, 10);
          if (day) {
            bundle.push(day);
          }
        }
        $rootScope.item.publicationDate = moment.utc(bundle).toDate();
      }
    };
    // /////////////////////////////////////////////////////////////////////
    function toTitleCase(str) {
      return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    // /////////////////////////////////////////////////////////////////////
    function formatTitle(str) {
      if (str === 'Casestudy') return 'Case Study';
      if (str === 'Prodtech') return 'Product/Technology';
      return str;
    }
    // /////////////////////////////////////////////////////////////////////
    function getCountryCodes(locations) {
      const countries = [];
      for (const i of Object.keys(locations)) {
        const location = locations[i];
        let countryCode = '';
        for (const j of Object.keys(location.address_components)) {
          const comp = location.address_components[j];
          const types = comp.types;
          if (types && types.length > 0 && types[0] === 'country') {
            countryCode = comp.short_name;
            break;
          }
        }
        countries.push(countryCode);
      }
      return countries;
    }
    // /////////////////////////////////////////////////////////////////////
    $scope.cancelForm = () => {
      SweetAlertUtil.confirm('Are you sure you want to cancel? All changes will be lost.').then(
        (confirm) => {
          if (confirm) {
            const uiReference = 'hwts.' + $scope.type;
            $state.go(uiReference);
          }
        },
      );
    };
    // /////////////////////////////////////////////////////////////////////
    $scope.removeRelatedItemByTag = (tag) => {
      $rootScope.item.relatedItems = $rootScope.item.relatedItems.filter(
        (itemId) => itemId !== tag.id,
      );
    };
  },
]);
