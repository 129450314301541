/* tslint:disable:no-console */
/* tslint:disable:only-arrow-functions */
/* tslint:disable:ter-prefer-arrow-callback */

import { getPathnameFromUrl } from '@src/submodules/cawst-core/util/getPathnameFromUrl';

import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.services') //
  .service('HWTS_Utilities', [
    '$http',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    function($http, $rootScope, $state, $window, authService) {
      function httpWrapper(method, command, cb, data) {
        $http({
          method,
          url: deploymentConfig.api.baseUrls.hwts + command,
          data,
        }).then(
          function successCallback(response) {
            if (cb) {
              cb(response.data);
            }
          },
          function errorCallback(response) {
            if (response.status === 401) {
              $state.go('signin');
            } else if (cb) {
              cb(null);
            }
          },
        );
      }

      function getLocation(query) {
        // Documentatin: https://developers.google.com/maps/documentation/javascript/geocoding
        return $http
          .get('https://maps.googleapis.com/maps/api/geocode/json', {
            params: {
              address: query,
              key: 'AIzaSyAavS7Z6mN7rNVbHXx_boMizh_5WZPlr5g',
              sensor: false,
            },
            // skipAuthorization: true,
          })
          .then((response) => {
            const querySplitArray = query.split(',');
            const ret = [];
            const results = response.data.results;

            for (const i in results) {
              if (results.hasOwnProperty(i)) {
                const result = results[i];
                const newItem: any = {};

                for (const j in result.address_components) {
                  if (result.address_components.hasOwnProperty(j)) {
                    const comp = result.address_components[j];

                    if (comp.types && comp.types.indexOf('country') !== -1) {
                      newItem.countryCode = comp.short_name;
                      newItem.countryName = comp.long_name;
                      break;
                    }
                  }
                }

                newItem.raw = result;
                newItem.displayName = result.formatted_address;
                newItem.geolocation = {
                  latitude: result.geometry.location.lat,
                  longitude: result.geometry.location.lng,
                };

                // prettier-ignore
                if (querySplitArray.length === 2 && !isNaN(parseInt(querySplitArray[0], 10))) {
                  newItem.displayName = '(' + query + ') ' + result.formatted_address;
                }

                ret.push(newItem);
              }
            }

            return ret;
          });
      }

      return {
        // /////////////////////////////////////////////////////////////////////
        getLocation,
        // /////////////////////////////////////////////////////////////////////
        searchProdTech(title, cb) {
          // @ts-ignore
          httpWrapper('GET', '/prodtech/search?title=' + title, cb);
        },
        // /////////////////////////////////////////////////////////////////////
        getUriFromUrl(url) {
          return getPathnameFromUrl(url);
        },

        getUrlFromUri(uri) {
          return deploymentConfig.resources.assetBaseUrls.hwts + uri;
        },
        // /////////////////////////////////////////////////////////////////////
        downloadFile(uri) {
          $window.open(
            deploymentConfig.api.baseUrls.hwts +
              '/file?uri=' +
              uri +
              '&authorization=' +
              authService.retrieveAccessToken(),
          );
        },

        downloadUploadedFile(uri) {
          $window.open(
            deploymentConfig.api.baseUrls.hwts +
              '/file?uri=' +
              uri +
              '&authorization=' +
              authService.retrieveAccessToken() +
              '&uploaded=true',
          );
        },

        addFileUpload(filename, cb) {
          // @ts-ignore
          httpWrapper('PUT', '/file?filename=' + filename, cb);
        },

        removeUploadedFile(uri, cb) {
          // @ts-ignore
          httpWrapper('DELETE', '/file?uri=' + uri, cb);
        },
        // /////////////////////////////////////////////////////////////////////
        updatePubStatus(statusObj, type, item, cb) {
          httpWrapper('POST', '/' + type + '/' + item.id + '/publication_status', cb, statusObj);
        },

        getAll(type, cb) {
          // @ts-ignore
          httpWrapper('GET', '/' + type + '/all', cb);
        },

        getPage(type, pageNumber, itemsPerPage, filter, includeDeletedItems, cb) {
          // @ts-ignore
          httpWrapper(
            'GET',
            '/' +
              type +
              '/paginate/' +
              pageNumber +
              '/' +
              itemsPerPage +
              '?filter=' +
              filter +
              '&includeDeletedItems=' +
              includeDeletedItems,
            cb,
          );
        },

        get(type, itemId, cb) {
          // @ts-ignore
          httpWrapper('GET', '/' + type + '/' + itemId, cb);
        },

        add(type, item, cb) {
          httpWrapper('PUT', '/' + type, cb, item);
        },

        modify(type, item, cb) {
          // console.log(`HWTS_Utilities.modify: ${type}\n`, item);
          httpWrapper('POST', '/' + type + '/' + item.id, cb, item);
        },

        delete(type, item, cb) {
          // @ts-ignore
          httpWrapper('DELETE', '/' + type + '/' + item.id, cb);
        },
        // /////////////////////////////////////////////////////////////////////
        getImageUrl(imageUri) {
          return deploymentConfig.resources.imageBaseUrls.hwts + '/' + imageUri;
        },
        // /////////////////////////////////////////////////////////////////////
      };
    },
  ]);
