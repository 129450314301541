/* tslint:disable:no-parameter-reassignment */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { has } from 'lodash';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.services') //
  .service('VWetStats', [
    '$http',
    '$state',
    '$window',
    'authService',
    function($http, $state, $window, authService) {
      const csvExportViews = {
        users: 'email,firstName,lastName,userOrg,userCountry',
        downloads:
          'fileName,resourceTitle,userFirstName,userLastName,userEmail,userOrg,userCountryCode,userCountry,timestamp',
        group: 'group,reduction',
      };

      function httpWrapper(method, command, cb) {
        $http({
          method,
          url: deploymentConfig.api.baseUrls.vwet + command,
        }).then(
          (response) => {
            if (cb) {
              cb(response.data);
            }
          },
          (response) => {
            if (response.status === 401) {
              $state.go('signin');
            } else {
              if (cb) cb(null);
            }
          },
        );
      }

      function getDownloadFilters(filters) {
        let route = '';
        if (filters) {
          if (filters.groupby) {
            route += '&groupby=' + filters.groupby;
          }
          if (filters.orderby) {
            route += '&orderby=' + filters.orderby;
          }
          if (has(filters, 'country') && filters.country && filters.country.length > 0) {
            route += '&filters[country]=' + filters.country;
          }
          if (has(filters, 'countries') && filters.countries && filters.countries.length > 0) {
            route +=
              '&filters[countries]=' + encodeURIComponent(filters.countries.split('\n').join(';'));
          }
          if (has(filters, 'dateStart') && filters.dateStart.length > 0) {
            route += '&filters[dateStart]=' + filters.dateStart;
          }
          if (has(filters, 'dateEnd') && filters.dateEnd.length > 0) {
            route += '&filters[dateEnd]=' + filters.dateEnd;
          }
          if (has(filters, 'email') && filters.email.length > 0) {
            route += '&filters[email]=' + filters.email;
          }
          if (has(filters, 'region') && filters.region.length > 0) {
            route += '&filters[region]=' + filters.region;
          }
          if (has(filters, 'orderBy') && filters.orderBy.length > 0) {
            route += '&filters[orderby]=' + filters.orderBy;
          }
          if (has(filters, 'orgName') && filters.orgName.length > 0) {
            route += '&filters[orgName]=' + filters.orgName;
          }
        }
        return route;
      }

      function getUserFilters(filters) {
        let route = '';
        if (filters) {
          if (filters.groupby) {
            route += '&groupby=' + filters.groupby;
          }
          if (filters.orderby) {
            route += '&orderby=' + filters.orderby;
          }
          if (has(filters, 'dateStart') && filters.dateStart.length > 0) {
            route += '&filters[dateStart]=' + filters.dateStart;
          }
          if (has(filters, 'dateEnd') && filters.dateEnd.length > 0) {
            route += '&filters[dateEnd]=' + filters.dateEnd;
          }
          if (has(filters, 'email') && filters.email.length > 0) {
            route += '&filters[email]=' + filters.email;
          }
          if (has(filters, 'country') && filters.country.length > 0) {
            route += '&filters[userCountry]=' + filters.country;
          }
          if (has(filters, 'countries') && filters.countries && filters.countries.length > 0) {
            route +=
              '&filters[countries]=' + encodeURIComponent(filters.countries.split('\n').join(';'));
          }
          if (has(filters, 'orgName') && filters.orgName.length > 0) {
            route += '&filters[orgName]=' + filters.orgName;
          }
        }
        return route;
      }

      return {
        // /////////////////////////////////////////////////////////////////////
        getDownloadStats(page, pageSize, filters, cb) {
          let route = '/dashboard/downloads?p=' + page + '&s=' + pageSize;
          filters = getDownloadFilters(filters);
          route += filters;
          httpWrapper('GET', route, cb);
        },
        // /////////////////////////////////////////////////////////////////////
        getUserStats(page, pageSize, filters, cb) {
          let route = '/dashboard/users?p=' + page + '&s=' + pageSize;
          filters = getUserFilters(filters);
          route += filters;
          httpWrapper('GET', route, cb);
        },
        // /////////////////////////////////////////////////////////////////////
        generateCsv(segment, view, filters) {
          const route =
            deploymentConfig.api.baseUrls.vwet +
            '/dashboard/' +
            segment +
            '?csvFields=' +
            csvExportViews[view];
          const auth = '&authorization=' + authService.retrieveAccessToken();

          if (segment === 'downloads') {
            $window.open(route + getDownloadFilters(filters) + auth);
          } else if (segment === 'users') {
            $window.open(route + getUserFilters(filters) + auth);
          }
        },
        // /////////////////////////////////////////////////////////////////////
      };
    },
  ]);
