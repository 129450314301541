/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { find } from 'lodash';
import * as moment from 'moment';

angular.module('app.controllers').controller('WavemakersReportCtrl', [
  '$http',
  '$scope',
  '$state',
  '$window',
  'Notification',
  'WavemakersProjects',
  function($http, $scope, $state, $window, Notification, WavemakersProjects) {
    // /////////////////////////////////////////////////////////////////////////
    const currentProjectId = $state.params.id || '';
    // /////////////////////////////////////////////////////////////////////////
    function init() {
      $scope.closeReport = {};
      $scope.wmResources = WavemakersProjects.wmResources;
      $scope.errorMessage = '';
      $scope.addActionToggleBtnMsg = 'Add an action or event';
      $scope.today = moment().format('YYYY-MM-DD');
      $scope.loading = true;
      $scope.addnewmode = true;

      WavemakersProjects.getAllWmProjects((data) => {
        if (data) {
          $scope.project = find(data, { id: currentProjectId });
          $scope.closeReportTitle = 'Add a Close Report For: ';
          $scope.projectTitle = $scope.project.title;
          if (!$scope.project.closeReportID) {
            $scope.closeReport.projectID = $scope.project.id;
            $scope.closeReport.date_submitted = null;
            $scope.closeReport.total_reached = 0;
            $scope.closeReport.fundraise_amount = 0;
            $scope.closeReport.fundraise_desc = '';
            $scope.closeReport.watercons_litres_saved = 0;
            $scope.closeReport.watercons_desc = '';
            $scope.closeReport.includedWaterCons = false;
            $scope.closeReport.includedFundraising = false;
            $scope.closeReport.last_modified = '';
          } else {
            $scope.closeReportTitle = 'Modify Close Report For: ';
            $scope.addnewmode = false;
            WavemakersProjects.getWmCloseReports((wmData) => {
              if (wmData) {
                $scope.closeReport = find(wmData, { id: $scope.project.closeReportID });
              }
            });
          }
          $scope.closeReport.actions = [];
          $scope.newAction = {};
          $scope.closeReport.partners = [];
          $scope.newPartner = {};
          $scope.addingAction = false;
          $scope.addingPartner = false;
          $scope.loading = false;
        }
      });
    }
    // /////////////////////////////////////////////////////////////////////////
    $scope.toggleAddActionForm = function() {
      $scope.addingAction = !$scope.addingAction;
      if ($scope.addingAction === true) {
        $scope.addActionToggleBtnMsg = 'Cancel add an action';
      } else {
        $scope.addActionToggleBtnMsg = 'Add an action or event';
      }
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.cancelAddAction = function() {
      $scope.addingAction = false;
      $scope.addActionToggleBtnMsg = 'Add an action or event';
      $window.scrollTo(0, 0);
    };
    // ///////////////////////////////////////////////////////////////////////
    $scope.addActionItem = function() {
      const newAction = {
        action_types: $scope.newAction.action_types || [],
        adults_reached: $scope.newAction.adults_reached || 0,
        date_ended: $scope.newAction.date_ended ? $scope.newAction.date_ended.toISOString() : '',
        date_started: $scope.newAction.date_started
          ? $scope.newAction.date_started.toISOString()
          : '',
        description: $scope.newAction.description || '',
        fundraise_amount: $scope.newAction.fundraise_amount || 0,
        project_types: $scope.newAction.project_types || [],
        resources_used: $scope.newAction.resources_used || [],
        title: $scope.newAction.title || '',
        youth_reached: $scope.newAction.youth_reached || 0,
      };

      $scope.closeReport.actions.push(newAction);
      $scope.addingAction = false;
      $scope.newAction = {};
      $scope.addActionToggleBtnMsg = 'Add an action or event';
      $window.scrollTo(0, 0);
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.addPartner = function() {
      const newPartner = {
        org: $scope.newPartner.org || '',
        url: $scope.newPartner.url || '',
      };
      $scope.closeReport.partners.push(newPartner);
      $scope.newPartner = {};
      $scope.addingPartner = false;
    };
    // ////////////////////////////////////////////////////////////////////////
    // Submit Close Report
    $scope.submitCloseReport = function() {
      const closeReport = $scope.closeReport;
      const project = $scope.project;

      if (!$scope.addnewmode && project.closeReportID) {
        closeReport.last_modified = $scope.today;
        WavemakersProjects.updateWmCloseReport(closeReport, () => {
          Notification.success('Report updated');
          $state.go('wmprojects', { reload: true });
          $window.location.reload(true);
        });
      } else {
        closeReport.date_created = moment().toISOString();
        closeReport.date_submitted = $scope.today;
        closeReport.status = 'submitted';
        WavemakersProjects.addWmCloseReport(closeReport, (id) => {
          project.closeReportID = id;
          WavemakersProjects.updateWmProject(project, () => {
            Notification.success('Report saved');
            $state.go('wmprojects', { reload: true });
            $window.location.reload(true);
          });
        });
      }
    };
    // /////////////////////////////////////////////////////////////////////////
    // # MISC Form Stuff
    $scope.open = function(cal, $event) {
      $scope.status[cal].opened = true;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.status = {
      start: {
        opened: false,
      },
      end: {
        opened: false,
      },
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.dateOptions = {
      formatYear: 'yyyy',
      startingDay: 1,
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.exitAdd = function() {
      $scope.addnewmode = false;
      $scope.projectTitle = '';
      $scope.closeReport = {};
      $state.go('wmprojects', { reload: true });
    };
    // /////////////////////////////////////////////////////////////////////////
    init();
    // /////////////////////////////////////////////////////////////////////////
  },
]);
