/*
Facilitates dialogs to work with ui-router and states.
For the most part, you won't need to make any changes to this file.
Most of the edits would be in app-config.js to add dialogStateProvider.state()
*/

/* tslint:disable:no-this-assignment */

angular.module('app').provider('dialogState', [
  '$stateProvider',
  function($stateProvider) {
    const provider = this;
    this.$get = () => {
      return provider;
    };
    this.state = (stateName, options) => {
      let modalInstance;
      $stateProvider.state(stateName, {
        url: options.url,
        onEnter($uibModal, $state) {
          modalInstance = $uibModal.open(options);
          modalInstance.result.finally(() => {
            modalInstance = null;
            if ($state.$current.name === stateName) {
              $state.go('^');
            }
          });
        },
        onExit() {
          if (modalInstance) {
            modalInstance.close();
          }
        },
      });
    };
  },
]);
