// ====================================================================
// Cawst Core Utilities - Get File Path from URL Function
// --------------------------------------------------------------------
// Created by: Alex Madsen
// Date: 2019-01-31
// ====================================================================

import * as URL from 'url-parse';

export const getPathnameFromUrl = (url: string): string => URL(url).pathname.substring(1);
