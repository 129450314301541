/* tslint:disable:no-parameter-reassignment */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { toArray } from 'lodash';

import { deploymentConfig } from '@src/client/config/deploymentConfig';
import { getPlainUrl, trimPrefix } from '@submodules/cawst-core/util';

angular
  .module('app.services') //
  .service('PartnersClients_Utilities', [
    '$http',
    '$state',
    '$uibModal',
    '$window',
    'fbutil',
    function($http, $state, $uibModal, $window, fbutil) {
      // /////////////////////////////////////////////////////////////////////////
      const partnersClientsTypeMap = {
        'wet-centres': 'WET Centre',
        'training-partners': 'Training Organization',
        collaborators: 'Collaborator',
      };
      // /////////////////////////////////////////////////////////////////////////
      function httpWrapper(method, baseUrl, command, cb, data?) {
        $http({
          method,
          url: baseUrl + command,
          data,
        }).then(
          (response) => {
            if (cb) {
              cb(response.data);
            }
          },
          (response) => {
            if (response.status === 401) {
              $state.go('signin');
            } else if (cb) {
              cb(null);
            }
          },
        );
      }
      // /////////////////////////////////////////////////////////////////////////
      return {
        partnersClientsTypeMap,
        // /////////////////////////////////////////////////////////////////////
        addItem(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.push('partners_clients', item, (_data) => {
            cb();
          });
        },
        deleteItem(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.remove('partners_clients', item.id, item, (_data) => {
            cb();
          });
        },
        updateItem(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.set('partners_clients', item.id, item, (data) => {
            cb(data);
          });
        },
        getList(cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.get('partners_clients/all', (data) => {
            cb(toArray(data));
          });
        },
        getOne(id, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.get('partners_clients/' + id, (data) => {
            cb(data);
          });
        },
        // /////////////////////////////////////////////////////////////////////
        getWashResource(id, cb) {
          httpWrapper('GET', deploymentConfig.api.baseUrls.wash, '/resource/' + id, cb);
        },
        // /////////////////////////////////////////////////////////////////////
        // # image uploader
        getUriFromUrl(url) {
          const plainUrl = getPlainUrl(url);
          const relativePath = trimPrefix(plainUrl, deploymentConfig.resources.imageBaseUrls.main);
          return relativePath;
        },

        // Get a signed image upload URL from the API
        getSignedUploadUrlForImage(filename, cb) {
          httpWrapper(
            'PUT',
            deploymentConfig.api.baseUrls.main,
            '/file?filename=' + filename + '&subfolder=partnersclients_images',
            cb,
          );
        },

        deleteImage(uri, cb) {
          httpWrapper('DELETE', deploymentConfig.api.baseUrls.main, '/file?uri=' + uri, cb);
        },
        // /////////////////////////////////////////////////////////////////////
      };
    },
  ]);
