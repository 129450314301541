/* tslint:disable:no-console */

interface PermissionFlags {
  [key: string]: boolean;
}

import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.services')
  //
  .service('User', [
    '$cookies',
    '$http',
    '$injector',
    '$timeout',
    '$window',
    'authService',
    // Don't use an arrow function here (or in any Angular constructor call)
    // tslint:disable-next-line:only-arrow-functions
    function($cookies, $http, $injector, $timeout, $window, authService) {
      // //////////////////////////////////////////////////////////////////////////

      // console.log('user.service.ts | Initializing service');

      let intercomService;
      if (deploymentConfig.intercom) {
        intercomService = $injector.get('intercomService');
      }

      const anonymousUserPermissionList = [
        'AccessPublicBsfkbEndpoints',
        'AccessPublicHwtsEndpoints',
        'AccessPublicWashEndpoints',
      ];

      const svc: any = {
        permissions: {} as PermissionFlags,
      };

      // ==========
      // PRIVATE METHODS

      function convertListToFlags(perms: string[]): PermissionFlags {
        const permFlags = {};
        perms.forEach((val: string) => {
          permFlags[val] = true;
        });
        return permFlags;
      }

      function refreshPermissions() {
        // console.log('user.service.ts | Refreshing permissions');

        const profile = svc.retrieveProfile();
        // console.log('user.service.ts | profile:', profile);

        const permissionList =
          profile && profile.permissions ? profile.permissions : anonymousUserPermissionList;

        // Use `angular.copy` instead of a direct assignment, so that dependents will be
        // informed of the update
        angular.copy(convertListToFlags(permissionList), svc.permissions);

        // console.log('user.service.ts | permissions:', svc.permissions);
      }

      // ==========
      // PUBLIC METHODS

      svc.isSignedIn = authService.isAuthenticated;

      // Called by auth.run.ts when the `$locationChangeStart` event occurs
      svc.reload = (isSignedIn, profile) => {
        refreshPermissions();
        if (deploymentConfig.intercom) {
          if (isSignedIn) {
            intercomService.bootWithUser(profile);
          } else {
            intercomService.bootWithAnonymous();
          }
        }
      };

      svc.retrieveProfile = () => {
        return authService.retrieveProfile();
      };

      svc.signIn = () => {
        authService.signIn();
        // TODO: Verify that a sign-in results in a page reload, causing handleAuthentication()
        // to run again and permissions to be refreshed.
      };

      svc.signOut = () => {
        authService.signOut();
        refreshPermissions();
        if (deploymentConfig.intercom) {
          intercomService.shutdown();
          intercomService.bootWithAnonymous();
        }
      };

      svc.storeProfile = (profile) => {
        return authService.storeProfile(profile);
      };

      authService.subscribeToAuthentication((data) => {
        const { isSignedIn, profile } = data;
        svc.reload(isSignedIn, profile);
      });

      return svc;
    },
  ]);
