/* tslint:disable:only-arrow-functions */
/* tslint:disable:ter-prefer-arrow-callback */

import * as angular from 'angular';

import { capitalize, has } from 'lodash';

angular
  .module('app.filters', [])
  // //////////////////////////////////////////////////////////////////////////////
  .filter('resolveToCaption', function() {
    return function(text, hwtsItemType) {
      const exceptions = {
        Title: {
          hwtsBsfProducer: 'Name',
          hwtsExperience: 'One Liner',
          hwtsFaq: 'Question',
          hwtsProductTechnology: 'Name',
        },
        Description: {
          hwtsCaseStudy: 'Summary',
          hwtsExperience: 'Summary',
          hwtsProductTechnology: 'Summary',
          hwtsResearch: 'One Liner',
        },
        Content: {
          hwtsCaseStudy: 'Full Text',
          hwtsDocument: 'Description',
          hwtsFaq: 'Answer',
        },
      };
      if (!exceptions[text] || !exceptions[text][hwtsItemType]) {
        return text;
      }
      return exceptions[text][hwtsItemType];
    };
  })
  // //////////////////////////////////////////////////////////////////////////////
  .filter('removeFilePath', function() {
    return function(fileUri) {
      if (fileUri) {
        const filePathArray = fileUri.split('/');
        const lastItem = filePathArray.length - 1;
        return filePathArray[lastItem];
      }
      return 'n/a';
    };
  })
  // //////////////////////////////////////////////////////////////////////////////
  .filter('showIfAvailable', function() {
    return function(string) {
      let showString = 'n/a';
      if (string && string.length > 0) {
        showString = string;
      }
      return showString;
    };
  })
  // //////////////////////////////////////////////////////////////////////////////
  .filter('displayLanguage', [
    'WASH_Data',
    function(WASH_Data) {
      return function(lang, resourceType) {
        const languages = WASH_Data.languages;
        const language = languages.find((obj) => {
          return obj.value === lang;
        });

        let displayLanguage = '';

        if (language) {
          displayLanguage = language.label;
        }

        return displayLanguage;
      };
    },
  ])
  // //////////////////////////////////////////////////////////////////////////////
  .filter('displayUserName', function() {
    return function(user) {
      let userName = '';
      if (user) {
        if (user.firstName) {
          userName += user.firstName;
        }
        if (user.firstName && user.lastName) {
          userName += ' ';
        }
        if (user.lastName) {
          userName += user.lastName;
        }
      }
      return userName.length > 0 ? userName : 'n/a';
    };
  })
  // //////////////////////////////////////////////////////////////////////////////
  .filter('locationCountry', function() {
    return function(location) {
      let countryName = 'n/a';

      if (location && has(location, 'countryName')) {
        countryName = location.countryName;
      }

      return countryName;
    };
  })
  // //////////////////////////////////////////////////////////////////////////////
  .filter('washFeaturedLabel', function() {
    return function(string) {
      const featureLabelMap = {
        frontpage: 'Front Page',
        posters_and_activities: 'Posters, Activities, and Videos',
        training: 'Training Resources',
        technical: 'Technical Resources',
      };
      let featureLabel = 'n/a';

      if (featureLabelMap.hasOwnProperty(string)) {
        featureLabel = featureLabelMap[string];
      }

      return featureLabel;
    };
  })
  // //////////////////////////////////////////////////////////////////////////////
  .filter('displayActivityEvent', function() {
    const eventLabelMap = {
      'searchindex.update': 'Search index updated',
      snapshot: 'Resource snapshot created',
      'resource.create': 'Resource created',
      'resource.update': 'Resource edited',
      'resource.delete': 'Resource deleted',
      'document.create': 'Document created',
      'document.update': 'Document edited',
      'document.delete': 'Document deleted',
    };

    const statusOptions = ['published', 'archived', 'draft'];

    const changesExist = function(item) {
      let changes = false;
      if (item && item.data && item.data.changes && item.data.changes.length > 0) {
        changes = true;
      }
      return changes;
    };

    const statusChange = function(item) {
      let status = null;
      const changes = changesExist(item) ? item.data.changes : [];
      if (changes.length > 0) {
        changes.forEach(function(change) {
          if (change.hasOwnProperty('newVal') && statusOptions.indexOf(change.newVal) > -1) {
            status = change.newVal;
          }
        });
      }
      return status;
    };

    return function(item) {
      let label = item.type;
      const typeArr = item.type.split('.');
      if (eventLabelMap.hasOwnProperty(item.type)) {
        label = eventLabelMap[item.type];
      }
      if (statusChange(item) && (typeArr[0] === 'resource' || typeArr[0] === 'document')) {
        label =
          capitalize(typeArr[0]) +
          ' ' +
          statusChange(item) +
          ((changesExist(item) && item.data.changes.length) > 1 ? ' and edited' : '');
      }
      return label;
    };
  }); // end app.filters
