// Dependencies (third-party code) to be imported into `vendor-bundle`

/* tslint:disable:interface-name */
/* tslint:disable:no-var-requires */

// The allowed properties of `window` are typed in `globals.d.ts`
window.$ = require('jquery');
window.jQuery = window.$;

require('bootstrap');
require('chartjs');
require('codemirror');
require('firebase');
require('json3');
require('jszip');
require('metismenu');
require('summernote'); // requires codemirror
require('sweetalert');
// require('xml');
// require('xml2json');
require('xlsx');

require('angular');
require('@uirouter/angularjs');
require('angular-animate');
// require('angular-breadcrumb');
require('angular-chart');
require('angular-cookies');
// require('angular-css');
require('angular-filter');
// require('angular-google-maps');
require('angular-loading-bar');
require('angular-resource');
require('angular-sanitize');
require('angular-simple-logger');
require('angular-storage');
require('angular-summernote');
require('angular-touch');
require('angular-ui-bootstrap');
require('angular-ui-notification');
require('angular-utils-pagination');
// require('angular-xml'); // requires xml2json
require('angularfire');
require('ngstorage');
require('ng-tags-input');

require('checklist-model'); // requires angular
// window.Flow = require('@flowjs/flow.js'); // 2.13.0
require('@flowjs/ng-flow'); // 2.7.8
// #Manager-89
window.Flow = require('../../overrides/flow.js'); // 2.13.0

// require('oclazyload'); // requires: angular, angular-ui-notification, flow, xml

require('auth0-js');
require('angular-auth0/src');
