/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import * as moment from 'moment';

angular
  .module('app.controllers') //
  .controller('HwtsImageCtrl', [
    '$uibModal',
    '$scope',
    '$state',
    '$http',
    'HWTS_Utilities',
    '$stateParams',
    '$window',
    'store',
    '$sce',
    '$q',
    '$rootScope',
    function(
      $uibModal,
      $scope,
      $state,
      $http,
      HWTS_Utilities,
      $stateParams,
      $window,
      store,
      $sce,
      $q,
      $rootScope,
    ) {
      $rootScope.imageController = $scope;
      $rootScope.options.selectedImageIndex = null;
      $rootScope.options.factSheetImageIndex = null;
      $scope.flowUploading = false;

      $scope.getImageUrl = HWTS_Utilities.getImageUrl;

      $scope.onLoadItem = function() {
        if ($rootScope.item.images) {
          for (const i of Object.keys($rootScope.item.images)) {
            if ($rootScope.item.images[i].isMainImage) {
              $rootScope.options.selectedImageIndex = i;
            }
            if ($rootScope.item.images[i].factSheetGraphic) {
              $rootScope.options.factSheetImageIndex = i;
            }
          }
        }
      };

      $scope.onMainImageSelected = function(value) {
        for (const i of Object.keys($rootScope.item.images)) {
          $rootScope.item.images[i].isMainImage = i === value;
        }
      };

      $scope.onFactSheetImageSelected = function(value) {
        for (const i of Object.keys($rootScope.item.images)) {
          $rootScope.item.images[i].factSheetGraphic = i === value;
        }
      };

      $scope.downloadImage = function(image) {
        if (image.new) {
          return HWTS_Utilities.downloadUploadedFile(image.uri);
        }
        return HWTS_Utilities.downloadFile(image.uri);
      };

      $scope.removeImage = function(image) {
        if (!$window.confirm('Are you sure you want to delete this image?')) return;

        for (const i of Object.keys($rootScope.item.images)) {
          if ($rootScope.item.images[i].uri === image.uri) {
            $rootScope.item.images.splice(i, 1);
            break;
          }
        }

        if (image.new) {
          HWTS_Utilities.removeUploadedFile(image.uri, (_res) => {
            $scope.onImageRemoved();
          });
        } else {
          $scope.onImageRemoved();
        }
      };

      $scope.onImageRemoved = function() {
        if ($scope.options.selectedImageIndex >= $rootScope.item.images.length) {
          $scope.options.selectedImageIndex = $rootScope.item.images.length - 1;
        }
        $scope.onMainImageSelected($scope.options.selectedImageIndex);
      };

      $scope.onImageAdded = function($file, $message, $flow) {
        if ($file.file.type.indexOf('image/') !== 0) {
          $window.alert('Only image files are allowed.');
          return false;
        }
        if ($file.size >= 5 * 1024 * 1024) {
          $window.alert('File exceeds size limit of 5MB.');
          return false;
        }
        $scope.flowUploading = true;
        HWTS_Utilities.addFileUpload($file.name, (res) => {
          $file.url = res.url;
          $file.uri = HWTS_Utilities.getUriFromUrl(res.url);
          $flow.opts.target = res.url;
          $flow.upload();
        });
      };

      $scope.onImageSuccess = function($file, $message, $flow) {
        if (!$rootScope.item.images) $rootScope.item.images = [];

        $rootScope.item.images.push({
          name: $file.name,
          type: $file.file.type,
          uri: $file.uri,
          size: $file.size,
          upload_time: moment().toISOString(),

          description: '',
          alt: '',
          isMainImage: false,

          new: true,
        });
        $file.cancel();
        $scope.imageListFocusIndex = $rootScope.item.images.length - 1;
        if ($rootScope.item.images.length === 1) {
          $rootScope.item.images[0].isMainImage = true;
          $scope.options.selectedImageIndex = 0;
        }
        $scope.flowUploading = false;
        // console.log($rootScope.item);
      };
    },
  ]);
