/* tslint:disable:no-var-requires */

// Don't use path aliases here! This file is processed by Webpack, which defines the aliases
// but (apparently) doesn't apply them to its own imports.

const { projectKey } = require('../../../package.json');
const defaultConfigRules = require('../../submodules/cawst-core/config/rules/rules')(projectKey);
const { deploymentTargets, projects } = require('../../submodules/cawst-core/config/projects');
import { ConfigMerger, ConfigRule } from '../../submodules/cawst-core/config/ConfigMerger';

// These settings are added to the settings defined in `defaultConfigRules`.
// In case of a conflict, these settings take priority.
const projectConfigRules: ConfigRule[] = [
  [
    'universal',
    {
      featureFlags: {
        purgeCSS: false, // default: false
      },
      intercom: null, // disable Intercom
    },
  ],
];

function addProjectUrls(rules: ConfigRule[]) {
  for (const dt of deploymentTargets) {
    const origin = projects['vwet-api'].deploymentTargetOrigins[dt];
    rules.push([
      dt,
      {
        api: {
          baseUrls: {
            mainSecure: origin + '/main/secure', // FIREBASE_VWET_URL
            hwts: origin + '/hwts/secure', // API_HWTS_PREFIX
            main: origin + '/main/public', // API_MAIN_PREFIX
            vwet: origin + '/vwet/secure', // API_VWET_PREFIX
            wash: origin + '/wash/secure', // API_WASH_PREFIX
          },
          origin, // API_URL
        },
        origins: {
          mainSite: projects['main-site'].deploymentTargetOrigins[dt], // MAINWEBSITE_URL
          washSite: projects['wash-resources-site'].deploymentTargetOrigins[dt], // WASH_URL
        },
      },
    ]);
  }

  rules.push([
    'prod',
    {
      resources: {
        assetBaseUrls: {
          hwts: 'https://cawst-hwts-prod.s3.amazonaws.com', // URI_PREFIX
          wash: 'https://cawst-wash-prod.s3.amazonaws.com', // URI_WASH_PREFIX
        },
        imageBaseUrls: {
          hwts: 'https://s3-us-west-2.amazonaws.com/cawst-hwts-prod', // HWTS_IMAGE_SERVE_URL
          main: 'https://cawst-mainwebsite-public.s3.amazonaws.com', // MAIN_IMAGE_URI_PREFIX
        },
      },
    },
  ]);

  rules.push([
    ['dev', 'local'],
    {
      resources: {
        assetBaseUrls: {
          hwts: 'https://cawst-hwts-dev.s3.amazonaws.com', // URI_PREFIX
          wash: 'https://cawst-wash-dev.s3.amazonaws.com', // URI_WASH_PREFIX
        },
        imageBaseUrls: {
          hwts: 'https://s3-us-west-2.amazonaws.com/cawst-hwts-dev', // HWTS_IMAGE_SERVE_URL
          main: 'https://cawst-mainwebsite-dev-public.s3.amazonaws.com', // MAIN_IMAGE_URI_PREFIX
        },
      },
    },
  ]);
}

addProjectUrls(projectConfigRules);

const cm = new ConfigMerger({
  rules: [...defaultConfigRules, ...projectConfigRules],
});

const deploymentConfig = cm.getResolvedConfig();
export { deploymentConfig, projectConfigRules };
