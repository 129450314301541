/* tslint:disable:no-console */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { find, result } from 'lodash';
import * as moment from 'moment';

angular.module('app.controllers').controller('TrainingWorkshopsCtrl', [
  '$scope',
  'TrainingEvents',
  'SweetAlertUtil',
  function($scope, TrainingEvents, SweetAlertUtil) {
    $scope.niceEventDate = TrainingEvents.niceEventDate;
    $scope.niceDuration = TrainingEvents.niceDuration;
    $scope.topicIcon = TrainingEvents.topicIcon;

    $scope.deleteTrainingEvent = function(training) {
      if (!confirm('Are you sure you want to remove this event?')) {
        return;
      }
      TrainingEvents.removeEvent(training, (response) => {
        if (response) {
          SweetAlertUtil.success('Training successfully removed.');
        } else {
          SweetAlertUtil.error(
            'Something went wrong removing the training, please contact virtual services.',
          );
        }
        reloadEvents();
      });
    };

    reloadEvents();

    // helper functions
    function reloadEvents() {
      $scope.spin_loadingEvents = true;
      TrainingEvents.getEvents((data) => {
        $scope.eventList = data;
        $scope.spin_loadingEvents = false;
      });
    }
  },
]);
