/* tslint:disable:no-var-requires */

const { deploymentConfig } = require('./deploymentConfig.ts');

window.appSettings = {};
window.appSettings = Object.assign({}, deploymentConfig);

require('../scripts/app');
require('../scripts/providers/ng-prov-main');

// Config block
require('../scripts/config/ng-cfg-app');
require('../../submodules/cawst-angularjs/auth/auth.config');
require('../scripts/config/route.config');
// require('../scripts/config/xmlHttpInterceptor.config'); // requires angular-xml, which requires xml2json; possibly unused
// require('../scripts/config/xml2json.config'); // has errors; possibly unused

// Run block
require('../scripts/run/ng-run');
require('../scripts/run/ng-run-navigation');

// Controllers
require('../scripts/controllers/app-controllers'); // load before controllers
require('../scripts/controllers/training/ng-ctrl-training');
require('../scripts/controllers/training/ng-ctrl-training-edit');
require('../scripts/controllers/ng-ctrl-wmprojects');
require('../scripts/controllers/ng-ctrl-wmreport');
require('../scripts/controllers/ng-ctrl-partnersclients-list');
require('../scripts/controllers/ng-ctrl-partnersclients-detail');
require('../scripts/controllers/ng-ctrl-urlredirect');
require('../scripts/controllers/ng-ctrl-searchindex');
require('../scripts/controllers/ng-ctrl-user');
require('../scripts/controllers/ng-ctrl-country');
require('../scripts/controllers/ng-ctrl-country-item');
require('../scripts/controllers/ng-ctrl-translation');
require('../scripts/controllers/ng-ctrl-activity-log');
require('../scripts/controllers/ng-ctrl-vwetstats');
require('../scripts/controllers/ng-ctrl-campaigns');

require('../scripts/controllers/wash/ng-ctrl-wash');
require('../scripts/controllers/wash/search');
require('../scripts/controllers/wash/resource');
require('../scripts/controllers/wash/add-new-resource');
require('../scripts/controllers/wash/edit-resource');
require('../scripts/controllers/wash/edit-document');
require('../scripts/controllers/wash/edit-children');
require('../scripts/controllers/wash/view-snapshots');

require('../scripts/controllers/ng-ctrl-hwts-items');
require('../scripts/controllers/ng-ctrl-hwts-item');
require('../scripts/controllers/ng-ctrl-form');
require('../scripts/controllers/ng-ctrl-hwts-image');
require('../scripts/controllers/ng-ctrl-hwts-file');
require('../scripts/controllers/ng-ctrl-hwts-efficiency');
require('../scripts/controllers/ng-ctrl-summernote');

// Directives
require('../scripts/directives/timeline/timeline');
require('../scripts/directives/notifications/notifications');
require('../scripts/directives/chat/chat');
require('../scripts/directives/dashboard/stats/stats');
require('../scripts/directives/header/header');
require('../scripts/directives/header/header-notification/header-notification');
require('../scripts/directives/sidebar/sidebar');
require('../scripts/directives/sidebar/sidebar-search/sidebar-search');
require('../scripts/directives/wash/washdirectives');
require('../scripts/directives/wash-search-filters/wash-search-filters');
require('../scripts/directives/hwts/prod-tech-item');

// Services
require('../scripts/services/app-services'); // load before services
require('../../submodules/cawst-angularjs/user/user.service');
require('../scripts/services/firebase-utils');
// require('../scripts/services/user');
require('../scripts/services/sweet-alert-util');
require('../scripts/services/global-messaging');
require('../scripts/services/hwts-utilities');
require('../scripts/services/training-events');
require('../scripts/services/wavemakers-projects');
require('../scripts/services/partnersclients-utilities');
require('../scripts/services/vwet-stats');
require('../scripts/services/wash/wash-data');
require('../scripts/services/wash/wash-httpwrapper');
require('../scripts/services/wash/wash-resource');
require('../scripts/services/wash/wash-document');
require('../scripts/services/wash/wash-utilities');

require('../scripts/filters/cstm-filters');

require('../../submodules/cawst-angularjs/auth/auth.imports');

require('../../submodules/cawst-angularjs/utils/intercom');

require('../js/sb-admin-2');
// require('../js/flow');
require('../js/summernote-reference');
require('../js/angular-toggle-switch.min');
// require('../js/angular-jwt.min');
require('../js/datetime');
