/* tslint:disable:no-console */
/* tslint:disable:no-parameter-reassignment */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('firebase.utils', ['firebase']) //
  .factory('fbutil', [
    '$http',
    function($http) {
      const firebaseBaseRoute = deploymentConfig.api.baseUrls.mainSecure + '/';

      return {
        push(path, item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          $http.put(firebaseBaseRoute + path, { item }).then(
            (ret, err) => {
              if (err) {
                console.log(path, err);
                cb(false);
                return;
              }
              cb(ret.data);
            },
            () => {
              cb(false);
            },
          );
        },

        set(path, id, item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          $http.post(firebaseBaseRoute + path, { item, id }).then(
            (_ret, err) => {
              if (err) {
                console.log(path, err);
                cb(false);
                return;
              }
              cb(true);
            },
            () => {
              cb(false);
            },
          );
        },

        get(path, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          $http.get(firebaseBaseRoute + path).then((ret, err) => {
            if (err) {
              console.log(path, err);
              return;
            }
            cb(ret.data);
          });
        },

        remove(path, id, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          $http.delete(firebaseBaseRoute + path + '/' + id).then((ret, err) => {
            if (err) {
              console.log(path, err);
              return;
            }
            cb(ret.data);
          });
        },
      };
    },
  ]);
