/* tslint:disable:no-console */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';

angular
  .module('app') //
  .directive('sidebar', [
    function() {
      return {
        templateUrl: 'scripts/directives/sidebar/sidebar.html',
        restrict: 'E',
        replace: true,
        scope: {},
        controller: [
          '$scope',
          '$state',
          'User',
          function($scope, $state) {
            $scope.selectedMenu = 'dashboard';
            $scope.vwet = false;
            $scope.wash = false;
            $scope.hwts = false;

            $scope.hasParentState = function(parent) {
              return $state.current.name.indexOf(parent + '.') !== -1;
            };

            $scope.toggle = function(prop) {
              $scope[prop] = !$scope[prop];
            };
          },
        ],
      };
    },
  ]);
