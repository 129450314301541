import * as angular from 'angular';

angular
  .module('app') //
  .config([
    '$stateProvider',
    '$urlMatcherFactoryProvider',
    '$urlRouterProvider',
    // tslint:disable-next-line:only-arrow-functions
    function($stateProvider, $urlMatcherFactoryProvider, $urlRouterProvider) {
      //
      // Return to the page from which the user logged in
      $urlMatcherFactoryProvider.strictMode(false);
      $urlRouterProvider.when('/callback', () => {
        // 'nextPathAfterLogin` is set in authService.signIn() when the user starts the signin
        // process.
        let nextPathAfterLogin = JSON.parse(window.localStorage.getItem('nextPathAfterLogin'));
        if (!nextPathAfterLogin || nextPathAfterLogin === '/callback') {
          nextPathAfterLogin = '';
        }

        // `nextPathAfterLogin` is only needed once, so remove it from the store.
        window.localStorage.removeItem('nextPathAfterLogin');
        return nextPathAfterLogin;
      });

      $urlRouterProvider.otherwise('/');

      const hwtsSharedItemView = 'views/hwts/item.html';
      const hwtsSharedItemCtrl = 'HwtsItemCtrl';
      const hwtsSharedItemListView = 'views/hwts/items.html';
      const hwtsSharedItemListCtrl = 'HwtsItemsCtrl';

      const washShareDocumentView = 'views/wash/form_document.html';
      const washShareDocumentCtrl = 'WashDocumentCtrl';

      const partnersClientsDetailView = 'views/partnersclients/partnersclients_detail.html';
      const partnersClientsDetailCtrl = 'partnersClientsDetailCtrl';

      $stateProvider
        .state('home', {
          url: '/',
          controller: 'AuthCtrl',
          templateUrl: 'views/dashboard/home.html',
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('signin', {
          url: '/signin',
          controller: 'AuthCtrl',
          templateUrl: 'views/dashboard/signin.html',
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('training', {
          url: '/training',
          controller: 'TrainingWorkshopsCtrl',
          templateUrl: 'views/training/training.html',
          reload: true,
        })
        .state('training.edit', {
          url: '/edit?id',
          controller: 'EditTrainingWorkshopsCtrl',
          templateUrl: 'views/training/training-edit.html',
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('campaigns', {
          url: '/campaigns?event',
          controller: 'CampaignsEventCtrl',
          templateUrl: 'views/campaigns.html',
          reloadOnSearch: false,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('urlredirects', {
          url: '/urlredirects',
          controller: 'URLRedirectsCtrl',
          templateUrl: 'views/urlredirects.html',
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('vwetstats', {
          url:
            '/vwetstats/:segment?page&groupby&orderby&country&dateStart&dateEnd&email&region&orgName&userLang&countries',
          controller: 'VWetStatsCtrl',
          templateUrl: 'views/vwetstats.html',
          reloadOnSearch: false,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('wmprojects', {
          url: '/wmprojects',
          controller: 'WavemakersProjectsCtrl',
          templateUrl: 'views/wmprojects.html',
          reload: true,
        })
        .state('wmprojects.closereport', {
          url: '/closereport?id',
          controller: 'WavemakersReportCtrl',
          templateUrl: 'views/wmreport.html',
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('partnersclients', {
          templateUrl: 'views/partnersclients.html',
          url: '/partners-clients',
          reload: true,
        })
        .state('partnersclients.list', {
          templateUrl: 'views/partnersclients/partnersclients_list.html',
          url: '/all',
          controller: 'partnersClientsListCtrl',
          reload: true,
        })
        .state('partnersclients.detail', {
          templateUrl: partnersClientsDetailView,
          url: '/add',
          controller: partnersClientsDetailCtrl,
          reload: true,
        })
        .state('partnersclients.edit', {
          templateUrl: partnersClientsDetailView,
          url: '/edit?id',
          controller: partnersClientsDetailCtrl,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        // /////////////////////////////////////////////////////////////////////
        .state('vwet', { templateUrl: 'views/vwet.html', url: '/vwet', reload: true })
        // /////////////////////////////////////////////////////////////////////
        .state('vwet.user', {
          url: '/user',
          controller: 'UserListController',
          templateUrl: 'views/vwet/users.html',
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('vwet.country', {
          templateUrl: 'views/vwet/country.html',
          url: '/country',
          controller: 'CountryListController',
          reload: true,
        })
        .state('vwet.country.edit', {
          templateUrl: 'views/vwet/country-item.html',
          url: '/edit?id',
          controller: 'CountryItemController',
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('vwet.searchindex', {
          url: '/searchindex',
          controller: 'SearchIndexController',
          templateUrl: 'views/vwet/searchindex.html',
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('vwet.translation', {
          url: '/translation',
          controller: 'TranslationListController',
          templateUrl: 'views/vwet/translation.html',
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        // /////////////////////////////////////////////////////////////////////
        .state('hwts', { templateUrl: 'views/hwts.html', url: '/hwts', reload: true })
        // /////////////////////////////////////////////////////////////////////
        .state('hwts.bsfproducer', {
          templateUrl: hwtsSharedItemListView,
          url: '/bsfproducer',
          controller: hwtsSharedItemListCtrl,
          reload: true,
        })
        .state('hwts.bsfproducer.add', {
          templateUrl: hwtsSharedItemView,
          url: '/add',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        .state('hwts.bsfproducer.edit', {
          templateUrl: hwtsSharedItemView,
          url: '/edit?id',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('hwts.casestudy', {
          templateUrl: hwtsSharedItemListView,
          url: '/casestudy',
          controller: hwtsSharedItemListCtrl,
          reload: true,
        })
        .state('hwts.casestudy.add', {
          templateUrl: hwtsSharedItemView,
          url: '/add',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        .state('hwts.casestudy.edit', {
          templateUrl: hwtsSharedItemView,
          url: '/edit?id',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('hwts.document', {
          templateUrl: hwtsSharedItemListView,
          url: '/document',
          controller: hwtsSharedItemListCtrl,
          reload: true,
        })
        .state('hwts.document.add', {
          templateUrl: hwtsSharedItemView,
          url: '/add',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        .state('hwts.document.edit', {
          templateUrl: hwtsSharedItemView,
          url: '/edit?id',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('hwts.experience', {
          templateUrl: hwtsSharedItemListView,
          url: '/experience',
          controller: hwtsSharedItemListCtrl,
          reload: true,
        })
        .state('hwts.experience.add', {
          templateUrl: hwtsSharedItemView,
          url: '/add',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        .state('hwts.experience.edit', {
          templateUrl: hwtsSharedItemView,
          url: '/edit?id',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('hwts.faq', {
          templateUrl: hwtsSharedItemListView,
          url: '/faq',
          controller: hwtsSharedItemListCtrl,
          reload: true,
        })
        .state('hwts.faq.add', {
          templateUrl: hwtsSharedItemView,
          url: '/add',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        .state('hwts.faq.edit', {
          templateUrl: hwtsSharedItemView,
          url: '/edit?id',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('hwts.organization', {
          templateUrl: hwtsSharedItemListView,
          url: '/organization',
          controller: hwtsSharedItemListCtrl,
          reload: true,
        })
        .state('hwts.organization.add', {
          templateUrl: hwtsSharedItemView,
          url: '/add',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        .state('hwts.organization.edit', {
          templateUrl: hwtsSharedItemView,
          url: '/edit?id',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('hwts.prodtech', {
          templateUrl: hwtsSharedItemListView,
          url: '/prodtech',
          controller: hwtsSharedItemListCtrl,
          reload: true,
        })
        .state('hwts.prodtech.add', {
          templateUrl: hwtsSharedItemView,
          url: '/add',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        .state('hwts.prodtech.edit', {
          templateUrl: hwtsSharedItemView,
          url: '/edit?id',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('hwts.project', {
          templateUrl: hwtsSharedItemListView,
          url: '/project',
          controller: hwtsSharedItemListCtrl,
          reload: true,
        })
        .state('hwts.project.add', {
          templateUrl: hwtsSharedItemView,
          url: '/add',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        .state('hwts.project.edit', {
          templateUrl: hwtsSharedItemView,
          url: '/edit?id',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('hwts.research', {
          templateUrl: hwtsSharedItemListView,
          url: '/research',
          controller: hwtsSharedItemListCtrl,
          reload: true,
        })
        .state('hwts.research.add', {
          templateUrl: hwtsSharedItemView,
          url: '/add',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        .state('hwts.research.edit', {
          templateUrl: hwtsSharedItemView,
          url: '/edit?id',
          controller: hwtsSharedItemCtrl,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        // /////////////////////////////////////////////////////////////////////
        .state('wash', {
          abstract: true,
          templateUrl: 'views/wash.html',
          url: '/wash',
          controller: 'WashController',
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('wash.newresource', {
          reloadOnSearch: false,
          templateUrl: 'views/wash/newresource.html',
          url: '/add_new_resource?type',
          controller: 'WashNewResourceCtrl',
          reload: true,
        })
        .state('wash.newresource.newdocument', {
          templateUrl: washShareDocumentView,
          url: '/add_document',
          controller: washShareDocumentCtrl,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('wash.list', {
          templateUrl: 'views/wash/listresources.html',
          url:
            '/listresources?page&orderBy&title&status&featured&type&category&topic&region&language',
          controller: 'WashListController',
          reloadOnSearch: false,
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('wash.activitylog', {
          templateUrl: 'views/wash/activitylog.html',
          url: '/activitylog',
          controller: 'ActivityLogCtrl',
          reload: true,
        })
        // /////////////////////////////////////////////////////////////////////
        .state('wash.resource', {
          templateUrl: 'views/wash/viewresource.html',
          url: '/resource/:resourceId',
          controller: 'WashItemController',
          reload: true,
        })
        .state('wash.resource.newdocument', {
          templateUrl: washShareDocumentView,
          url: '/add_new_translation_region',
          controller: washShareDocumentCtrl,
          reload: true,
        })
        .state('wash.resource.editdocument', {
          templateUrl: washShareDocumentView,
          url: '/edit_document/:documentId',
          controller: washShareDocumentCtrl,
          reload: true,
        })
        .state('wash.resource.addchildren', {
          templateUrl: 'views/wash/form_addchildren.html',
          url: '/add_sub_resources',
          controller: 'WashAddChildrenCtrl',
          reload: true,
        })
        .state('wash.resource.editresource', {
          templateUrl: 'views/wash/form_resource.html',
          url: '/edit_details',
          controller: 'WashEditResourceCtrl',
          reload: true,
        })
        .state('wash.resource.snapshots', {
          templateUrl: 'views/wash/snapshot.html',
          url: '/snapshots',
          controller: 'WashSnapshotCtrl',
          reload: true,
        });
    },
  ]);
