/* tslint:disable:import-name */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import swal from 'sweetalert';

angular
  .module('app.services') //
  .factory('SweetAlertUtil', [
    '$q',
    function($q) {
      return {
        alert(message, options) {
          return swal(
            angular.extend(
              {
                title: 'Alert',
                text: message,
                type: 'warning',
                showCancelButton: false,
              },
              options,
            ),
          );
        },

        info(message, options) {
          return swal(
            angular.extend(
              {
                text: message,
                type: 'info',
              },
              options,
            ),
          );
        },

        success(message, options) {
          return swal(
            angular.extend(
              {
                title: 'Success',
                text: message,
                type: 'success',
              },
              options,
            ),
          );
        },

        error(message, options) {
          return swal(
            angular.extend(
              {
                title: 'Error',
                text: message,
                type: 'error',
              },
              options,
            ),
          );
        },

        confirm(message, options) {
          const deferred = $q.defer();
          const swalOptions = angular.extend(
            {
              title: 'Alert',
              text: message,
              type: 'warning',
              showCancelButton: true,
            },
            options,
          );
          swal(
            swalOptions,
            // @ts-ignore
            (r) => {
              deferred.resolve(r);
            },
            (e) => {
              deferred.reject(e);
            },
          );
          return deferred.promise;
        },

        prompt(message, placeholder, options) {
          const deferred = $q.defer();
          const swalOptions = angular.extend(
            {
              title: 'Prompt',
              text: message,
              type: 'input',
              showCancelButton: true,
              closeOnConfirm: false,
              animation: 'slide-from-top',
              inputPlaceholder: placeholder,
            },
            options,
          );
          swal(
            swalOptions,
            // @ts-ignore
            (r) => {
              if (r === '') {
                // @ts-ignore
                swal.showInputError('You need to write something!');
              } else {
                deferred.resolve(r);
              }
            },
            (e) => {
              deferred.reject(e);
            },
          );
          return deferred.promise;
        },
      };
    },
  ]);
