/* tslint:disable:no-parameter-reassignment */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { filter } from 'lodash';
import * as moment from 'moment';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.controllers') //
  .controller('partnersClientsListCtrl', [
    '$scope',
    '$state',
    '$window',
    'PartnersClients_Utilities',
    function($scope, $state, $window, PartnersClients_Utilities) {
      $scope.partners_clients = {};
      $scope.loading = true;
      $scope.imagesPrefix = deploymentConfig.resources.imageBaseUrls.main + '/';
      $scope.partnersClientsTypeMap = PartnersClients_Utilities.partnersClientsTypeMap;
      // /////////////////////////////////////////////////////////////////////////
      function loadItem(item) {
        if (item.lastModified) {
          item.lastModified = moment(item.lastModified).toDate();
        }
        return item;
      }
      // /////////////////////////////////////////////////////////////////////////////
      PartnersClients_Utilities.getList((data) => {
        if (data) {
          data = filter(data, { published: true });
          $scope.partners_clients.wetcentres = loadItem(data);
          $scope.loading = false;
        }
      });
      // /////////////////////////////////////////////////////////////////////////////
      $scope.edit = function(item) {
        $state.go('partnersclients.edit', { id: item.id });
      };
      // /////////////////////////////////////////////////////////////////////////////
    },
  ]);
