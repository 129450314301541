/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { get, has, keysIn, map, toArray } from 'lodash';

import { deploymentConfig } from '@src/client/config/deploymentConfig';
import { cleanCampaignData, convertDataObjectToCsvString } from '@src/client/scripts/util/csv';

interface DetailsData {
  endDate?: any;
  id?: string;
  [key: string]: any;
}

interface ResponseData {
  [key: string]: any;
}

interface Campaigns {
  disable: boolean;
  eventId: string;

  details: {
    [key: string]: DetailsData;
  };
  detailList: DetailsData[];

  responses: {
    [key: string]: ResponseData;
  };
  responseList: ResponseData[];

  [key: string]: any;
}

interface Scope {
  $$phase: boolean;
  $apply: any;
  campaigns: Campaigns;
}

angular
  .module('app.controllers') //
  .controller('CampaignsEventCtrl', [
    '$http',
    '$scope',
    '$state',
    'User',
    function($http, $scope: Scope, $state, User) {
      $scope.campaigns = {
        disable: true,
        eventId: $state.params.event || null,

        details: {},
        detailList: [],
        responses: {},
        responseList: [],
      };

      if (User.permissions.ViewCampaigns) {
        getAllEvents();
      } else {
        // TODO: Always go to signin?
        $state.go('signin');
      }

      function detailsObjectToArray(detailsObject): object[] {
        return Object.entries(detailsObject).map((entry) => {
          return {
            title: entry[0],
            ...entry[1],
          };
        });
      }

      const GetTotalAttendees = (campaignList): number => {
        let total = 0;
        for (const key of Object.keys(campaignList)) {
          if (campaignList[key].totalAttendees) {
            total += campaignList[key].totalAttendees;
          } else if (Number(campaignList[key].totalAttendees) !== 0) {
            total += 1;
          }
        }
        return total;
      };

      $scope.campaigns.LoadEvent = function(eventId) {
        if (!has($scope.campaigns.details, eventId)) {
          return;
        }
        $scope.campaigns.disable = true;
        $state.go('campaigns', { event: eventId });
        $scope.campaigns.eventId = eventId;
        $scope.campaigns.loading = true;
        $scope.campaigns.hasRsvp = true;
        $http
          .get(deploymentConfig.api.origin + '/main/secure/campaigns/' + eventId)
          .then((ret, _err) => {
            $scope.campaigns.responses = get(ret, 'data', {});
            $scope.campaigns.responseList = Object.values($scope.campaigns.responses);

            $scope.campaigns.totalAttendees = GetTotalAttendees(get(ret, 'data', []));
            $scope.campaigns.loading = false;
            $scope.campaigns.disable = false;
            if (!$scope.$$phase) {
              $scope.$apply();
            }
          });
      };

      function getAllEvents() {
        $http //
          .get(deploymentConfig.api.origin + '/main/secure/campaigns/all_event_details')
          .then((res, _err) => {
            $scope.campaigns.details = get(res, 'data', {});
            $scope.campaigns.detailList = detailsObjectToArray($scope.campaigns.details);
            if (!$scope.$$phase) {
              $scope.$apply();
            }

            if ($scope.campaigns.eventId) {
              $scope.campaigns.LoadEvent($scope.campaigns.eventId);
            }
          });
      }

      $scope.campaigns.downloadCsv = () => {
        const rawCampaignData: any = $scope.campaigns.responses;
        const blacklistedKeys: string[] = ['$$hashKey'];
        const sterilizedData: any[] = cleanCampaignData(rawCampaignData, blacklistedKeys);
        const csvContent: string = convertDataObjectToCsvString(sterilizedData);
        performDownloadAction(csvContent);
      };

      const performDownloadAction = (csvString: string): void => {
        // Wrapping Legacy Behaviour - AM (2019-02-20)
        const encodedUri = encodeURI(csvString);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'data.csv');
        link.click();
      };
    },
  ]);
