/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import * as moment from 'moment';

// TODO: ngStorage is used only in this file; use `store` from angular-storage to perform the
// functions now being performed by `$localStorage`, then remove ngStorage

angular
  .module('app.controllers') //
  .controller('ItemFormController', [
    '$scope',
    '$rootScope',
    '$interval',
    '$localStorage',
    '$location',
    '$window',
    'User',
    function($scope, $rootScope, $interval, $localStorage, $location, $window, User) {
      $scope.loadingFromRevision = false;
      $rootScope.revisions = [];
      $rootScope.oldItem = null;
      $scope.intervalInMilliseconds = 60 * 1000; // 60 seconds
      if (!$rootScope.revisionIntervals) $rootScope.revisionIntervals = {};

      $scope.saveChangedForm = function(forceUrl) {
        if ($scope.loadingFromRevision || $rootScope.item == null) return;
        let newItem = JSON.parse(JSON.stringify($rootScope.item));
        newItem = $scope.saveDateProperties(newItem);
        newItem = JSON.stringify(newItem);
        if ($rootScope.oldItem !== newItem) {
          if ($rootScope.oldItem != null) {
            const newRevisionEntry = {
              title:
                moment(new Date()).format('YYYY-MM-DD hh:mm:ss A') +
                ' - ' +
                User.retrieveProfile().email,
              item: newItem,
            };
            $rootScope.revisions.unshift(newRevisionEntry);
            // console.log('saved',forceUrl,newRevisionEntry.item)
          }
          $rootScope.oldItem = newItem;
          $scope.saveCachedEdits(forceUrl);
        }
      };

      if (
        !$scope.revisionIntervals.hasOwnProperty($location.url()) &&
        ($location.url().indexOf('/edit') !== -1 || $location.url().indexOf('/add') !== -1)
      ) {
        $scope.revisionIntervals[$location.url()] = $interval(
          $scope.saveChangedForm,
          $scope.intervalInMilliseconds,
        );
      }

      // removes timers that's not for the current page
      $interval(() => {
        for (const url of Object.keys($scope.revisionIntervals)) {
          if (url !== $location.url()) {
            $interval.cancel($scope.revisionIntervals[url]);
            delete $scope.revisionIntervals[url];
          }
        }
      }, 1000);

      $scope.saveCachedEdits = function(forceUrl) {
        if ($rootScope.revisions.length > 10) $rootScope.revisions.length = 10;
        let url = $location.url();
        if (forceUrl && typeof forceUrl === 'string') {
          url = forceUrl;
        }
        url = url.trim();
        $localStorage[url] = $rootScope.revisions;
      };

      $scope.loadCachedEdits = function() {
        $rootScope.revisions = $localStorage[$location.url()];
        if (!$rootScope.revisions) $rootScope.revisions = [];
      };
      $scope.loadCachedEdits();

      $scope.onRevisionChange = function() {
        $scope.loadingFromRevision = true;
        const message =
          'Are you sure you want to discard all your changes and load the cached version from "' +
          $scope.selectedRevision.title +
          '"?';
        if ($window.confirm(message)) {
          $rootScope.oldItem = $scope.selectedRevision.item;
          $rootScope.item = $scope.loadDateProperties(JSON.parse($scope.selectedRevision.item));
        }
        $scope.selectedRevision = null;
        $scope.loadingFromRevision = false;
      };

      $scope.loadDateProperties = function(item) {
        for (const i of Object.keys($rootScope.dateProperties)) {
          const prop = $rootScope.dateProperties[i];
          if (item && item.hasOwnProperty(prop) && item[prop]) {
            item[prop] = moment(item[prop]).toDate();
          }
        }
        return item;
      };

      $scope.saveDateProperties = function(item) {
        for (const i of Object.keys($rootScope.dateProperties)) {
          const prop = $rootScope.dateProperties[i];
          if (item && item.hasOwnProperty(prop) && item[prop]) {
            item[prop] = moment(item[prop]).toISOString();
          }
        }
        return item;
      };

      $scope.resetPristine = function() {
        // Calls $setPristine 5 times with 10ms intervals. For whatever reason,
        // calling $setPristine doesn't work intermittently if you call it only once.
        $interval(
          () => {
            if ($scope.itemForm) $scope.itemForm.$setPristine();
            let item = JSON.parse(JSON.stringify($rootScope.item));
            item = $scope.saveDateProperties(item);
            item = JSON.stringify(item);
            $rootScope.oldItem = item;
          },
          10,
          5,
        );
      };

      $scope.clearCachedEdits = function() {
        $localStorage[$location.url()] = [];
      };

      $scope.$watch('itemForm.$dirty', (dirty) => {
        if (dirty) $rootScope.preventNavigation();
        else $rootScope.allowNavigation();
      });
    },
  ]);
