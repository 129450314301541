/* tslint:disable:no-console */

import { merge } from 'lodash';

import { DeploymentTarget } from '@submodules/cawst-core/config/ConfigMerger';

export interface ConfigOptions {
  forceMinify?: boolean;
  deploymentTarget?: 'dev' | 'local' | 'prod';
  isTest?: boolean;
}

const defaultOptions: ConfigOptions = {
  deploymentTarget: (process.env.DEPLOYMENT_TARGET as DeploymentTarget) || 'local',
  forceMinify: false, // TODO: Read this setting from an env var
  isTest: false, // TODO: Use this setting in webpack.config
};

export function resolveConfig(multiconfig, options: ConfigOptions = {}) {
  const { deploymentTarget, forceMinify, isTest } = Object.assign(defaultOptions, options);

  console.log('Deployment target:', deploymentTarget);
  if (isTest) {
    console.log('Running in test mode');
  }

  let config = multiconfig[deploymentTarget];

  if (isTest) {
    config = merge({}, config, multiconfig.test);
  }

  console.log('Feature flags:');
  Object.keys(config.featureFlags).forEach((flag) => {
    console.log(`  ${flag}:`, config.featureFlags[flag]);
  });
  console.log('');

  return config;
}
