/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { clone, cloneDeep } from 'lodash';
import * as moment from 'moment';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.controllers') //
  .controller('WashEditResourceCtrl', [
    '$scope',
    '$rootScope',
    'WASH_Resource',
    'WASH_Utilities',
    'WASH_Data',
    '$state',
    'SweetAlertUtil',
    function($scope, $rootScope, WASH_Resource, WASH_Utilities, WASH_Data, $state, SweetAlertUtil) {
      // //////////////////////////////////////////////////////////////////////////
      // Edit or Add resource meta data
      // //////////////////////////////////////////////////////////////////////////
      $scope.washResourceForm = {};
      $scope.washResourceForm.resource = {};
      // //////////////////////////////////////////////////////////////////////////
      const resourceId = $state.params.resourceId ? $state.params.resourceId : null;

      if (resourceId) {
        $scope.washResourceForm.formState = 'edit';
        $scope.washResourceForm.headerText = 'Edit resource details';
      } else {
        $scope.washResourceForm.formState = 'add';
        $scope.washResourceForm.headerText = 'Add resource details';
      }
      // //////////////////////////////////////////////////////////////////////////
      // coDevelopedBy : Managing here and pushing to array when submitting form
      $scope.washResourceForm.coDevelopedBy = {
        name: '',
        url: '',
        logo: {},
      };

      function setCoDevelopedBy(resource) {
        if (resource.coDevelopedBy) {
          $scope.washResourceForm.coDevelopedBy = resource.coDevelopedBy[0];
        }
      }

      function updateCoDevelopedBy(resource) {
        const ret = [];
        if (resource.coDeveloped) {
          ret.push($scope.washResourceForm.coDevelopedBy);
        }
        return ret;
      }
      // //////////////////////////////////////////////////////////////////////////
      // selectedTopics : Managing topics & pushing to array when submitting form
      $scope.washResourceForm.selectedTopics = WASH_Data.topicCheckboxes;

      function selectTopics(resource) {
        if (resource && resource.topics && resource.topics.length > 0) {
          resource.topics.forEach((topic) => {
            $scope.washResourceForm.selectedTopics[topic] = true;
          });
        }
      }

      function updateTopicsArray(topicsObj) {
        // @TODO: Duplicated in ng-ctrl-wash-newitem.js
        if (!topicsObj) return;

        const topicsArray = [];
        for (const key of Object.keys(topicsObj)) {
          if (topicsObj[key] === true) topicsArray.push(key);
        }
        return topicsArray;
      }
      // //////////////////////////////////////////////////////////////////////////
      // filteredTypes : Allows resource type change to everything but collection
      $scope.washResourceForm.filteredTypes = [];

      function setFilteredTypes() {
        const typeArray = WASH_Data.resourceTypes;
        const filterByCategory = function(obj) {
          // can select any type except collection
          return 'category' in obj && obj.category !== 'Collection';
        };
        $scope.washResourceForm.filteredTypes = typeArray.filter(filterByCategory);
      }

      function resourceTypeChangeAlert(newCategory, oldCategory) {
        if (newCategory && newCategory !== oldCategory) {
          if (newCategory === 'Simple') {
            SweetAlertUtil.alert(
              'Reminder to remove sub resources. The resource has changed from a ' +
                oldCategory +
                ' resource to a ' +
                newCategory +
                ' resource.',
            );
          } else if (newCategory === 'Complex') {
            SweetAlertUtil.alert(
              'Reminder to add sub resources. The resource has changed from a ' +
                oldCategory +
                ' resource to a ' +
                newCategory +
                ' resource.',
            );
          }
        }
      }
      // /////////////////////////////////////////////////////////////////////////
      $scope.washResourceForm.submitProcessing = false;
      $scope.washResourceForm.submitSuccess = false;
      $scope.washResourceForm.submitError = false;
      $scope.washResourceForm.errorMessage = 'Error';
      $scope.washResourceForm.processingMessage = 'Processing...';
      $scope.washResourceForm.successMessage = 'Successfully updated resource.';

      $scope.washResourceForm.disableEditing = function() {
        let ret = false;
        if ($scope.washResourceForm.flowUploadCount.length > -1) {
          ret = true;
        }
        if ($scope.washResourceForm.submitProcessing) {
          ret = true;
        }
        return ret;
      };

      const stateSubmitProcessing = function(processingMessage) {
        $scope.washResourceForm.submitProcessing = true;
        $scope.washResourceForm.submitSuccess = false;
        $scope.washResourceForm.submitError = false;
        if (processingMessage) $scope.washResourceForm.processingMessage = processingMessage;
      };

      const stateSubmitSuccess = function(successMessage?) {
        $scope.washResourceForm.submitProcessing = false;
        $scope.washResourceForm.submitSuccess = true;
        $scope.washResourceForm.submitError = false;
        if (successMessage) {
          $scope.washResourceForm.successMessage = successMessage;
        }
      };

      const stateSubmitError = function(errorMessage) {
        $scope.washResourceForm.submitProcessing = false;
        $scope.washResourceForm.submitSuccess = false;
        $scope.washResourceForm.submitError = true;
        if (errorMessage) $scope.washResourceForm.errorMessage = errorMessage;
      };

      const stateSubmitClear = function() {
        $scope.washResourceForm.submitProcessing = false;
        $scope.washResourceForm.submitSuccess = false;
        $scope.washResourceForm.submitError = false;
      };
      // /////////////////////////////////////////////////////////////////////////
      $scope.washResourceForm.cancel = function() {
        SweetAlertUtil.confirm('Are you sure you want to cancel? All changes will be lost.').then(
          (confirm) => {
            if (confirm) {
              if ($scope.washResourceForm.formState === 'edit') {
                $state.go('wash.resource', { resourceId });
              } else {
                $state.go('wash.newresource');
              }
            }
          },
          (p) => {
            /* fail */
          },
        );
      };
      // /////////////////////////////////////////////////////////////////////////
      $scope.washResourceForm.submit = function(isValid) {
        const resource = $scope.washResourceForm.resource;

        if (!isValid) {
          stateSubmitError('Please fill out all required fields.');
          return;
        }

        if (resource.coDeveloped && $scope.washResourceForm.coDevelopedBy.length <= 0) {
          stateSubmitError(
            'When "Co-Developed" is selected at least 1 co-developed partner must be added.',
          );
          return;
        }

        stateSubmitProcessing('Editing ' + $scope.washResourceForm.beforeResource.title + '...');
        resource.topics = updateTopicsArray($scope.washResourceForm.selectedTopics);
        resource.coDevelopedBy = updateCoDevelopedBy(resource);

        WASH_Resource.editResource(resource, (data) => {
          if (data) {
            $scope.washResourceForm.resource = data;
            $scope.washResourceForm.beforeResource = cloneDeep(data);
            resourceTypeChangeAlert(
              $scope.washResourceForm.resource.category,
              $scope.washResourceForm.beforeResource.category,
            );
            stateSubmitSuccess();
          } else {
            stateSubmitError(
              'Error: Cannot update ' + $scope.washResourceForm.beforeResource.title,
            );
          }
        });
      };
      // /////////////////////////////////////////////////////////////////////////
      const getResourceDetails = function() {
        $scope.washResourceForm.loadingResource = true;

        WASH_Resource.getResource(resourceId, (data) => {
          if (data) {
            $scope.washResourceForm.resource = data;
            $scope.washResourceForm.beforeResource = cloneDeep(data);
            selectTopics($scope.washResourceForm.beforeResource);
            setCoDevelopedBy($scope.washResourceForm.resource);
            setFilteredTypes();
            $scope.washResourceForm.loadingResource = false;
          } else {
            stateSubmitError(
              'Cannot retrieve information on resource. Please contact administrative team.',
            );
          }
        });
      };
      // /////////////////////////////////////////////////////////////////////////
      if ($scope.washResourceForm.formState === 'edit') {
        getResourceDetails();
      }
      // /////////////////////////////////////////////////////////////////////////
      // image file upload
      $rootScope.fileController = $scope;
      $scope.washResourceForm.flowUploadCount = -1;

      $scope.washResourceForm.washBucketUrl = function(uri) {
        return uri ? deploymentConfig.resources.assetBaseUrls.wash + '/' + uri : '';
        // return uri ? 'https://s3-us-west-2.amazonaws.com/cawst-wash-dev/' + uri : '';
      };

      $scope.washResourceForm.getFlowTarget = function(filename) {
        return filename.url;
      };

      $scope.washResourceForm.onFileAdded = function($file, $message, $flow) {
        const file = $file;
        if (file.size >= 5 * 1024 * 1024) {
          SweetAlertUtil.alert('File exceeds size limit of 5MB.');
          return false;
        }
        if (!WASH_Utilities.isImageMimeType(file.file.type)) {
          SweetAlertUtil.alert('File type can only be .jpg, .png, or .gif.');
          return false;
        }
        const filenames = [];
        filenames.push(file.name);
        WASH_Utilities.addLogoUpload(filenames, (resUrl) => {
          $file.url = resUrl[0];
          $file.uri = WASH_Utilities.getUriFromUrl(resUrl[0]);
          $flow.upload();
        });
        $scope.washResourceForm.flowUploadCount += 1;
      };

      $scope.washResourceForm.onFileSuccess = function($file, $message, $flow) {
        $scope.washResourceForm.coDevelopedBy.logo = {
          name: $file.name,
          type: $file.file.type,
          uri: $file.uri,
          fileSize: $file.size,
          upload_time: moment().toISOString(),
          new: true, // DON'T TOUCH
        };
        $file.cancel();
        $scope.washResourceForm.flowUploadCount -= 1;
      };

      $scope.washResourceForm.removeFile = function(file) {
        SweetAlertUtil.confirm('Are you sure you want to delete this file?').then(
          (confirm) => {
            if (confirm) {
              // remove file from object
              $scope.washResourceForm.coDevelopedBy.logo = {};

              if (file && file.new) {
                // If the file was uploaded in this session and is in the tmp folder
                WASH_Utilities.removeUploadedFile(file.uri, (res) => {
                  // do nothing
                });
              } else {
                // If the file was saved earlier and is in the final files folder
                WASH_Utilities.removeSavedFile(file.uri, (res) => {
                  // do nothing
                });
              }
            }
          },
          (p) => {
            /* fail */
          },
        );
      };

      $scope.washResourceForm.downloadUploadedFile = function(file) {
        if (file.new) {
          WASH_Utilities.downloadUploadedFile(file.uri);
        } else {
          WASH_Utilities.downloadFile(file.uri);
        }
      };
      // /////////////////////////////////////////////////////////////////////////
    },
  ]);
