/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import * as moment from 'moment';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.controllers') //
  .controller('WashController', [
    '$scope',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'WASH_Utilities',
    'WASH_Data',
    function($scope, $rootScope, $state, $window, authService, WASH_Utilities, WASH_Data) {
      $scope.formatDate = function(date) {
        return moment(date).format('MMMM, YYYY');
      };

      $scope.formatDateLong = function(date) {
        return moment(date).format('LLL');
      };

      $scope.removeFilePath = function(fileUri) {
        // File path is 'files/', i.e. 'files/HWTS Product Sheet_2011-06_en.pdf'
        const fileName = fileUri.replace('files/', '');
        return fileName;
      };

      $scope.downloadFile = function(file) {
        if (file.type === 'link') {
          $window.open(file.uri);
        } else {
          $window.open(
            deploymentConfig.api.baseUrls.wash +
              '/file?uri=' +
              file.uri +
              '&authorization=' +
              authService.retrieveAccessToken(),
          );
        }
      };

      $scope.GetFileIconClass = function(fileType) {
        const fileMimeType = WASH_Data.fileMimeType;
        let iconClass = 'fa-file-o';
        if (fileType) {
          for (const key of Object.keys(fileMimeType)) {
            const mimeType = fileMimeType[key];
            if (fileType === mimeType.type) {
              iconClass = mimeType.icon;
            }
          }
        }
        return iconClass;
      };
    },
  ]); // end WASH Controllers
