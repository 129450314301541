import * as angular from 'angular';

angular.module('app').directive('washSearchFilters', [
  'WASH_Data',
  '$timeout',
  (WASH_Data, $timeout) => {
    const categories = [
      { value: 'Simple', label: 'Simple Resource' },
      { value: 'Complex', label: 'Complex Resource' },
      { value: 'Collection', label: 'Collection' },
    ];

    const featured = [
      { value: 'frontpage', label: 'Front page' },
      { value: 'posters_and_activities', label: 'Posters, activities, and videos' },
      { value: 'training', label: 'Training resources' },
      { value: 'technical', label: 'Technical resources' },
    ];

    const languages = WASH_Data.languages;

    const orderBy = [
      { value: 'lastModified', label: 'Last Modified' },
      { value: 'publicationDate', label: 'Publication date' },
      { value: 'title', label: 'Title' },
    ];

    const regions = WASH_Data.regions;

    const statuses = [
      { value: 'published', label: 'Published' },
      { value: 'archived', label: 'Archived' },
      { value: 'draft', label: 'Draft' },
    ];

    const topics = [
      { value: 'Water Supply' },
      { value: 'Drinking Water Quality Testing' },
      { value: 'Sanitation' },
      { value: 'Delivering and Creating Training' },
      { value: 'Household Water Treatment and Safe Storage' },
      { value: 'Biosand Filter' },
      { value: 'WASH and Health' },
      { value: 'Monitoring for Improvement' },
      { value: 'Community WASH Promotion and Hygiene' },
      { value: 'Evaluation' },
    ];

    const types = WASH_Data.resourceTypes;

    const washDefinitions = WASH_Data.washDefinitions;

    return {
      restrict: 'E',
      templateUrl: 'scripts/directives/wash-search-filters/wash-search-filters.html',
      scope: {
        clearFilters: '=',
        search: '=',
        selectedCategory: '=category',
        selectedFeatured: '=featured',
        selectedLanguage: '=language',
        selectedOrderBy: '=orderBy',
        selectedRegion: '=region',
        selectedStatus: '=status',
        selectedTopic: '=topic',
        selectedType: '=type',
      },
      link(scope, element, attributes) {
        scope.applyFilter = (label, value) => {
          // NOTE: In AngularJS onChange gets triggered before the model is
          // updated in the isolated scope. This is expected behaviour for
          // AngularJS, but causes UI issues as the filter selected isn't
          // applied. Using `$timeout()` to run `search()` after the digest is
          // over and all bindings have been propagated.
          // https://github.com/angular/angular.js/issues/4558
          $timeout(() => {
            scope.search();
          });
        };

        scope.categories = categories;
        scope.featured = featured;
        scope.languages = languages;
        scope.orderBy = orderBy;
        scope.regions = regions;
        scope.statuses = statuses;
        scope.topics = topics;
        scope.types = types;
        scope.washDefinitions = washDefinitions;
      },
    };
  },
]);
