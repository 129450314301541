/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { cloneDeep, findIndex, isEmpty } from 'lodash';

angular
  .module('app.controllers') //
  .controller('WashNewResourceCtrl', [
    '$scope',
    '$state',
    'WASH_Utilities',
    'WASH_Resource',
    'WASH_Data',
    'SweetAlertUtil',
    function($scope, $state, WASH_Utilities, WASH_Resource, WASH_Data, SweetAlertUtil) {
      // ///////////////////////////////////////////////////////////////////////
      $scope.washNewResource = {}; // controller object
      $scope.washNewResource.headerText = 'Add a new resource';
      $scope.washNewResource.newResource = {};
      $scope.washNewResource.draftResource = {};
      $scope.washNewResource.selectedTopics = WASH_Data.topicCheckboxes;
      $scope.washNewResource.resourceTypes = WASH_Data.resourceTypes;
      $scope.washNewResource.washDefinitions = WASH_Data.washDefinitions;
      // ///////////////////////////////////////////////////////////////////////
      // # Form States
      // Form states are: | 'select type' | 'add details' |
      if (
        $state.params.type &&
        findIndex($scope.washNewResource.resourceTypes, { value: $state.params.type })
      ) {
        $scope.washNewResource.formState = 'add details';
        $scope.washNewResource.newResource.type = $state.params.type;
      } else {
        $scope.washNewResource.formState = 'select type';
      }

      $scope.washNewResource.formStateTitle = function() {
        const formState = $scope.washNewResource.formState;
        if (formState === 'select type') {
          return 'Select resource type';
        }
        if (formState === 'add details') {
          return 'Add resource details';
        }
      };

      $scope.washNewResource.formStateDesc = function() {
        const formState = $scope.washNewResource.formState;
        if (formState === 'select type') {
          return "Select the type of resource you are creating. If you don't see the type you're looking for, or have any questions contact the admin team.";
        }
        return '';
      };
      // ///////////////////////////////////////////////////////////////////////
      // # Form Alerts and Processing
      $scope.washNewResource.formProcessing = false;
      $scope.washNewResource.formSuccess = false;
      $scope.washNewResource.formError = false;

      const FormProcessing = function(processingMessage) {
        $scope.washNewResource.formProcessing = true;
        $scope.washNewResource.formSuccess = false;
        $scope.washNewResource.formError = false;
        $scope.washNewResource.processingMessage = processingMessage;
      };

      const FormSuccess = function(successMessage) {
        $scope.washNewResource.formProcessing = false;
        $scope.washNewResource.formSuccess = true;
        $scope.washNewResource.formError = false;
        $scope.washNewResource.successMessage = successMessage;
      };

      const FormError = function(errorMessage) {
        $scope.washNewResource.formProcessing = false;
        $scope.washNewResource.formSuccess = false;
        $scope.washNewResource.formError = true;
        $scope.washNewResource.errorMessage = errorMessage;
      };

      const FormClear = function() {
        $scope.washNewResource.formProcessing = false;
        $scope.washNewResource.formSuccess = false;
        $scope.washNewResource.formError = false;
      };

      $scope.washNewResource.disableEditing = function() {
        return $scope.washNewResource.formProcessing;
      };

      $scope.washNewResource.goToNextStep = function(step) {
        $scope.washNewResource.formState = step;
      };

      $scope.washNewResource.cancel = function() {
        SweetAlertUtil.confirm('Are you sure you want to cancel? All changes will be lost.').then(
          (confirm) => {
            if (confirm) {
              if (isEmpty($scope.washNewResource.draftResource)) {
                $scope.washNewResource.formState = 'select type';
                $scope.washNewResource.newResource = {};
                $scope.washNewResource.draftResource = {};
                $state.go('wash.newresource', { type: null }, { reload: false });
              } else {
                $state.go('wash.resource', { resourceId: $scope.washNewResource.draftResource.id });
              }
            }
          },
          (p) => {
            /* fail */
          },
        );
      };
      // ///////////////////////////////////////////////////////////////////////
      // # Step 1: Select Type
      $scope.washNewResource.searchType = '';
      $scope.washNewResource.selectType = function(type) {
        $scope.washNewResource.newResource.type = type;
        $state.go('wash.newresource', { type });
        $scope.washNewResource.formState = 'add details';
      };
      // ///////////////////////////////////////////////////////////////////////
      // # Step 2: Add resource details
      const CreateSelectedTopicsArray = function(topicsObj) {
        // Duplicated in ng-ctrl-wash-edit.js
        const topicsArray = [];
        if (topicsObj) {
          for (const key of Object.keys(topicsObj)) {
            if (topicsObj[key] === true) {
              topicsArray.push(key);
            }
          }
          return topicsArray;
        }
        // console.log('Error: CreateSelectedTopicsArray - Requires selectedTopicsObj');
      };

      CreateSelectedTopicsArray($scope.washNewResource.selectedTopics);

      $scope.washNewResource.submitDetails = function(isValid) {
        const resource = $scope.washNewResource.newResource;
        resource.publicationStatus = 'draft';
        resource.topics = CreateSelectedTopicsArray($scope.washNewResource.selectedTopics);

        if (isValid) {
          FormProcessing('Adding new resource...');
          WASH_Resource.addResource(resource, (data) => {
            if (data) {
              $scope.washNewResource.draftResource = data;
              FormSuccess('Successfully added new resource.');
            } else {
              FormError('Error: Cannot add new resource.');
            }
          });
        } else {
          FormError('Please fill out all required fields.');
        }
      };
      // ///////////////////////////////////////////////////////////////////////
    },
  ]);
