/* tslint:disable:only-arrow-functions */
/* tslint:disable:no-console */
import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.services') //
  .service('WASH_HttpWrapper', [
    '$http',
    '$state',
    function($http, $state) {
      return {
        httpWrapper(method, command, cb, data?) {
          $http({
            method,
            url: deploymentConfig.api.baseUrls.wash + command,
            data,
            headers: { 'Content-Type': 'application/json' },
          }).then(
            (response) => {
              if (cb) cb(response.data);
            },
            (err) => {
              if (err.status === 401) {
                $state.go('signin');
              } else {
                console.error(err);
                if (cb) cb(null);
              }
            },
          );
        },
      };
    },
  ]);
