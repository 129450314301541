/* tslint:disable:no-parameter-reassignment */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { toArray } from 'lodash';
import { getPathnameFromUrl } from '@src/submodules/cawst-core/util/getPathnameFromUrl';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.services') //
  .service('WavemakersProjects', [
    '$http',
    '$state',
    '$uibModal',
    '$window',
    'fbutil',
    function($http, $uibModal, $state, $window, fbutil) {
      const syncMyRegList = []; // FB syncarray
      const syncMyReginProg = false; // used to avoid too many sync fb calls at once

      function addId(obj) {
        for (const key of Object.keys(obj)) {
          obj[key].id = key;
        }
        return obj;
      }

      const projectTypes = [
        {
          type: 'local',
          label: 'Water Conservation',
          description: 'Rain gardens, rain barrels, low-flush toilets, etc.',
        },
        {
          type: 'local',
          label: 'Sanitation / Water Quality',
          description: 'Storm water, pesticides, run-off, etc.',
        },
        { type: 'local', label: 'Hygiene', description: 'Hand washing or other personal hygiene.' },
        { type: 'local', label: 'Other', description: '' },
        { type: 'global', label: 'WASH Awareness', description: '' },
        { type: 'global', label: 'WASH Fundraising', description: '' },
        {
          type: 'global',
          label: 'Water',
          description: 'Improving access to clean, safe drinking water.',
        },
        { type: 'global', label: 'Sanitation', description: 'Improving access to toilets.' },
        {
          type: 'global',
          label: 'Hygiene',
          description: 'Improving access to hand washing or other personal hygiene.',
        },
        { type: 'global', label: 'Other', description: '' },
      ];

      const actionTypes = [
        'Engage Your Community',
        'Educate and Empower',
        'Make Sustainable Choices',
        'Work With Partners',
        'Use Appropriate Technology',
      ];

      const wmResources = [
        { id: 'tikho_story', label: 'Tikho’s Story Slideshow' },
        { id: 'kids_of_kayele', label: 'Kids of Kayele online game' },
        { id: 'flood_of_change', label: 'Flood of Change documentary film' },
        { id: 'youtube_channel', label: 'YouTube videos' },
        { id: 'sanitation_story_game', label: 'Sanitation Story card game' },
        { id: 'three_pile_sort_africa', label: '3 pile sort Africa card game' },
        { id: 'three_pile_sort_nam', label: '3 pile sort North America card game' },
        {
          id: 'bsf_lesson_plan',
          label: 'Water Filtration/ How to build a biosand filter lesson plan',
        },
        { id: 'global_village_mystery', label: 'Global Village Mystery game' },
        { id: 'water_watchers', label: 'Water Watchers game' },
        { id: 'water_audit', label: 'Water Audit' },
        { id: 'tikho_daniel_save_day', label: 'Tikho and Daniel Save the Day Play' },
        { id: 'other', label: 'Other', value: '' },
      ];

      const provinces = [
        { abbr: 'AB', name: 'Alberta' },
        { abbr: 'BC', name: 'British Columbia' },
        { abbr: 'MB', name: 'Manitoba' },
        { abbr: 'NB', name: 'New Brunswick' },
        { abbr: 'NL', name: 'Newfoundland and Labrador' },
        { abbr: 'NS', name: 'Nova Scotia' },
        { abbr: 'NT', name: 'Northwest Territories' },
        { abbr: 'NU', name: 'Nunavut' },
        { abbr: 'ON', name: 'Ontario' },
        { abbr: 'PE', name: 'Prince Edward Island' },
        { abbr: 'QC', name: 'Quebec' },
        { abbr: 'SK', name: 'Saskatchewan' },
        { abbr: 'YT', name: 'Yukon' },
      ];

      // TOREFACTOR: Remove duplication; `httpWrapper` exists in other files
      function httpWrapper(method, command, cb, data?) {
        $http({
          method,
          url: deploymentConfig.api.baseUrls.main + command,
          data,
        }).then(
          (response) => {
            if (cb) {
              cb(response.data);
            }
          },
          (response) => {
            if (response.status === 401) {
              $state.go('signin');
            } else if (cb) {
              cb(null);
            }
          },
        );
      }

      return {
        // /////////////////////////////////////////////////////////////////////
        actionTypes,
        provinces,
        projectTypes,
        wmResources,
        // /////////////////////////////////////////////////////////////////////
        addWmProject(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.push('wm_project_sharing/projects', item, (_data) => {
            cb();
          });
        },
        updateWmProject(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.set('wm_project_sharing/projects', item.id, item, (_data) => {
            cb();
          });
        },
        getAllWmProjects(cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.get('wm_project_sharing/projects/all', (data) => {
            cb(toArray(addId(data)));
          });
        },
        // deleteWmProject: function(item,cb) {
        // 	cb = cb || function() {}
        // 	fbutil.remove('wm_project_sharing/projects',item.id,function(data){
        // 		cb()
        // 	})
        // },
        // /////////////////////////////////////////////////////////////////////
        // # Close Report
        addWmCloseReport(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.push('wm_project_sharing/close_reports', item, (id) => {
            cb(id);
          });
        },
        updateWmCloseReport(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.set('wm_project_sharing/close_reports', item.id, item, (_data) => {
            cb();
          });
        },
        getWmCloseReports(cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.get('wm_project_sharing/close_reports/all', (data) => {
            cb(toArray(addId(data)));
          });
        },
        // /////////////////////////////////////////////////////////////////////
        // closeReport: function(cb) {
        // 	// cb = cb || function() {}
        // 	// fbutil.push('wm_project_sharing/close_reports',item,function(data){
        // 	// 	fbutil.set('wm_project_sharing/projects',data.id,data,function(data){
        // 	// 		cb()
        // 	// 	})
        // 	// })
        // },
        // /////////////////////////////////////////////////////////////////////
        // # image uploader
        getUriFromUrl(url) {
          return getPathnameFromUrl(url);
        },
        uploadImage(filename, cb) {
          httpWrapper('PUT', '/file?filename=' + filename + '&subfolder=action_projects', cb);
        },
        deleteImage(uri, cb) {
          httpWrapper('DELETE', '/file?uri=' + uri, cb);
        },
        // /////////////////////////////////////////////////////////////////////
      };
    },
  ]);
