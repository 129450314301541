/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import * as moment from 'moment';

import { deploymentConfig } from '@src/client/config/deploymentConfig';
import { getFileSize } from '../../../submodules/cawst-angularjs/utils/file/getFileSize';

angular.module('app.controllers').controller('WavemakersProjectsCtrl', [
  '$http',
  '$scope',
  '$state',
  '$window',
  'Notification',
  'WavemakersProjects',
  function($http, $scope, $state, $window, Notification, WavemakersProjects) {
    // /////////////////////////////////////////////////////////////////////////
    $scope.provinces = WavemakersProjects.provinces;
    $scope.projectTypes = WavemakersProjects.projectTypes;
    $scope.actionTypes = WavemakersProjects.actionTypes;
    $scope.errorMessage = '';
    $scope.pubStatus = [
      { label: 'Published', value: true },
      { label: 'Unpublished', value: false },
    ];
    // /////////////////////////////////////////////////////////////////////////
    function reloadProjects() {
      $scope.loading = true;
      WavemakersProjects.getAllWmProjects((data) => {
        $scope.projectsList = data;
        $scope.loading = false;
      });
    }
    // /////////////////////////////////////////////////////////////////////////
    // # Add New Project Form
    $scope.project = {}; // Default object for the add and edit form

    $scope.addNewProject = function() {
      $scope.project = {};
      $scope.editmode = true;
      $scope.addnewmode = true;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.modifyProject = function(project) {
      $scope.project = project;
      $scope.editmode = true;
      $scope.addnewmode = false;
      $scope.projectTitle = $scope.project.title;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.openCloseReport = function(project) {
      $scope.editmode = false;
      $state.go('wmprojects.closereport', { id: project.id });
    };
    // /////////////////////////////////////////////////////////////////////////
    // ** not deleting projects at the moment
    // $scope.deleteProject = function(project) {
    // 	if(!confirm('Are you sure you want to delete this project?')) {
    // 		return;
    // 	}
    // 	WavemakersProjects.deleteWmProject(project,function(){
    // 		reloadProjects()
    // 	})
    // }
    // /////////////////////////////////////////////////////////////////////////
    $scope.saveProject = function(project) {
      $scope.project.date_created = moment().toISOString();
      $scope.project.date_started = moment($scope.project.date_started).toISOString();
      $scope.project.date_finished = moment($scope.project.date_finished).toISOString();

      if (!$scope.addnewmode) {
        WavemakersProjects.updateWmProject(project, () => {
          Notification.success('Project saved');
          $scope.exitAdd();
          reloadProjects();
        });
      } else {
        WavemakersProjects.addWmProject(project, () => {
          Notification.success('New Project added');
          $scope.exitAdd();
          reloadProjects();
        });
      }
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.exitAdd = function() {
      $scope.editmode = false;
      $scope.addnewmode = false;
      $scope.projectTitle = '';
      $scope.project = {};
      setTimeout(() => {
        $window.scrollTo(0, 0);
      }, 1);
    };
    // /////////////////////////////////////////////////////////////////////////
    // # Image Upload
    $scope.deleteFileProperties = function() {
      if ($scope.project.img_main) {
        delete $scope.project.img_main;
      }
    };
    // /////////////////////////////////////////////////////////////////////////
    // no longer doing thumbs, not worth it just size picture properly
    // img_main, img_main_uri
    $scope.deleteImage = function(project) {
      if (!$window.confirm('Are you sure you want to delete this image?')) {
        return;
      }
      let imageUri = $scope.project.img_main_uri;
      if (!$scope.project.img_main_uri) {
        imageUri = 'uploads/action_projects/' + project.img_main;
      }
      WavemakersProjects.deleteImage(imageUri, (res) => {
        if (res) {
          $scope.deleteFileProperties();
        } else {
          $scope.errorMessage =
            'there was an issue deleting the image, please contact the web team for support.';
        }
      });
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.onImageAdded = function($file, $message, $flow) {
      if ($file.file.type.indexOf('image/') !== 0) {
        $window.alert('Only image files are allowed.');
        return false;
      }
      if ($file.size >= 5 * 1024 * 1024) {
        $window.alert('File exceeds size limit of 5MB.');
        return false;
      }
      WavemakersProjects.uploadImage($file.name, (res) => {
        if (res) {
          $scope.file = $file;
          $file.url = res.url;
          $file.uri = WavemakersProjects.getUriFromUrl(res.url);          
          $flow.opts.target = res.url;
          $flow.upload();
        } else {
          $scope.errorMessage =
            'There was an issue uploading the file, please contact the web team for support.';
        }
      });
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.onImageSuccess = function($file, $message, $flow) {
      $scope.project.img_main = $file.relativePath;
      $scope.project.img_main_uri = $file.uri;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.getImageUrl = function(project) {
      if (project.img_main_uri) {
        return deploymentConfig.resources.imageBaseUrls.main + '/' + project.img_main_uri;
      }
      return (
        deploymentConfig.resources.imageBaseUrls.main +
        '/uploads/action_projects/' +
        project.img_main
      );
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.formatProgress = function(decimal) {
      return Math.round(decimal * 100) + '%';
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.getFileSize = getFileSize;
    // ////////////////////////////////////////////////////////////////////////
    // # MISC Form Stuff
    // /////////////////////////////////////////////////////////////////////////
    $scope.updatePublishStatus = function(pStatus) {
      $scope.project.published = pStatus;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.open = function(cal, $event) {
      $scope.status[cal].opened = true;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.status = {
      start: {
        opened: false,
      },
      end: {
        opened: false,
      },
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.dateOptions = {
      formatYear: 'yy',
      startingDay: 1,
    };
    // /////////////////////////////////////////////////////////////////////////
    reloadProjects();
    // /////////////////////////////////////////////////////////////////////////
  },
]);
