/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';

angular
  .module('app.controllers') //
  .controller('WashSnapshotCtrl', [
    '$scope',
    '$state',
    'WASH_Utilities',
    function($scope, $state, WASH_Utilities) {
      $scope.loading = true;
      $scope.headerText = 'Snapshots';
      $scope.snapshots = null;

      const resourceId = $state.params.resourceId;

      WASH_Utilities.getSnapshots(resourceId, (data) => {
        // console.log(data);
        // if (data) {
        //     data.forEach(function(item) {
        //         var snapshot = item;
        //         item = {
        //             name: snapshot.name,
        //             dateCreated: snapshot.dateCreated,
        //             resource: $scope.getResourceInfo(snapshot.resource)
        //         }
        //         item = JSON.stringify(item,null,4);
        //     });
        //
        // }
        $scope.snapshots = data;
        $scope.loading = false;
      });

      // $scope.getResourceInfo=function(resource) {
      //     if(!resource.documents)
      //         resource.documents=[];
      //     var documents=resource.documents;
      //     for(var j=0;j<documents.length;j++) {
      //         var doc=documents[j];
      //         var files=doc.files?doc.files:[];
      //         for(var k=0;k<files.length;k++) {
      //             var file=files[k];
      //             files[k]=file.uri;
      //         }
      //         documents[j]={
      //             language:doc.language,
      //             region:doc.region,
      //             files:files
      //         };
      //     }
      //     if(!resource.children)
      //         resource.children=[];
      //     var children=resource.children;
      //     for(var j=0;j<children.length;j++) {
      //         var child=children[j];
      //         children[j]=$scope.getResourceInfo(child);
      //     }
      //     return {
      //         id:resource.id,
      //         category:resource.category,
      //         type:resource.type,
      //         title:resource.title,
      //         description:resource.description,
      //         languages:resource.languages,
      //         lastModified:resource.lastModified,
      //         lastModifiedBy:resource.lastModifiedBy,
      //         theme:resource.theme,
      //         topics:resource.topics,
      //         documents:documents,
      //         children:children
      //     };
      // };
    },
  ]);
