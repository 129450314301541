/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { capitalize } from 'lodash';
import * as moment from 'moment';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.controllers') //
  .controller('ActivityLogCtrl', [
    '$http',
    '$scope',
    '$window',
    'WASH_Utilities',
    'WASH_Resource',
    function($http, $scope, $window, WASH_Utilities, WASH_Resource) {
      $scope.pagination = {
        currentPage: 1,
        itemCount: 0,
        itemsPerPage: 50,
        items: [],
      };

      $scope.loading = true;
      $scope.headerText = 'Activity Log';
      $scope.searchUrl = null;

      $scope.updateSearchUrl = () => {
        $scope.searchUrl =
          deploymentConfig.api.origin +
          '/activitylog/wash/' +
          $scope.pagination.currentPage +
          '/' +
          $scope.pagination.itemsPerPage +
          '?';
      };

      $scope.normalizeActivityData = (items) => {
        const newItems = [];
        items.forEach((item) => {
          const newItem = item;
          if (item.data.document && item.data.document.id) {
            WASH_Resource.getResourceByDocument(item.data.document.id, (data) => {
              newItem.data.resource = data;
            });
          }
          newItems.push(newItem);
        });
        return newItems;
      };

      $scope.getItemsForCurrentPage = () => {
        $scope.updateSearchUrl();
        $http.get($scope.searchUrl).then(
          (response) => {
            $scope.pagination.items = $scope.normalizeActivityData(response.data.items);
            $scope.pagination.itemCount = response.data.totalItemCount;
            $scope.loading = false;
          },
          (err) => {
            if (err.data) $window.alert(err.data);
            $scope.loading = false;
          },
        );
      };

      $scope.onPageChange = (newPageNumber) => {
        $scope.pagination.currentPage = newPageNumber;
        $scope.getItemsForCurrentPage();
      };

      $scope.formatDate = (date) => {
        return moment(date).format('LLL');
      };

      $scope.getItemsForCurrentPage();

      // /////////////////////////////////////////////////////////////////////////
      // # Data display
      $scope.eventLabelMap = {
        'searchindex.update': 'Search index updated',
        snapshot: 'Resource snapshot created',
        'resource.create': 'Resource created',
        'resource.update': 'Resource edited',
        'resource.delete': 'Resource deleted',
        'document.create': 'Document created',
        'document.update': 'Document edited',
        'document.delete': 'Document deleted',
      };

      $scope.statusOptions = ['published', 'archived', 'draft'];

      $scope.changesExist = (item) => {
        let changes = false;
        if (item && item.data && item.data.changes && item.data.changes.length > 0) {
          changes = true;
        }
        return changes;
      };

      $scope.statusChange = (item) => {
        let status = null;
        const changes = $scope.changesExist(item) ? item.data.changes : [];
        if (changes.length > 0) {
          changes.forEach((change) => {
            if (
              change.hasOwnProperty('newVal') &&
              $scope.statusOptions.indexOf(change.newVal) > -1
            ) {
              status = change.newVal;
            }
          });
        }
        return status;
      };

      $scope.displayEvent = (item) => {
        let label = item.type;
        const typeArr = item.type.split('.');
        if ($scope.eventLabelMap.hasOwnProperty(item.type)) label = $scope.eventLabelMap[item.type];
        if ($scope.statusChange(item) && (typeArr[0] === 'resource' || typeArr[0] === 'document')) {
          label =
            capitalize(typeArr[0]) +
            ' ' +
            $scope.statusChange(item) +
            (($scope.changesExist(item) && item.data.changes.length) > 1 ? ' and edited' : '');
        }
        return label;
      };

      $scope.hideChange = (change) => {
        let hide = false;
        const propertyChangesToHide = [
          'thumbnails.0.fileSize',
          'thumbnails.0.type',
          'thumbnails.0.width',
          'files.0.fileSize',
          'files.0.pages',
          'slugType',
        ];
        if (change.property) {
          const split = change.property.split('.');
          if (propertyChangesToHide.indexOf(change.property) > -1) {
            hide = true;
          }
          if (split[0] === 'snapshots') {
            hide = true;
          }
        }
        return hide;
      };
    },
  ]);
