/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import * as moment from 'moment';

angular
  .module('app.controllers') //
  .controller('HwtsItemsCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    'HWTS_Utilities',
    function($rootScope, $scope, $state, $window, HWTS_Utilities) {
      $scope.showPublishStatus = true;

      $scope.pagination = {
        currentPage: 1,
        itemCount: 0,
        itemsPerPage: 10,
        items: [],
      };

      $scope.loading = true;
      $scope.state = $state.$current.name;
      const states = $scope.state.split(/[. ]+/);
      $scope.site = states[0];
      $scope.type = states[1];
      $scope.typeText = toTitleCase($scope.type);
      $scope.headerText = formatTitle($scope.typeText);
      $scope.filterValue = '';
      $scope.includeDeletedItems = false;

      if ($scope.type === 'organization') {
        $scope.showPublishStatus = false;
      }

      $scope.getItemsForCurrentPage = function() {
        HWTS_Utilities.getPage(
          $scope.type,
          $scope.pagination.currentPage,
          $scope.pagination.itemsPerPage,
          this.filterValue,
          $scope.includeDeletedItems,
          (data) => {
            if (!data) {
              $window.alert('Server error');
              $state.go('signin');
            }
            for (const i of Object.keys(data.items)) {
              if (!data.items[i].title && data.items[i].name) {
                data.items[i].title = data.items[i].name;
              }
              if (!data.items[i].title && data.items[i].lastName && data.items[i].firstName) {
                data.items[i].title = data.items[i].lastName + ', ' + data.items[i].firstName;
              }

              if (!data.items[i].title || data.items[i].title.trim() === 0) {
                data.items[i].title = '[No Title]';
              }
            }
            $scope.pagination.items = data.items;
            $scope.pagination.itemCount = data.totalItemCount;
            $scope.loading = false;
          },
        );
      };

      $scope.getItemsForCurrentPage();

      $scope.onPageChange = function(newPageNumber) {
        $scope.pagination.currentPage = newPageNumber;
        $scope.getItemsForCurrentPage();
      };

      $scope.updateStatus = function(item, status) {
        const statusObj: any = {};
        statusObj.publicationStatus = status;
        const queryParams = '';
        if (status === 'Archived') {
          const reason = $window.prompt('Please enter reason for archival:');
          if (!reason) return;
          statusObj.archivalReason = reason;
        }
        HWTS_Utilities.updatePubStatus(statusObj, $scope.type, item, (_response) => {
          $scope.getItemsForCurrentPage();
        });
      };

      $scope.edit = function(item) {
        $state.go('.edit', { id: item.id });
      };
      $scope.formatDate = function(dateStr) {
        const date = moment(dateStr);
        if (dateStr && dateStr.length > 0 && date.isValid()) {
          return date.format('MMM-DD-YYYY HH:mm:ss');
        }
        return '';
      };
      function toTitleCase(str) {
        return str.replace(/\w\S*/g, (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      }
      function formatTitle(str) {
        if (str === 'Casestudy') {
          return 'Case Studies';
        }
        if (str === 'Prodtech') {
          return 'Products/Technologies';
        }
        if (str === 'Bsfproducer') {
          return 'Biosand Filter Producers';
        }
        return str;
      }
    },
  ]);
