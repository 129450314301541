/* tslint:disable:forin */
/* tslint:disable:function-name */
/* tslint:disable:no-bitwise */
/* tslint:disable:no-increment-decrement */
/* tslint:disable:no-parameter-reassignment */
/* tslint:disable:no-shadowed-variable */
/* tslint:disable:no-this-assignment */
/* tslint:disable:no-var-keyword */
/* tslint:disable:one-variable-per-declaration */
/* tslint:disable:only-arrow-functions */
/* tslint:disable:prefer-const */
/* tslint:disable:prefer-for-of */
/* tslint:disable:ter-prefer-arrow-callback */

import * as angular from 'angular';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.controllers') //
  .controller('TranslationListController', [
    '$scope',
    '$window',
    '$state',
    '$rootScope',
    '$http',
    function($scope, $window, $state, $rootScope, $http) {
      $scope.pagination = {
        currentPage: 1,
        itemCount: 0,
        itemsPerPage: 100,
        items: [],
      };

      $scope.loading = true;
      $scope.headerText = 'Translation';
      $scope.translationType = 'all';
      $scope.showConflictsOnly = true;
      $scope.searchUrl = null;
      $scope.currentType = null;

      $scope.refreshWashMetadata = function() {
        $http.post(deploymentConfig.api.origin + '/translation/secure/metadata/wash').then(
          function(response) {
            // alert('Done.');
          },
          function(err) {
            alert('Error: ' + err.toString());
          },
        );
      };

      $scope.refreshHwtsMetadata = function() {
        $http.post(deploymentConfig.api.origin + '/translation/secure/metadata/hwts').then(
          function(response) {
            // alert('Done.');
          },
          function(err) {
            alert('Error: ' + err.toString());
          },
        );
      };

      $scope.downloadResults = function() {
        $scope.updateSearchUrl();
        $http.get($scope.searchUrl + '&all=true').then(
          function(response) {
            var data = [['en_GB', 'fr_FR', 'es_AR']];
            for (var i in response.data.items) {
              var item = response.data.items[i];
              data.push([item.en, item.fr, item.es]);
            }
            var ws_name = 'Sheet1';
            // @ts-ignore
            var wb = new Workbook(),
              // @ts-ignore
              ws = sheet_from_array_of_arrays(data);
            wb.SheetNames.push(ws_name);
            wb.Sheets[ws_name] = ws;
            var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
            saveAs(
              new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
              $scope.translationType + '_' + moment().format('YYYY_MM_DD') + '.xlsx',
            );
          },
          function(err) {
            if (err.data) $window.alert(err.data);
          },
        );
      };

      $scope.updateSearchUrl = function() {
        $scope.searchUrl =
          deploymentConfig.api.origin +
          '/translation/secure/' +
          $scope.translationType +
          '/' +
          $scope.pagination.currentPage +
          '/' +
          $scope.pagination.itemsPerPage +
          '?';
        if ($scope.showConflictsOnly) $scope.searchUrl += '&showConflictsOnly=true';
        if ($scope.filterValue) $scope.searchUrl += '&filter=' + $scope.filterValue;
      };

      $scope.getItemsForCurrentPage = function() {
        $scope.updateSearchUrl();
        $http.get($scope.searchUrl).then(
          function(response) {
            $scope.pagination.items = response.data.items;
            for (var i = 0; i < $scope.pagination.items.length; i++) {
              $scope.pagination.items[i].expanded = false;
            }
            $scope.pagination.itemCount = response.data.totalItemCount;
            $scope.loading = false;
          },
          function(err) {
            if (err.data) $window.alert(err.data);
            $scope.loading = false;
          },
        );
      };

      $scope.onPageChange = function(newPageNumber) {
        $scope.pagination.currentPage = newPageNumber;
        $scope.getItemsForCurrentPage();
      };

      $scope.formatDate = function(date) {
        return moment(date).format('LLL');
      };
      $scope.deletePhrase = function(item) {
        if (confirm('Are you sure you want to delete the phrase "' + item.en + '"?')) {
          $http.delete(deploymentConfig.api.origin + '/translation/secure/' + item.id).then(
            function(response) {
              $scope.getItemsForCurrentPage();
            },
            function(err) {
              if (err) alert('Error: ' + err.data);
            },
          );
        }
      };
      $scope.toggleAppearance = function(item) {
        $http
          .post(
            deploymentConfig.api.origin + '/translation/secure/' + item.id + '/appearance/toggle',
          )
          .then(
            function(response) {
              $scope.getItemsForCurrentPage();
            },
            function(err) {
              if (err) {
                alert('Error: ' + err.data);
              }
            },
          );
      };

      $scope.updateTranslationReference = function(item) {
        $http
          .post(
            deploymentConfig.api.origin + '/translation/secure/' + item.id + '/update-reference',
            {
              en_ref: item.en_ref,
            },
          )
          .then(
            function(response) {
              $scope.getItemsForCurrentPage();
            },
            function(err) {
              if (err) {
                alert('Error: ' + err.data);
              }
            },
          );
      };

      $scope.uploadCawstUIFile = function(event) {
        $scope.currentType = 'cawst_ui';
        $scope.uploadFile(event);
      };

      $scope.uploadHwtsUIFile = function(event) {
        $scope.currentType = 'hwts_ui';
        $scope.uploadFile(event);
      };

      $scope.uploadWashUIFile = function(event) {
        $scope.currentType = 'wash_ui';
        $scope.uploadFile(event);
      };

      $scope.uploadFile = function(event) {
        var files = event.target.files;
        if (files.length === 0) return;
        $scope.phrases = [];
        $scope.files = [];
        $scope.uploadTarget = event.target;
        for (var i = 0; i < files.length; i++) $scope.files.push(files[i]);
        $scope.consolidateMultipleFiles = false;
        if ($scope.files.length > 1) {
          $scope.consolidateMultipleFiles = true;
        }

        $scope.readRemainingFile();
      };
      $scope.readRemainingFile = function() {
        if ($scope.files.length === 0) {
          $scope.uploadTarget.value = null;
          $scope.sendPhrases();
          return;
        }
        var file = $scope.files.splice(0, 1)[0];
        var reader = new FileReader();
        var name = file.name;
        reader.onload = function(e) {
          var data = e.target.result;

          var arr = fixdata(data);
          var wb = XLSX.read(btoa(arr), { type: 'base64' });
          var jsonData = to_json(wb);
          for (var sheetName in jsonData) {
            for (var rowIndex in jsonData[sheetName]) {
              var row = jsonData[sheetName][rowIndex];
              $scope.phrases.push(row);
            }
          }
          $scope.readRemainingFile();
        };
        reader.readAsArrayBuffer(file);
      };
      $scope.sendPhrases = function() {
        if ($scope.consolidateMultipleFiles) {
          if ($scope.phrases.length % 3) {
            alert('Error: The number of rows in the files do not match.');
            return;
          }
          var subLength = $scope.phrases.length / 3;
          var phraseListA = $scope.phrases.splice(0, subLength);
          var phraseListB = $scope.phrases.splice(0, subLength);
          var phraseListC = $scope.phrases.splice(0, subLength);
          for (var i = 0; i < phraseListA.length; i++) {
            for (var key in phraseListB[i]) {
              phraseListA[i][key] = phraseListB[i][key];
            }
            for (var keyC in phraseListC[i]) {
              phraseListA[i][keyC] = phraseListC[i][keyC];
            }
          }
          $scope.phrases = phraseListA;
        }
        $http
          .post(deploymentConfig.api.origin + '/translation/secure/' + $scope.currentType, {
            phrases: $scope.phrases,
          })
          .then(
            function(response) {
              $scope.getItemsForCurrentPage();
            },
            function(err) {
              if (err) alert('Error: ' + err.data);
            },
          );
      };

      function fixdata(data) {
        var o = '',
          l = 0,
          w = 10240;
        for (; l < data.byteLength / w; ++l) {
          o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
        }
        o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
        return o;
      }

      function to_json(workbook) {
        var result = {};
        workbook.SheetNames.forEach(function(sheetName) {
          // @ts-ignore
          var roa = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          if (roa.length > 0) {
            result[sheetName] = roa;
          }
        });
        return result;
      }
      function datenum(v, date1904) {
        if (date1904) v += 1462;
        var epoch = Date.parse(v);
        // @ts-ignore
        return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
      }

      function sheet_from_array_of_arrays(data, opts) {
        var ws = {};
        var range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
        for (var R = 0; R !== data.length; ++R) {
          for (var C = 0; C !== data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            var cell: any = { v: data[R][C] };
            if (cell.v == null) {
              continue;
            }
            var cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
              cell.t = 'n';
              // @ts-ignore
              cell.z = XLSX.SSF._table[14];
              // @ts-ignore
              cell.v = datenum(cell.v);
            } else cell.t = 's';

            ws[cell_ref] = cell;
          }
        }
        if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
        return ws;
      }

      function Workbook() {
        if (!(this instanceof Workbook)) {
          // @ts-ignore
          return new Workbook();
        }
        this.SheetNames = [];
        this.Sheets = {};
      }

      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }

      $scope.getItemsForCurrentPage();
    },
  ]);

/*! FileSaver.js
 *  A saveAs() FileSaver implementation.
 *  2014-01-24
 *
 *  By Eli Grey, http://eligrey.com
 *  License: X11/MIT
 *    See LICENSE.md
 */

/* global self */
/* jslint bitwise: true, indent: 4, laxbreak: true, laxcomma: true, smarttabs: true, plusplus: true */

/*! @source http://purl.eligrey.com/github/FileSaver.js/blob/master/FileSaver.js */

var saveAs =
  saveAs ||
  // IE 10+ (native saveAs)
  (typeof navigator !== 'undefined' &&
    navigator.msSaveOrOpenBlob &&
    navigator.msSaveOrOpenBlob.bind(navigator)) ||
  // Everyone else
  (function(view) {
    'use strict';

    // IE <10 is explicitly unsupported
    if (typeof navigator !== 'undefined' && /MSIE [1-9]\./.test(navigator.userAgent)) {
      return;
    }
    var doc = view.document,
      // only get URL when necessary in case BlobBuilder.js hasn't overridden it yet
      get_URL = function() {
        return view.URL || view.webkitURL || view;
      },
      URL = view.URL || view.webkitURL || view,
      save_link = doc.createElementNS('http://www.w3.org/1999/xhtml', 'a'),
      can_use_save_link = !view.externalHost && 'download' in save_link,
      click = function(node) {
        var event = doc.createEvent('MouseEvents');
        event.initMouseEvent(
          'click',
          true,
          false,
          view,
          0,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null,
        );
        node.dispatchEvent(event);
      },
      webkit_req_fs = view.webkitRequestFileSystem,
      req_fs = view.requestFileSystem || webkit_req_fs || view.mozRequestFileSystem,
      throw_outside = function(ex) {
        (view.setImmediate || view.setTimeout)(function() {
          throw ex;
        }, 0);
      },
      force_saveable_type = 'application/octet-stream',
      fs_min_size = 0,
      deletion_queue = [],
      process_deletion_queue = function() {
        var i = deletion_queue.length;
        while (i--) {
          var file = deletion_queue[i];
          if (typeof file === 'string') {
            // file is an object URL
            URL.revokeObjectURL(file);
          } else {
            // file is a File
            file.remove();
          }
        }
        deletion_queue.length = 0; // clear queue
      },
      dispatch = function(filesaver, event_types, event) {
        event_types = [].concat(event_types);
        var i = event_types.length;
        while (i--) {
          var listener = filesaver['on' + event_types[i]];
          if (typeof listener === 'function') {
            try {
              listener.call(filesaver, event || filesaver);
            } catch (ex) {
              throw_outside(ex);
            }
          }
        }
      },
      FileSaver = function(blob, name) {
        // First try a.download, then web filesystem, then object URLs
        var filesaver = this,
          type = blob.type,
          blob_changed = false,
          object_url,
          target_view,
          get_object_url = function() {
            var objectUrl = get_URL().createObjectURL(blob);
            deletion_queue.push(objectUrl);
            return objectUrl;
          },
          dispatch_all = function() {
            // @ts-ignore
            dispatch(filesaver, 'writestart progress write writeend'.split(' '));
          },
          // on any filesys errors revert to saving with object URLs
          fs_error = function() {
            // don't create more object URLs than needed
            if (blob_changed || !object_url) {
              // @ts-ignore
              object_url = get_object_url(blob);
            }
            if (target_view) {
              target_view.location.href = object_url;
            } else {
              if (
                navigator.userAgent.match(/7\.[\d\s\.]+Safari/) && // is Safari 7.x
                typeof window.FileReader !== 'undefined' && // can convert to base64
                blob.size <= 1024 * 1024 * 300 // file size max 300MB
              ) {
                var reader = new window.FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function() {
                  var frame = doc.createElement('iframe');
                  frame.src = reader.result;
                  frame.style.display = 'none';
                  doc.body.appendChild(frame);
                  dispatch_all();
                  return;
                };
                filesaver.readyState = filesaver.DONE;
                filesaver.savedAs = filesaver.SAVEDASUNKNOWN;
                return;
              }
              window.open(object_url, '_blank');
              filesaver.readyState = filesaver.DONE;
              filesaver.savedAs = filesaver.SAVEDASBLOB;
              dispatch_all();
              return;
            }
          },
          abortable = function(func) {
            return function() {
              if (filesaver.readyState !== filesaver.DONE) {
                return func.apply(this, arguments);
              }
            };
          },
          create_if_not_found = { create: true, exclusive: false },
          slice;
        filesaver.readyState = filesaver.INIT;
        if (!name) {
          name = 'download';
        }
        if (can_use_save_link) {
          // @ts-ignore
          object_url = get_object_url(blob);
          // FF for Android has a nasty garbage collection mechanism
          // that turns all objects that are not pure javascript into 'deadObject'
          // this means `doc` and `save_link` are unusable and need to be recreated
          // `view` is usable though:
          doc = view.document;
          save_link = doc.createElementNS('http://www.w3.org/1999/xhtml', 'a');
          save_link.href = object_url;
          save_link.download = name;
          var event = doc.createEvent('MouseEvents');
          event.initMouseEvent(
            'click',
            true,
            false,
            view,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null,
          );
          save_link.dispatchEvent(event);
          filesaver.readyState = filesaver.DONE;
          filesaver.savedAs = filesaver.SAVEDASBLOB;
          dispatch_all();
          return;
        }
        // Object and web filesystem URLs have a problem saving in Google Chrome when
        // viewed in a tab, so I force save with application/octet-stream
        // http://code.google.com/p/chromium/issues/detail?id=91158
        if (view.chrome && type && type !== force_saveable_type) {
          slice = blob.slice || blob.webkitSlice;
          blob = slice.call(blob, 0, blob.size, force_saveable_type);
          blob_changed = true;
        }
        // Since I can't be sure that the guessed media type will trigger a download
        // in WebKit, I append .download to the filename.
        // https://bugs.webkit.org/show_bug.cgi?id=65440
        if (webkit_req_fs && name !== 'download') {
          name += '.download';
        }
        if (type === force_saveable_type || webkit_req_fs) {
          target_view = view;
        }
        if (!req_fs) {
          fs_error();
          return;
        }
        fs_min_size += blob.size;
        req_fs(
          view.TEMPORARY,
          fs_min_size,
          abortable(function(fs) {
            fs.root.getDirectory(
              'saved',
              create_if_not_found,
              abortable(function(dir) {
                var save = function() {
                  dir.getFile(
                    name,
                    create_if_not_found,
                    abortable(function(file) {
                      file.createWriter(
                        abortable(function(writer) {
                          writer.onwriteend = function(event) {
                            target_view.location.href = file.toURL();
                            deletion_queue.push(file);
                            filesaver.readyState = filesaver.DONE;
                            filesaver.savedAs = filesaver.SAVEDASBLOB;
                            dispatch(filesaver, 'writeend', event);
                          };
                          writer.onerror = function() {
                            var error = writer.error;
                            if (error.code !== error.ABORT_ERR) {
                              fs_error();
                            }
                          };
                          'writestart progress write abort'.split(' ').forEach(function(event) {
                            writer['on' + event] = filesaver['on' + event];
                          });
                          writer.write(blob);
                          filesaver.abort = function() {
                            writer.abort();
                            filesaver.readyState = filesaver.DONE;
                            filesaver.savedAs = filesaver.FAILED;
                          };
                          filesaver.readyState = filesaver.WRITING;
                        }),
                        fs_error,
                      );
                    }),
                    fs_error,
                  );
                };
                dir.getFile(
                  name,
                  { create: false },
                  abortable(function(file) {
                    // delete file if it already exists
                    file.remove();
                    save();
                  }),
                  abortable(function(ex) {
                    if (ex.code === ex.NOT_FOUND_ERR) {
                      save();
                    } else {
                      fs_error();
                    }
                  }),
                );
              }),
              fs_error,
            );
          }),
          fs_error,
        );
      },
      FS_proto = FileSaver.prototype,
      saveAs = function(blob, name) {
        return new FileSaver(blob, name);
      };
    FS_proto.abort = function() {
      var filesaver = this;
      filesaver.readyState = filesaver.DONE;
      filesaver.savedAs = filesaver.FAILED;
      // @ts-ignore
      dispatch(filesaver, 'abort');
    };
    FS_proto.readyState = FS_proto.INIT = 0;
    FS_proto.WRITING = 1;
    FS_proto.DONE = 2;
    FS_proto.FAILED = -1;
    FS_proto.SAVEDASBLOB = 1;
    FS_proto.SAVEDASURI = 2;
    FS_proto.SAVEDASUNKNOWN = 3;

    FS_proto.error = FS_proto.onwritestart = FS_proto.onprogress = FS_proto.onwrite = FS_proto.onabort = FS_proto.onerror = FS_proto.onwriteend = null;

    view.addEventListener('unload', process_deletion_queue, false);
    // @ts-ignore
    saveAs.unload = function() {
      process_deletion_queue();
      view.removeEventListener('unload', process_deletion_queue, false);
    };
    return saveAs;
  })(
    (typeof self !== 'undefined' && self) ||
      (typeof window !== 'undefined' && window) ||
      this.content,
  );
// `self` is undefined in Firefox for Android content script context
// while `this` is nsIContentFrameMessageManager
// with an attribute `content` that corresponds to the window

/*

// @ts-ignore
if (typeof module !== 'undefined' && module !== null) {
  module.exports = saveAs;
  // @ts-ignore
} else if (typeof define !== 'undefined' && define !== null && define.amd != null) {
  // @ts-ignore
  define([], function() {
    return saveAs;
  });
  // @ts-ignore
} else if (typeof Meteor !== 'undefined') {
  // make it available for Meteor
  // @ts-ignore
  Meteor.saveAs = saveAs;
}

 */
