/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';

angular
  .module('app.controllers') //
  .controller('HwtsEfficiencyDialogCtrl', [
    '$http',
    '$scope',
    '$uibModalInstance',
    'entry',
    function($http, $scope, $uibModalInstance, entry) {
      $scope.entry = entry;
      $scope.itemCtrlScope = angular.element(document.getElementById('itemForm')).scope();

      $scope.testingTypes = [
        { key: 'Independent Testing', caption: 'Independent' },
        { key: 'Manufacturer Testing', caption: 'Manufacturer' },
        { key: 'Theoretical Testing', caption: 'Theoretical' },
      ];

      $scope.ok = function() {
        $uibModalInstance.close($scope.entry);
      };

      $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.searchItems = function(type, value) {
        return $scope.itemCtrlScope.searchItems(type, value);
      };

      // $scope.getDocument = function(value) {
      //   return $http.get('/api/manager/document/search/'+value)
      //   .then(function(response){
      //     return response.data;
      //   });
      // };
      //
      // $scope.onSelect = function($item, $model, $label, $event) {
      //   $scope.document=$item;
      //   $scope.referenceId=$item.id;
      //   $scope.updateDocumentText();
      // };
      //
      // $scope.updateDocumentText = function() {
      //   if($scope.document)
      //     $scope.documentText=JSON.stringify($scope.document,null,"   ");
      //   else
      //     $scope.documentText="";
      // }
    },
  ]);
