/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';

angular
  .module('app.services') //
  .factory('GlobalMessageService', [
    '$rootScope',
    function($rootScope) {
      // @NOTE: Type can be:
      // success
      // info
      // processing
      // error
      // warning

      var notificationMessageFeed = [];

      return {
        // /////////////////////////////////////////////////////////////////////
        getAllMessages: notificationMessageFeed,
        // /////////////////////////////////////////////////////////////////////
        getMessage(index) {
          return notificationMessageFeed[index];
        },
        // /////////////////////////////////////////////////////////////////////
        clearMessage(index, cb) {
          notificationMessageFeed.splice(index, 1);

          if (cb) {
            return cb(notificationMessageFeed);
          }
        },
        // /////////////////////////////////////////////////////////////////////
        addMessage(message, type, dismissable, cb) {
          notificationMessageFeed.push({
            message: message,
            type: type,
            dismissable: dismissable,
          });
          // return index of message
          return cb(notificationMessageFeed.length - 1);
        },
        // /////////////////////////////////////////////////////////////////////
      };
    },
  ]);
