import { cloneDeep, omit } from 'lodash';

export const cleanCampaignData = (
  rawData: { [key: string]: any },
  blacklistedKeys: string[],
): object[] => {

  return Object.values(rawData).map(value => omit(value, blacklistedKeys));

};

// Assumes that our raw data objects have consistent key entries
export const convertDataObjectToCsvString = (data: any[]): string => {
  const dataTypeHeader: string = 'data:text/csv;charset=utf-8,';
  const headingKeys: string[] = Object.keys(data[0]);

  let result: string = '';
  result += dataTypeHeader;
  result += generateHeadingRow(headingKeys);
  data.forEach((datum) => {
    result += generateDataRow(datum, headingKeys);
  });

  return result;
};

export const generateHeadingRow = (keys: string[]): string => {
  return `${keys.join(',')}\n`;
};

export const generateDataRow = (dataObject: any, keyList: string[]): string => {
  let result = '';

  keyList.forEach((key) => {
    const fieldData = sanitizeDataField(dataObject[key]);
    result += `${fieldData},`;
  });

  // Trim the last character (should be a comma), and append a newline
  result = `${result.substr(0, result.length - 1)}\n`;
  return result;
};

const sanitizeDataField = (field: any): any => {
  let result = field;

  if (isEmptyEntryOrEmptyString(result)) {
    result = 'NULL';
  } else if (typeof result === 'string') {
    result = sanitizeStringData(result);
  }

  return result;
};

const isEmptyEntryOrEmptyString = (value: any): boolean => {
  return !value && (typeof value !== 'number' && typeof value !== 'boolean');
};

const sanitizeStringData = (value: string): string => {
  let result = value;

  result = result.replace(/"/g, "'");
  result = result.replace(/\n\r/g, ' ');
  result = result.replace(/\n/g, ' ');
  result = `"${result}"`; // Neutralizes string-data commas in .csv

  return result;
};
