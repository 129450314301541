/* tslint:disable:no-var-requires */

import { ConfigRule } from '@submodules/cawst-core/config/ConfigMerger';
const { deploymentTargets, projects } = require('../projects');
const { organizationDetails } = require('../organizationDetails');

module.exports = (projectKey) => {
  // Deployment targets: prod, dev, local
  const universalRule: ConfigRule = [
    'universal',
    {
      auth0: {
        audience: 'https://cawst.auth0.com/userinfo',
        domain: 'cawst.auth0.com',
      },
      featureFlags: {
        purgeCSS: false, // TODO: Move to cawst-angularjs #CAWST-27
        useUniversalLogin: true,
      },
      google: {
        maps: {
          api: {
            key: 'AIzaSyDAJsfakICYrn4P6UHGwEtbfQYbbkyhd5U',
          },
        },
      },
      port: projects[projectKey].port,
      organizationDetails,
      prerender: {
        token: 'sEUXNvWPFHOpVgXsSmEW',
      },
    },
  ];

  // Deployment target: prod
  const prodOrigin = projects['vwet-api'].deploymentTargetOrigins.prod;
  const prodRule: ConfigRule = [
    'prod',
    {
      api: {
        appUrl: prodOrigin + projects[projectKey].apiBaseRoute,
        origin: prodOrigin,
      },
      auth0: {
        clientId: 'wwWNxqPrq17QHvtkM0LUZASNHdAAyb3t',
      },
      intercom: {
        appId: 'o5z64gn0',
        apiKey: '855b4d9fec978f8af72479cdf3ef7d2b14845391',
        token: 'dG9rOjJiMDNmOTcxX2JhOTNfNDg2OV84OGEyXzk0NGNjMjRmMjlhNToxOjA=',
      },
      resources: {
        assetBaseUrl: projects[projectKey].assetBaseUrls.prod,
        imageBaseUrl: projects[projectKey].assetBaseUrls.prod,
      },
    },
  ];

  // Deployment targets: dev, local
  const nonprodRule: ConfigRule = [
    ['dev', 'local'],
    {
      auth0: {
        clientId: '1UefSTmG0oyL13YrwpEW4cVaZPzVlxJs',
      },
      intercom: {
        appId: 'qvqnxx0t',
        apiKey: '0db6dc1fa2707f8016dbf21cd11821248a686f2a',
        token: 'dG9rOjA2MTRiYjRlXzI2ZDhfNGY5M185MTRmX2FlNDgwYjk1MjI5ZToxOjA=',
      },
      resources: {
        assetBaseUrl: projects[projectKey].assetBaseUrls.dev,
        imageBaseUrl: projects[projectKey].assetBaseUrls.dev,
      },
    },
  ];

  // ----------
  // Deployment target: dev
  const devOrigin = projects['vwet-api'].deploymentTargetOrigins.dev;
  const devRule: ConfigRule = [
    'dev',
    {
      api: {
        appUrl: devOrigin + projects[projectKey].apiBaseRoute,
        origin: devOrigin,
      },
    },
  ];

  // ----------
  // Deployment target: local
  const localOrigin = 'http://localhost:' + projects['vwet-api'].port;
  const localRule: ConfigRule = [
    'local',
    {
      api: {
        appUrl: localOrigin + projects[projectKey].apiBaseRoute,
        origin: localOrigin,
      },
    },
  ];

  // ----------
  // Option: test
  // Use a different port when testing, to avoid conflicts if the project is running on its assigned port
  const testRule: ConfigRule = [
    'test',
    {
      port: '3049',
    },
  ];

  // ----------
  // Add automatically computed properties

  // Deployment target origins
  const originRules = [];
  for (const deploymentTarget of deploymentTargets) {
    originRules.push([
      deploymentTarget,
      {
        origin: projects[projectKey].deploymentTargetOrigins[deploymentTarget],
        urls: {
          userAccount:
            projects['wash-resources-site'].deploymentTargetOrigins[deploymentTarget] + '/myaccount',
        },
      },
    ]);
  }

  return [universalRule, prodRule, nonprodRule, devRule, localRule, testRule, ...originRules];
};
