/* tslint:disable:only-arrow-functions */
/* tslint:disable:ter-prefer-arrow-callback */

import * as angular from 'angular';

angular
  .module('app') //
  .directive('stats', function() {
    return {
      templateUrl: 'scripts/directives/dashboard/stats/stats.html',
      restrict: 'E',
      replace: true,
      scope: {
        model: '=',
        comments: '@',
        number: '@',
        name: '@',
        colour: '@',
        details: '@',
        type: '@',
        goto: '@',
      },
    };
  });
