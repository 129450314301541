/* tslint:disable:no-console */
/* tslint:disable:no-empty */
/* tslint:disable:only-arrow-functions */
/* tslint:disable:variable-name */

import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular.module('app').controller('AuthCtrl', [
  '$scope',
  '$state',
  'authService',
  'User',
  // Don't use an arrow function here (or in any Angular constructor call)
  // tslint:disable-next-line:only-arrow-functions
  function($scope, $state, authService, User) {
    // ----------
    /* TODO: Restore password-reset functionality. #WASH-15
      $scope.changePassword = () => {
        // this method triggers a password reset email to be sent via Auth0
        const data = {
          connection: 'Username-Password-Authentication',
        };
        // This function was provided by the legacy auth0-angular package. It's not clear that
        // there is an equivalent function in auth0-angular.
        auth.reset(data, (_err, _message) => {});
      };
      */
    // ----------
    $scope.getAccountLink = () => {
      return deploymentConfig.urls.userAccount + `?access_token=${authService.retrieveIdToken()}`;
    };

    // ----------
    $scope.isSignedIn = User.isSignedIn;
    // ----------
    // Sign-in and sign-out are through User.signIn(), not authService.signin();
    $scope.signIn = User.signIn;

    // ----------
    // Sign-in and sign-out are through User.signIn(), not authService.signin();
    $scope.signOut = () => {
      User.signOut();
      $state.reload();
    };

    // ----------
    $scope.permissions = User.permissions;
    // console.log('auth.controller.ts | $scope.permissions:', $scope.permissions);

    // checks if a user has permissions to see closed Access research documents or edit content on  (only employees have this)
    $scope.userHasStaffPermissions = () => {
      return $scope.permissions.AccessClosedResearch || $scope.permissions.editContent;
    };
    // ----------
  },
]);
