/* tslint:disable:no-console */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { find, result } from 'lodash';
import * as moment from 'moment';

angular.module('app.controllers').controller('EditTrainingWorkshopsCtrl', [
  '$scope',
  '$http',
  '$state',
  'SweetAlertUtil',
  'TrainingEvents',
  function($scope, $http, $state, SweetAlertUtil, TrainingEvents) {
    $scope.trainingEventForm = {
      addNewMode: true,
      loadingEvent: true,
      startDate: new Date(),
      endDate: new Date(),
      venueNoResults: false,
      addVenueForm: false,
      newVenue: {},
      organizerNoResults: false,
      addOrganizerForm: false,
      newOrganizer: {},
      hostNoResults: false,
      addHostForm: false,
      newHost: {},
    };

    // # Default object for the add new training form
    $scope.training = {
      title: '',
      visibility: 'published',
      language: {
        title: 'English',
        ietf: 'en',
        iso_639_1: 'en',
        iso_639_2: 'eng',
        iso_639_3: '',
      },
    };

    if ($state.params.id) {
      $scope.trainingEventForm.addNewMode = false;
      $scope.trainingEventForm.loadingEvent = true;
      initializeTraining($state.params.id);
    }

    // Initialize templates
    TrainingEvents.getEventOrganizers((data) => {
      $scope.trainingOrganizers = data;

      TrainingEvents.getEventVenues((data) => {
        $scope.trainingVenues = data;

        TrainingEvents.getEventHosts((data) => {
          $scope.trainingHosts = data;

          TrainingEvents.getLanguages((data) => {
            $scope.languages = data;

            TrainingEvents.getTrainingTemplates((data) => {
              data.other = {
                id: 'other',
                label: 'Other',
                title: '',
                topic_main: 'other',
                topics: [],
                newTemplate: true,
              };
              $scope.trainingTemplates = data;

              $scope.trainingEventForm.loadingEvent = false;
            });
          });
        });
      });
    });

    $scope.returnToTrainings = function() {
      $state.transitionTo('training', null, { reload: true });
    };

    $scope.trainingEventForm.summeroptions = {
      focus: true,
      airMode: false,
      toolbar: [
        [
          'style',
          ['bold', 'italic', 'underline', 'superscript', 'subscript', 'strikethrough', 'clear'],
        ],
        ['textsize', ['fontsize']],
        ['alignment', ['ul', 'ol', 'paragraph', 'lineheight']],
        ['height', ['height']],
        ['edit', ['undo', 'redo']],
      ],
    };

    $scope.trainingEventForm.updateNiceEventDate = function(training) {
      convertDatesToString(training);
      $scope.trainingEventForm.niceEventDate = TrainingEvents.niceEventDate(training);
    };

    $scope.saveTraining = function(training) {
      convertDatesToString(training);
      training.length_days = moment(training.date_end).diff(moment(training.date_start), 'days');
      if (!$scope.trainingEventForm.addNewMode) {
        TrainingEvents.updateEvent(training, (response) => {
          if (response) {
            SweetAlertUtil.success('Training workshop saved.');
          } else {
            SweetAlertUtil.error(
              'Something went wrong updating the training, please contact virtual services.',
            );
          }
        });
      } else {
        TrainingEvents.addEvent(training, (response) => {
          if (response) {
            SweetAlertUtil.success('New Training workshop added.');
          } else {
            SweetAlertUtil.error(
              'Something went wrong creating the event, please contact virtual services.',
            );
          }
        });
      }
      // $state.go('training');
    };

    // # Create New Organizer
    $scope.saveNewOrganizer = function(organizer) {
      organizer.label = organizer.organization + ' - ' + organizer.name;
      TrainingEvents.addEventOrganizer(organizer, (response) => {
        if (response) {
          $scope.updateNewOrganizerSelection(organizer, $scope.training.organizer, organizer.label);
          $scope.trainingEventForm.newOrganizer = {};
          $scope.trainingEventForm.organizerNoResults = false;
          $scope.trainingEventForm.addOrganizerForm = false;
          SweetAlertUtil.success('New organizer saved.');
        } else {
          SweetAlertUtil.error(
            'Something went wrong adding the organizer, please contact virtual services.',
          );
        }
      });
    };

    $scope.updateNewOrganizerSelection = function(item, _model, _label) {
      $scope.training.organizer = item;
    };

    $scope.updateLanguage = function(item, _model, _label) {
      $scope.training.language = {
        title: item.title,
        ietf: item.ietf ? item.ietf : '',
        iso_639_1: item.iso_639_1 ? item.iso_639_1 : '',
        iso_639_2: item.iso_639_2 ? item.iso_639_2 : '',
        iso_639_3: item.iso_639_3 ? item.iso_639_3 : '',
      };
    };

    // # Create new host
    $scope.saveNewHost = function(host) {
      host.label = host.organization + ' - ' + host.name;
      TrainingEvents.addEventHost(host, (response) => {
        if (response) {
          $scope.updateNewHostSelection(host, $scope.training.host, host.label);
          $scope.trainingEventForm.newHost = {};
          $scope.trainingEventForm.hostNoResults = false;
          $scope.trainingEventForm.addHostForm = false;
          SweetAlertUtil.success('New host saved');
        } else {
          SweetAlertUtil.error(
            'Something went wrong adding the host, please contact virtual services.',
          );
        }
      });
    };

    $scope.updateNewHostSelection = function(item, _model, _label) {
      $scope.training.host = item;
    };

    // # Create New Venue
    $scope.updateNewVenueAddressSelection = function(item, _model, label) {
      // extract country
      $scope.trainingEventForm.newVenue.country = result(
        find(item.address_components, (comp) => {
          return comp.types[0] === 'country';
        }),
        'long_name',
      );
      $scope.trainingEventForm.newVenue.city = result(
        find(item.address_components, (comp) => {
          return comp.types[0] === 'locality';
        }),
        'long_name',
      );
      $scope.trainingEventForm.newVenue.state = result(
        find(item.address_components, (comp) => {
          return comp.types[0] === 'administrative_area_level_1';
        }),
        'long_name',
      );
      if ($scope.trainingEventForm.newVenue.state) {
        $scope.trainingEventForm.newVenue.hasState = true;
      }

      $scope.trainingEventForm.newVenue.label = label;
      $scope.trainingEventForm.newVenue.latitude = item.geometry.location.lat;
      $scope.trainingEventForm.newVenue.longitude = item.geometry.location.lng;
    };

    $scope.trainingEventForm.saveNewVenue = function(venue) {
      TrainingEvents.addEventVenue(venue, (response) => {
        if (response) {
          $scope.updateNewVenueSelection(venue, $scope.training.venue, venue.label);
          $scope.trainingEventForm.newVenue = {};
          $scope.trainingEventForm.venueNoResults = false;
          $scope.trainingEventForm.addVenueForm = false;
          SweetAlertUtil.success('New venue/location saved.');
        } else {
          SweetAlertUtil.error(
            'Something went wrong adding the venue, please contact virtual services.',
          );
        }
      });
    };

    $scope.trainingEventForm.cancelAddNewForm = function(form) {
      if (form === 'newVenue') {
        $scope.trainingEventForm.newVenue = {};
        $scope.trainingEventForm.addVenueForm = false;
      } else if (form === 'newHost') {
        $scope.trainingEventForm.newHost = {};
        $scope.trainingEventForm.addHostForm = false;
      } else if (form === 'newOrganizer') {
        $scope.trainingEventForm.newOrganizer = {};
        $scope.trainingEventForm.addOrganizerForm = false;
      }
    };

    $scope.updateNewVenueSelection = function(item, _model, _label) {
      $scope.training.venue = item;
    };

    $scope.updateTemplateChoice = function() {
      const tpl = $scope.trainingTemplates[$scope.trainingEventForm.selectedTemplate];

      $scope.training.title = tpl.title;
      $scope.training.description_short = tpl.short_desc;
      $scope.training.description_long_html = tpl.long_desc_html;
      $scope.training.topic_main = tpl.topic_main;
      $scope.training.topics = tpl.topics;
    };

    // # update the original template using the edits from this event
    $scope.updateTemplateMaster = function() {
      const updatedTpl = {
        title: $scope.training.title,
        short_desc: $scope.training.description_short,
        long_desc_html: $scope.training.description_long_html,
      };
      $scope.updatingTemplate = true;
      if ($scope.trainingEventForm.selectedTemplate) {
        TrainingEvents.updateTemplateMaster(updatedTpl, (response) => {
          if (response) {
            SweetAlertUtil.success('Template saved.');
          } else {
            SweetAlertUtil.error(
              'Something went wrong saving the template, please contact virtual services.',
            );
          }
          $scope.updatingTemplate = false;
        });
      }
    };

    $scope.open = function(cal, $event) {
      $scope.status[cal].opened = true;
    };

    $scope.status = {
      start: {
        opened: false,
      },
      end: {
        opened: false,
      },
    };

    $scope.dateOptions = {
      formatYear: 'yy',
      startingDay: 1,
    };

    $scope.updateTime = function(cal) {
      const d = new Date(cal);
      d.setHours(9);
      d.setMinutes(0);
    };

    // # --- Get location as they type ---
    $scope.getLocation = function(val) {
      return $http
        .get('//maps.googleapis.com/maps/api/geocode/json', {
          params: {
            address: val,
            sensor: false,
          },
          skipAuthorization: true,
        })
        .then((response) => {
          return response.data.results.map((item) => {
            return item;
          });
        });
    };

    // Helper functions
    function initializeTraining(eventId) {
      TrainingEvents.getEvent(eventId, (eventObj) => {
        $scope.training = eventObj;
        // reformat dates for ui
        $scope.trainingEventForm.startDate = moment($scope.training.date_start).toDate();
        $scope.trainingEventForm.endDate = moment($scope.training.date_end).toDate();

        $scope.trainingEventForm.updateNiceEventDate($scope.training);

        $scope.trainingEventForm.loadingEvent = false;

        console.log($scope.training);
      });
    }

    function convertDatesToString(training) {
      training.date_start = moment($scope.trainingEventForm.startDate).format(
        'YYYY-MM-DD hh:mm:ss',
      );
      training.date_end = moment($scope.trainingEventForm.endDate).format('YYYY-MM-DD hh:mm:ss');
    }
  },
]);
