/* tslint:disable:only-arrow-functions */
/* tslint:disable:ter-prefer-arrow-callback */

import * as angular from 'angular';

angular
  .module('app')
  // /////////////////////////////////////////////////////////////////////////////
  // # Notifications directive
  .directive('notificationArea', [
    'GlobalMessageService',
    function(GlobalMessageService) {
      return {
        restrict: 'E',
        templateUrl: 'scripts/directives/wash/notification_area.html',
        scope: true, // setting true means rootscope vars are available.. if you want to isolate, set to {}
        link(scope: any, _element, _attr) { // TOTYPE
          scope.clearMessage = GlobalMessageService.clearMessage;
          scope.allMessages = GlobalMessageService.getAllMessages;
        },
      };
    },
  ])

  // /////////////////////////////////////////////////////////////////////////////
  // # Shows parents and children of resource being changed
  .directive('changeResourceAlert', function() {
    return {
      restrict: 'E',
      templateUrl: 'scripts/directives/wash/change_alert.html',
      scope: {
        resource: '=', // expect resource object to contain parents and children
        message: '=', // optional
        type: '=', // optional, accepts info or warning, default is warning
      },
    };
  })

  // /////////////////////////////////////////////////////////////////////////////
  // # For serving translation csv file
  .directive('customOnChange', function() {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        const onChangeHandler = scope.$eval(attrs.customOnChange);
        element.bind('change', onChangeHandler);
      },
    };
  });

// ///////////////////////////////////////////////////////////////////////////// // end directives
