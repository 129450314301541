import * as angular from 'angular';

angular
  .module('app') //
  // -- Remove hashbang
  .config([
    '$locationProvider',
    // tslint:disable-next-line:only-arrow-functions
    function($locationProvider) {
      // html5Mode for pretty URLs and hashPrefix for prerender
      $locationProvider.html5Mode(true).hashPrefix('!');
    },
  ])

  .config([
    '$provide',
    // tslint:disable-next-line:only-arrow-functions
    function($provide) {
      // Animate uiViewScroll (aka smooth scroll)
      // "- 90" to account for height of fixed navigation
      $provide.decorator('$uiViewScroll', () => {
        return (uiViewElement) => {
          angular.element('html,body').animate(
            {
              scrollTop: uiViewElement.offset().top - 90,
            },
            500,
          );
        };
      });
    },
  ]);
