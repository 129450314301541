/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { findIndex } from 'lodash';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.controllers') //
  .controller('WashAddChildrenCtrl', [
    '$scope',
    'WASH_Resource',
    'WASH_Utilities',
    'WASH_Data',
    '$state',
    '$http',
    'SweetAlertUtil',
    function($scope, WASH_Resource, WASH_Utilities, WASH_Data, $state, $http, SweetAlertUtil) {
      const defaultOrderBy = 'lastModified';

      $scope.washAddChildren = {};
      $scope.washAddChildren.headerText = 'Add sub resources';
      $scope.washAddChildren.loading = true;

      $scope.washAddChildren.newChildren = [];
      $scope.washAddChildren.newChildrenIds = [];

      $scope.washAddChildren.removeChildrenIds = [];

      $scope.washAddChildren.selectedFilters = {};

      $scope.washAddChildren.resourceTypes = WASH_Data.resourceTypes;
      $scope.washAddChildren.washDefinitions = WASH_Data.washDefinitions;

      // --- # Need resource title, type, and current children ---
      const resourceId = $state.params.resourceId;
      WASH_Resource.getResource(resourceId, (data) => {
        $scope.washAddChildren.resource = data;
        $scope.washAddChildren.loading = false;
      });

      // --- # Form submit ---
      $scope.washAddChildren.submitProcessing = false;
      $scope.washAddChildren.submitSuccess = false;
      $scope.washAddChildren.submitError = false;
      $scope.washAddChildren.errorMessage = 'Error';
      $scope.washAddChildren.processingMessage = 'Processing...';

      const StateSubmitProcessing = function(processingMessage) {
        $scope.washAddChildren.submitProcessing = true;
        $scope.washAddChildren.submitSuccess = false;
        $scope.washAddChildren.submitError = false;
        if (processingMessage) $scope.washAddChildren.processingMessage = processingMessage;
      };

      const stateSubmitSuccess = function() {
        $scope.washAddChildren.submitProcessing = false;
        $scope.washAddChildren.submitSuccess = true;
        $scope.washAddChildren.submitError = false;
      };

      const stateSubmitError = function(errorMessage) {
        $scope.washAddChildren.submitProcessing = false;
        $scope.washAddChildren.submitSuccess = false;
        $scope.washAddChildren.submitError = true;
        if (errorMessage) $scope.washAddChildren.errorMessage = errorMessage;
      };

      const StateSubmitClear = function() {
        $scope.washAddChildren.submitProcessing = false;
        $scope.washAddChildren.submitSuccess = false;
        $scope.washAddChildren.submitError = false;
      };

      $scope.washAddChildren.childExists = function(itemId) {
        if ($scope.washAddChildren.resource) {
          if ($scope.washAddChildren.newChildrenIds.indexOf(itemId) >= 0) {
            // if item is in the list of new children
            return true;
          }
          return findIndex($scope.washAddChildren.resource.children, { id: itemId }) >= 0;
        }
        return false;
      };

      $scope.washAddChildren.addNewChild = function(newChild) {
        if (newChild) {
          const itemToAdd = newChild;
          StateSubmitProcessing('Adding ' + itemToAdd.title + '...');

          if (!$scope.washAddChildren.childExists(itemToAdd.id)) {
            $scope.washAddChildren.newChildren.push(itemToAdd);
            $scope.washAddChildren.newChildrenIds.push(itemToAdd.id);
            StateSubmitClear();
          } else {
            stateSubmitError(itemToAdd.title + ' has already been added.');
          }
        } else {
          stateSubmitError('Error: Cannot add sub resource.');
        }
      };

      $scope.washAddChildren.removeNewChild = function(item) {
        const itemToRemove = item;

        if (itemToRemove) {
          StateSubmitProcessing('Removing ' + itemToRemove.title + '...');

          if ($scope.washAddChildren.childExists(itemToRemove.id)) {
            const idInObjArray = findIndex($scope.washAddChildren.newChildren, {
              id: itemToRemove.id,
            });
            const idInIdArray = $scope.washAddChildren.newChildrenIds.indexOf(itemToRemove.id);
            $scope.washAddChildren.newChildren.splice(idInObjArray, 1);
            $scope.washAddChildren.newChildrenIds.splice(idInIdArray, 1);
            StateSubmitClear();
          } else {
            stateSubmitError(itemToRemove.title + ' does not exist as a sub resource.');
          }
        } else {
          stateSubmitError('Error: Cannot remove ' + itemToRemove.title + '.');
        }
      };

      $scope.washAddChildren.removeExistingChild = function(item) {
        const itemToRemove = item;

        if (itemToRemove) {
          StateSubmitProcessing('Removing ' + itemToRemove.title + '...');

          if ($scope.washAddChildren.childExists(itemToRemove.id)) {
            const existingChildId = findIndex($scope.washAddChildren.resource.children, {
              id: itemToRemove.id,
            });
            $scope.washAddChildren.removeChildrenIds.push(itemToRemove.id, 1);
            $scope.washAddChildren.resource.children.splice(existingChildId, 1);
            StateSubmitClear();
          } else {
            stateSubmitError(itemToRemove.title + ' does not exist as a sub resource.');
          }
        } else {
          stateSubmitError('Error: Cannot remove ' + itemToRemove.title + '.');
        }
      };

      $scope.washAddChildren.removeChild = function(item) {
        // console.log('washAddChildren.removeChild');
        if (item) {
          const itemId = item.id;

          if (findIndex($scope.washAddChildren.resource.children, { id: itemId }) >= 0) {
            $scope.washAddChildren.removeExistingChild(item);
          } else if ($scope.washAddChildren.newChildrenIds.indexOf(itemId) >= 0) {
            $scope.washAddChildren.removeNewChild(item);
          }
        }
      };

      $scope.washAddChildren.cancel = function() {
        SweetAlertUtil.confirm('Are you sure you want to cancel? All changes will be lost.').then(
          (confirm) => {
            if (confirm) {
              $state.go('wash.resource', { resourceId });
            }
          },
          (p) => {
            /* fail */
          },
        );
      };

      $scope.washAddChildren.submit = function() {
        StateSubmitProcessing(
          'Adding sub resources to ' + $scope.washAddChildren.resource.title + '...',
        );

        WASH_Resource.addResourceChildren(
          resourceId,
          $scope.washAddChildren.newChildrenIds,
          (data) => {
            if (data) {
              stateSubmitSuccess();
              removeChildren();
            } else {
              stateSubmitError(
                'Error: Cannot add sub resources to ' + $scope.washAddChildren.resource.title,
              );
            }
          },
        );

        function removeChildren() {
          WASH_Resource.removeResourceChildren(
            resourceId,
            $scope.washAddChildren.removeChildrenIds,
            (data) => {
              if (data) {
                stateSubmitSuccess();
              } else {
                stateSubmitError(
                  'Error: Cannot remove sub resources from ' +
                    $scope.washAddChildren.resource.title,
                );
              }
            },
          );
        }
      };

      // --- # Search resources ---
      $scope.washAddChildren.searchTitle = '';
      $scope.washAddChildren.pagination = {
        currentPage: 1,
        itemCount: 0,
        itemsPerPage: 10,
        items: [],
      };

      $scope.washAddChildren.getItemsForCurrentPage = function() {
        $scope.washAddChildren.searchLoading = true;

        const pagination = $scope.washAddChildren.pagination;
        const selectedOrderBy = $scope.washAddChildren.selectedOrderBy;
        const filters = $scope.washAddChildren.selectedFilters;
        const searchTitle = $scope.washAddChildren.searchTitle;

        const url =
          deploymentConfig.api.baseUrls.wash +
          '/resource/paginate/' +
          pagination.currentPage +
          '/' +
          pagination.itemsPerPage;
        const orderBy = selectedOrderBy
          ? '?orderBy=' + selectedOrderBy
          : '?orderBy=' + defaultOrderBy;
        const title = searchTitle ? '&title=' + searchTitle : '';
        const status = filters.status ? '&publicationStatus=' + filters.status : '';
        const type = filters.type ? '&type=' + filters.type : '';
        const category = filters.category ? '&category=' + filters.category : '';
        const topic = filters.topic ? '&topic=' + filters.topic : '';
        const region = filters.region ? '&region=' + filters.region : '';
        const language = filters.language ? '&language=' + filters.language : '';
        const featured = filters.featured ? '&featured=' + filters.featured : '';

        $http
          .get(url + orderBy + status + title + type + category + topic + region + language + featured)
          .then(
            (response) => {
              $scope.washAddChildren.pagination.items = response.data.items;
              $scope.washAddChildren.pagination.itemCount = response.data.totalItemCount;
              $scope.washAddChildren.searchLoading = false;
            },
            (err) => {
              $scope.washAddChildren.searchLoading = false;
              if (err.data) SweetAlertUtil.alert('Error retrieving results: ' + err.data);
            },
          );
      };

      $scope.washAddChildren.onPageChange = function(newPageNumber) {
        $scope.washAddChildren.pagination.currentPage = newPageNumber;
        $scope.washAddChildren.getItemsForCurrentPage();
      };

      $scope.washAddChildren.clearFilters = function() {
        $scope.washAddChildren.selectedOrderBy = 'publicationDate';
        $scope.washAddChildren.selectedFilters = {};
        $scope.washAddChildren.getItemsForCurrentPage();
      };

      // --- # Run on load ---
      $scope.washAddChildren.disableForm = function() {
        return $scope.washAddChildren.formSubmitProcessing === true;
      };

      $scope.$on('$viewContentLoaded', () => {
        $scope.washAddChildren.selectedOrderBy = 'publicationDate';
        $scope.washAddChildren.getItemsForCurrentPage();
      });
    },
  ]);
