/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';
import { URL } from '@submodules/cawst-core/types';
import { createInclusionMap } from '@submodules/cawst-core/util/createInclusionMap';
import { Pagination } from '../types';

interface UserDetails {
  code: string;
  flag: string;
  id: string;
  name: string;
  roles: string[];
}

interface UserPagination extends Pagination {
  items: UserDetails[];
}

angular
  .module('app.controllers') //
  .controller('UserListController', [
    '$http',
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    function($http, $rootScope, $scope, $state, $window) {
      $scope.pagination = {
        currentPage: 1,
        itemCount: 0,
        itemsPerPage: 25,
        items: [],
      } as UserPagination;

      $scope.loading = true;
      $scope.headerText = 'Users';
      $scope.roles = [
        'HwtsVolunteer',
        'HwtsManager',
        'Staff',
        'TrainingManager',
        'WashManager',
        'WashStaff',
        'Translator',
        'Administrator',
      ] as string[];

      $scope.getItemsForCurrentPage = function() {
        const filterPostFix: string = $scope.filterValue ? '?filter=' + $scope.filterValue : '';
        const url: URL =
          deploymentConfig.api.origin +
          '/user/paginate/' +
          $scope.pagination.currentPage +
          '/' +
          $scope.pagination.itemsPerPage;
        $http.get(url + filterPostFix).then(
          (response) => {
            $scope.pagination.items = response.data.items;
            $scope.pagination.itemCount = response.data.totalItemCount;
            $scope.loading = false;

            // Attach a roles map, showing { role: true/false }, to each user
            $scope.pagination.items.forEach((item: UserDetails, i) => {
              $scope.pagination.items[i].rolesMap = createInclusionMap($scope.roles, (item.roles || []));
            });
          },
          (err) => {
            $window.alert(err.data);
            $scope.loading = false;
          },
        );
      };

      $scope.getItemsForCurrentPage();

      $scope.onPageChange = (newPageNumber) => {
        $scope.pagination.currentPage = newPageNumber;
        $scope.getItemsForCurrentPage();
      };

      $scope.getRoles = (roles) => {
        const currentRoles = [];
        Object.keys(roles).forEach((key) => {
            if (roles[key]) {
              currentRoles.push(key);
            }
          });
        return currentRoles;
      }

      $scope.hasRoles = (roles) => {
        return $scope.getRoles(roles).length > 0;
      }

      $scope.changeRole = (item, role) => {
        $http({
          method: item.rolesMap[role] ? 'POST' : 'DELETE',
          url: deploymentConfig.api.origin + '/user/' + item.id + '/role/' + role,
        }).then(
          (_response) => {
            $window.alert( role + ' role updated');
          },
          (response) => {
            $window.alert(response.data);
            $scope.getItemsForCurrentPage();
          },
        );
      };
    },
  ]);
