import * as angular from 'angular';

angular
  .module('app') //
  .run([
    '$location',
    '$rootScope',
    'authService',
    'User',
    // Don't use an arrow function here (or in any Angular constructor call)
    // tslint:disable-next-line:only-arrow-functions
    function($location, $rootScope, authService) {
      //
      authService.handleAuthentication();

      // This code appears to be made unnecessary by #Manager-21
      // $rootScope.$on('$locationChangeStart', (event) => {
      //
      //   // Don't reload the User service if a redirection after login is pending.
      //   if (!window.localStorage.getItem('nextPathAfterLogin')) {
      //     User.reload();
      //   }
      // });
    },
  ]);
