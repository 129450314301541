/* tslint:disable:only-arrow-functions */
/* tslint:disable:ter-prefer-arrow-callback */

import * as angular from 'angular';

angular.module('app').directive('headerNotification', function() {
  return {
    templateUrl: 'scripts/directives/header/header-notification/header-notification.html',
    restrict: 'E',
    replace: true,
  };
});
