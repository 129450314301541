import * as angular from 'angular';

angular.module('app', [
  'ui.bootstrap',
  'ui.router',
  'ui-notification',
  'summernote',

  'angular.filter',
  'angular-loading-bar',
  'angular-storage',
  'angularUtils.directives.dirPagination',

  'ngAnimate',
  'ngCookies',
  // TODO: ngStorage is used only in ng-ctrl-form.ts; use angular-storage to perform the same
  // functions, then remove ngStorage
  'ngStorage',
  'ngTagsInput',

  'auth0.auth0',
  'checklist-model',
  'datetime',
  'firebase.utils',
  'flow',

  'app.controllers',
  'app.filters',
  'app.services',
]);
