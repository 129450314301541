/* tslint:disable:only-arrow-functions */
/* tslint:disable:ter-prefer-arrow-callback */

import * as angular from 'angular';

angular
  .module('app') //
  .directive('notifications', function() {
    return {
      templateUrl: 'scripts/directives/notifications/notifications.html',
      restrict: 'E',
      replace: true,
    };
  });
