/* tslint:disable:only-arrow-functions */
/* tslint:disable:prefer-for-of */

import * as angular from 'angular';
import { find } from 'lodash';
import * as moment from 'moment';

import { URL } from '@src/submodules/cawst-core/types';
import { IFlowFile } from '../../../../overrides/flow';

interface FlowFile extends IFlowFile {
  uri: string;
  url: URL;
}

angular
  .module('app.controllers') //
  .controller('WashDocumentCtrl', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    'GlobalMessageService',
    'SweetAlertUtil',
    'WASH_Data',
    'WASH_Utilities',
    'WASH_Resource',
    'WASH_Document',
    'displayLanguageFilter',
    function(
      $rootScope,
      $scope,
      $state,
      $window,
      GlobalMessageService,
      SweetAlertUtil,
      WASH_Data,
      WASH_Utilities,
      WASH_Resource,
      WASH_Document,
      displayLanguageFilter,
    ) {
      $scope.manageDocument = {};

      $scope.language = 'en';
      $scope.loading = true;
      $scope.newDocument = true;

      $scope.isImageType = WASH_Utilities.isImageType;
      $scope.languages = WASH_Data.languages;
      $scope.regions = WASH_Data.regions;
      $scope.washDefinitions = WASH_Data.washDefinitions;

      // --- # Set form header text ---
      $scope.headerText = 'Edit document';
      if ($state.current.url === '/add_new_translation_region') {
        $scope.headerText = 'Add a translated document or region specific document';
      }

      // --- # Get resource & document ---
      const resourceId = $state.params.resourceId;
      $scope.document = {}; // document object being sent
      $scope.document.language = 'en';
      $scope.document.publicationDate = new Date();
      $scope.document.publicationStatus = 'published';

      WASH_Resource.getResource(resourceId, (data) => {
        $scope.resource = data;
        $scope.loading = false;

        console.log(data);

        if ($state.params.documentId) {
          const documentId = $state.params.documentId;
          $scope.document = find($scope.resource.documents, { id: documentId });
          if ($scope.document.publicationDate) {
            $scope.document.publicationDate = new Date($scope.document.publicationDate);
          } else {
            $scope.document.publicationDate = null;
          }
          $scope.newDocument = false;
        }
      });

      // --- # Form ---
      $scope.manageDocument.removeEditFunctionality = false;
      let processingMessageIndex;

      const removeProcessingMessage = function() {
        if (processingMessageIndex >= 0) {
          GlobalMessageService.clearMessage(processingMessageIndex, (allMessages) => {
            // console.log(allMessages);
          });
        }
      };

      const formProcessing = function(message) {
        $scope.manageDocument.removeEditFunctionality = true;
        GlobalMessageService.addMessage(message, 'processing', false, (index) => {
          processingMessageIndex = index;
        });
      };

      const formSuccess = function(message) {
        $scope.manageDocument.removeEditFunctionality = false;
        removeProcessingMessage();
        GlobalMessageService.addMessage(message, 'success', true, (index) => {
          $scope.$parent.loadResource();
          $state.go('wash.resource', { resourceId });
        });
      };

      const formError = function(message) {
        $scope.manageDocument.removeEditFunctionality = false;
        removeProcessingMessage();
        GlobalMessageService.addMessage(message, 'error', true, (index) => {
          // console.log('ViewResourceFormError, index:', index);
        });
      };

      $scope.datePicker = {};

      $scope.datePicker.status = {
        pubDate: {
          opened: false,
        },
      };

      $scope.datePicker.options = {
        formatYear: 'yyyy',
        formatDay: 'dd',
        formatMonth: 'MMMM',
        startingDay: 0,
      };

      $scope.disableUploadImage = function() {
        let disable = false;
        if ($scope.manageDocument.removeEditFunctionality) {
          disable = true;
        }
        if (WASH_Utilities.hasHighResImage($scope.document.files)) {
          disable = true;
        }
        if (find($scope.document.files, { type: 'image/png' })) {
          disable = true;
        }
        return disable;
      };

      $scope.disableUploadInstructions = function() {
        let disable = false;
        if ($scope.manageDocument.removeEditFunctionality) {
          disable = true;
        }
        if (WASH_Utilities.hasInstructions($scope.document.files)) {
          disable = true;
        }
        return disable;
      };

      $scope.cancelForm = function() {
        SweetAlertUtil.confirm(
          'Are you sure you want to close this form? You will be redirected to the resource overview and all changes will be lost.',
        ).then(
          (confirm) => {
            if (confirm) $state.go('wash.resource', { resourceId });
          },
          (p) => {
            /* fail */
          },
        );
      };

      $scope.changePublicationStatus = function(status) {
        if (status === 'archived') {
          SweetAlertUtil.confirm(
            'Are you sure you want to archive this document? This document will no longer be available on WASH Resources.',
          ).then(
            (confirm) => {
              if (confirm) {
                formProcessing('Archiving document...');
                $scope.document.publicationStatus = 'archived';
                WASH_Document.editDocument(resourceId, $scope.document, (_data) => {
                  const docLang = displayLanguageFilter($scope.document.language);
                  const docRegion = $scope.document.region
                    ? ' (' + $scope.document.region + ')'
                    : '';
                  const resourceTitle = $scope.resource.title;
                  formSuccess(docLang + docRegion + ' document archived in ' + resourceTitle);
                });
              }
            },
            (p) => {
              /* fail */
            },
          );
        } else if (status === 'published') {
          SweetAlertUtil.confirm(
            'Are you sure you want to publish this document? It will be available on WASH Resources.',
          ).then(
            (confirm) => {
              if (confirm) {
                formProcessing('Publishing document...');
                $scope.document.publicationStatus = 'published';
                WASH_Document.editDocument(resourceId, $scope.document, (_data) => {
                  const docLang = displayLanguageFilter($scope.document.language);
                  const docRegion = $scope.document.region
                    ? ' (' + $scope.document.region + ')'
                    : '';
                  const resourceTitle = $scope.resource.title;
                  formSuccess(docLang + docRegion + ' document published in ' + resourceTitle);
                });
              }
            },
            (p) => {
              /* fail */
            },
          );
        }
      };

      $scope.submitForm = function(isValid, _formObj) {
        if (isValid) {
          if ($scope.document.hasOwnProperty('files') && $scope.document.files.length > 0) {
            formProcessing('Saving new document...');
            WASH_Document.createNewDocument(resourceId, $scope.document, (data) => {
              if (data) {
                const docLang = displayLanguageFilter($scope.document.language);
                const docRegion = $scope.document.region ? ' (' + $scope.document.region + ')' : '';
                const resourceTitle = $scope.resource.title;
                formSuccess(docLang + docRegion + ' document saved in ' + resourceTitle);
              }
            });
          } else {
            formError('At least one file is required');
          }
        } else {
          formError('Please fill out all required fields');
        }
      };

      // --- # Link functions ---
      $scope.newLinkToAdd = '';
      $scope.showAddYoutubeVideo = false;

      $scope.addLinkToDocument = function(url) {
        if (!$scope.document.files) $scope.document.files = [];

        if (url && url.length > 0) {
          $scope.document.files.push({
            name: url,
            type: 'link',
            uri: url,
            fileSize: null,
            pages: null,
            upload_time: moment().toISOString(),
          });
          $scope.showAddYoutubeVideo = false;
        } else {
          SweetAlertUtil.alert('URL required to add YouTube video link.');
        }
      };

      $scope.removeLinkFromDocument = function(link) {
        SweetAlertUtil.confirm('Are you sure you want to delete this link?').then(
          (confirm) => {
            if (confirm && link) {
              for (const i in $scope.document.files) {
                if ($scope.document.files[i].uri === link.uri) {
                  $scope.document.files.splice(i, 1);
                  break;
                }
              }
            }
          },
          (p) => {
            /* fail */
          },
        );
      };

      // --- # File functions ---
      $rootScope.fileController = $scope;
      $scope.flowUploadCount = -1;

      $scope.onLoadItem = () => {
        // do nothing
      };

      $scope.downloadUploadedFile = (file) => {
        // Download file from tmp uploads folder
        // before it's been saved to the permanent location
        if (file.new) WASH_Utilities.downloadUploadedFile(file.uri);
        else WASH_Utilities.downloadFile(file.uri);
      };

      $scope.removeFile = (file) => {
        // Removes file from AWS S3 and the document object
        SweetAlertUtil.confirm('Are you sure you want to delete this file?').then(
          (confirm) => {
            if (confirm) {
              for (const i in $scope.document.files) {
                if ($scope.document.files[i].uri === file.uri) {
                  $scope.document.files.splice(i, 1);
                  break;
                }
              }

              if (file.new) {
                // If the file was uploaded in this session and is in the tmp folder
                WASH_Utilities.removeUploadedFile(file.uri, (_res) => {
                  $scope.onFileRemoved();
                });
              } else {
                // If the file was saved earlier and is in the final files folder
                WASH_Utilities.removeSavedFile(file.uri, (_res) => {
                  $scope.onFileRemoved();
                });
              }
            }
          },
          (p) => {
            /* fail */
          },
        );
      };

      $scope.onFilesAdded = function($files, $message, $flow) {
        const filenames = [];
        for (let i = 0; i < $files.length; i += 1) {
          const file = $files[i];
          if (file.size >= 300 * 1024 * 1024) {
            SweetAlertUtil.alert('File exceeds size limit of 300MB.');
            return false;
          }
          filenames.push(file.name);
        }

        $scope.flowUploadCount = filenames.length;

        WASH_Utilities.addFileUpload(filenames, (res) => {
          for (let j = 0; j < $files.length; j += 1) {
            $files[j].url = res[j];
            $files[j].uri = WASH_Utilities.getUriFromUrl(res[j]);
          }

          $flow.upload();
        });
      };

      $scope.onImageAdded = function($file, $event, $flow) {
        if (!WASH_Utilities.isHighResImage($file.file.type)) {
          SweetAlertUtil.alert('Can only upload pdf.');
          return false;
        }
        const $files = [$file];
        $scope.onFilesAdded($files, $event, $flow);
      };

      $scope.onInstructionsAdded = function($file, $event, $flow) {
        if (!WASH_Utilities.isInstructions($file.file.type)) {
          SweetAlertUtil.alert('Can only upload a word document for instructions and guidelines.');
          return false;
        }
        const $files = [$file];
        $scope.onFilesAdded($files, $event, $flow);
      };

      $scope.getFlowTarget = function(flowFile: FlowFile) {
        return flowFile.url;
      };

      $scope.onFileRemoved = function() {
        // do nothing
      };

      $scope.onFileSuccess = function($file, $message, $flow) {
        if (!$scope.document.files) $scope.document.files = [];

        $scope.document.files.push({
          name: $file.name,
          type: $file.file.type,
          uri: $file.uri,
          fileSize: $file.size,
          upload_time: moment().toISOString(),
          new: true, // DON'T TOUCH
        });

        $file.cancel();
        $scope.flowUploadCount -= 1;
      };
    },
  ]);
