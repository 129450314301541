/* tslint:disable:no-parameter-reassignment */
/* tslint:disable:only-arrow-functions */
import * as angular from 'angular';

angular
  .module('app.services') //
  .factory('WASH_Data', function() {
    // /////////////////////////////////////////////////////////////////////////
    this.fileMimeType = [
      {
        type: 'application/msword',
        display: 'DOC',
        icon: 'fa-file-word-o',
      },
      {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        display: 'DOCX',
        icon: 'fa-file-word-o',
      },
      {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        display: 'DOTX',
        icon: 'fa-file-word-o',
      },
      {
        type: 'application/vnd.ms-powerpoint',
        display: 'PPT',
        icon: 'fa-file-powerpoint-o',
      },
      {
        type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        display: 'PPTX',
        icon: 'fa-file-powerpoint-o',
      },
      {
        type: 'video/x-ms-wmv',
        display: 'WMV',
        icon: 'fa-file-video-o',
      },
      {
        type: 'application/vnd.ms-excel',
        display: 'XLS',
        icon: 'fa-file-excel-o',
      },
      {
        type: 'application/pdf',
        display: 'PDF',
        icon: 'fa-file-pdf-o',
      },
      {
        type: 'link',
        display: '',
        icon: 'fa-external-link',
      },
    ];
    // /////////////////////////////////////////////////////////////////////////
    this.highResImageTypes = ['application/pdf', 'image/svg+xml'];
    // /////////////////////////////////////////////////////////////////////////
    this.imageMimeTypes = ['image/pjpeg', 'image/jpeg', 'image/png', 'image/gif'];
    // /////////////////////////////////////////////////////////////////////////
    this.languages = [
      { value: '00', label: 'No text/voice' },
      { value: 'en', label: 'English' },
      { value: 'es', label: 'Spanish' },
      { value: 'fr', label: 'French' },
      { value: 'am', label: 'Amharic' },
      { value: 'ar', label: 'Arabic' },
      { value: 'id', label: 'Bahasa' },
      { value: 'bem', label: 'Bemba' },
      { value: 'my', label: 'Burmese' },
      { value: 'ht', label: 'Creole' }, // Could be 'bi', 'ht', 'rc', or 'sg', in WASH Res was 'ht'.
      { value: 'kar', label: 'Karen' },
      { value: 'km', label: 'Khmer' },
      { value: 'ne', label: 'Nepali' },
      { value: 'sw', label: 'Swahili' },
      { value: 'tzsw', label: 'Tanzanian Swahili' },
      { value: 'th', label: 'Thai' },
    ];
    // /////////////////////////////////////////////////////////////////////////
    this.regions = [
      { value: 'Africa' },
      { value: 'Caribbean' },
      { value: 'Latin America' },
      { value: 'South Asia' },
      { value: 'South East Asia' },
      { value: 'South West Asia' },
    ];
    // /////////////////////////////////////////////////////////////////////////
    this.resourceTypes = [
      { value: 'Activity', category: 'Complex', image: true },
      { value: 'Activity Instructions', category: 'Simple', image: false },
      { value: 'Agenda', category: 'Simple', image: false },
      { value: 'Appendix', category: 'Complex', image: false },
      { value: 'Brochure', category: 'Simple', image: false },
      { value: 'Budget', category: 'Simple', image: false },
      { value: 'Case Studies', category: 'Complex', image: false },
      { value: 'Case Study', category: 'Simple', image: false },
      { value: 'Certificate', category: 'Simple', image: false },
      { value: 'Construction Manual', category: 'Complex', image: false },
      { value: 'Evaluation', category: 'Simple', image: false },
      { value: 'Evaluation Summary', category: 'Simple', image: false },
      { value: 'Fact Sheet', category: 'Simple', image: false },
      { value: 'Fact Sheets', category: 'Complex', image: false },
      { value: 'Form', category: 'Simple', image: false },
      { value: 'Forms', category: 'Complex', image: false },
      { value: 'Guide', category: 'Simple', image: false },
      { value: 'Handout', category: 'Simple', image: false },
      { value: 'Instructions', category: 'Simple', image: false },
      { value: 'Lesson Plan', category: 'Simple', image: false },
      { value: 'Lesson Plans', category: 'Complex', image: false },
      { value: 'Literature Summary', category: 'Simple', image: false },
      { value: 'Manual', category: 'Complex', image: false },
      { value: 'Participant List', category: 'Simple', image: false },
      { value: 'Poster', category: 'Simple', image: true },
      { value: 'Poster Set', category: 'Complex', image: true },
      { value: 'Presentation', category: 'Simple', image: false },
      { value: 'Product Sheet', category: 'Simple', image: false },
      { value: 'Product Sheets', category: 'Complex', image: false },
      { value: 'Questionnaire', category: 'Simple', image: false },
      { value: 'Research Essentials', category: 'Simple', image: false },
      { value: 'Section', category: 'Simple', image: false },
      { value: 'Technical Brief', category: 'Simple', image: false },
      { value: 'Technical Briefs', category: 'Complex', image: false },
      { value: 'Tool', category: 'Simple', image: false },
      { value: 'Training Toolkit', category: 'Collection', image: false },
      { value: 'Trainer Essentials', category: 'Complex', image: false },
      { value: 'Trainer Essential', category: 'Simple', image: false },
      { value: 'Trainer Manual', category: 'Complex', image: false },
      { value: 'Trainer Tools', category: 'Complex', image: false },
      { value: 'Video', category: 'Simple', image: false },
      { value: 'Webinar Video', category: 'Simple', image: false },
      { value: 'Worksheet', category: 'Simple', image: false },
      { value: 'Workshop Outline', category: 'Simple', image: false },
      { value: 'Workshop Report', category: 'Simple', image: false },
    ];
    // /////////////////////////////////////////////////////////////////////////
    this.topicCheckboxes = {
      'Water Supply': false,
      'Drinking Water Quality Testing': false,
      Sanitation: false,
      'Delivering and Creating Training': false,
      'Household Water Treatment and Safe Storage': false,
      'Biosand Filter': false,
      'WASH and Health': false,
      'Monitoring for Improvement': false,
      'Community WASH Promotion and Hygiene': false,
      Evaluation: false,
      COVID19: false,
    };
    // /////////////////////////////////////////////////////////////////////////
    this.uploadInstructionTypes = [
      'application/msword', // .doc && .dot
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template', // .dotx
    ];
    // /////////////////////////////////////////////////////////////////////////
    /*tslint:disable */
    this.washDefinitions = {
      document:
        'This is a language and region specific document available in a resource. A document contains files. A document generally contains one non editable file (e.g. a pdf) and an editable file (i.e. a docx).',
      simpleResource:
        'A resource without any sub items.',
      complexResource:
        'A resource with sub-items. These sub items are within the complex resource but not all aspects of the complex resource are available as separate items. A complex resource is uploaded as it\'s own document.',
      collection:
        'A curated collection of resources that are put into a .zip file and made available to clients. Different than a complex resource because it does contain all sub resources in the .zip.',
      category:
        'A category describes how the resource is put together and presented to clients. There are 3 categories: Simple Resource, Complex Resource, and Collection.',
      image:
        'Some resource types are classified as an "Image". Examples of these types include posters and activities. Images can include a file for instructions.',
    };
    /*tslint:enable */
    // /////////////////////////////////////////////////////////////////////////
    return this;
  });
