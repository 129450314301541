/* tslint:disable:no-console */

import { merge } from 'lodash';

import { SideEffects } from '../types';
import {
  ConfigOptions,
  resolveConfig,
} from './resolveConfig';

type DeploymentTargetGroup = 'universal';

export type DeploymentTarget = 'dev' | 'local' | 'prod';
const defaultDeploymentTargets: DeploymentTarget[] = ['dev', 'local', 'prod'];

type RunningMode = 'test';

interface ConfigSettings {
  [name: string]: any;
}

export type ConfigScope = DeploymentTargetGroup | DeploymentTarget | RunningMode;

export type ConfigRule = [ConfigScope | ConfigScope[], ConfigSettings];

interface ScopeConfigData {
  [name: string]: any;
}

interface MulticonfigData {
  [scope: string]: ScopeConfigData;
}

export class ConfigMerger {
  protected static defaultOptions = {
    deploymentTarget: 'local',
    forceMinify: false,
    isTest: false,
  };

  protected deploymentTargets: DeploymentTarget[] = [];
  protected multiconfig: MulticonfigData = {};
  protected rules: ConfigRule[] = [];

  // ==========
  // METHODS

  // Apply the rules to create a MulticonfigData object
  protected adoptRule(rule: ConfigRule): SideEffects {
    const [scopeIdentifier, settings] = rule;

    // Scopes to which the rule applies; convert a single value into a single-value array
    const scopes: ConfigScope[] = Array.isArray(scopeIdentifier)
      ? scopeIdentifier
      : [scopeIdentifier];

    for (const scope of scopes) {
      this.mergeSettingsIntoScope(settings, scope);
    }
  }

  protected initializeMulticonfig(initialMulticonfig: MulticonfigData) {
    this.multiconfig = initialMulticonfig;

    // Add an empty object, if missing, for each deployment target
    this.deploymentTargets.forEach((target: DeploymentTarget) => {
      if (!(target in this.multiconfig)) {
        this.multiconfig[target] = {};
      }
      this.multiconfig[target].deploymentTarget = target;

      // Adopt any universal settings without overwriting own settings
      if ('universal' in this.multiconfig) {
        this.multiconfig[target] = merge({}, this.multiconfig.universal, this.multiconfig[target]);
      }
    });
    return this.multiconfig;
  }

  protected initializeScope(scope: ConfigScope): SideEffects {
    if (!(scope in this.multiconfig)) {
      this.multiconfig[scope] = {};

      //  If scope is a deployment target, set `deploymentTarget` = name of scope
      if (this.deploymentTargets.includes(scope as DeploymentTarget)) {
        this.multiconfig[scope].deploymentTarget = scope;
      }
    }

    // Inherit 'universal' settings, if any
    if ('universal' in this.multiconfig) {
      this.multiconfig[scope] = Object.assign(this.multiconfig[scope], this.multiconfig.universal);
    }
  }

  public identifyNonuniversalScopes(): ConfigScope[] {
    return Object.keys(this.multiconfig).filter(
      (scope: ConfigScope) => scope !== 'universal',
    ) as ConfigScope[];
  }

  protected mergeSettingsIntoScope(settings: ConfigSettings, scope: ConfigScope) {
    if (!(scope in this.multiconfig)) {
      this.initializeScope(scope);
    }
    this.multiconfig[scope] = merge(this.multiconfig[scope], settings);
  }

  // ==========
  // PUBLIC RULES

  public constructor(
    private options: {
      initialMulticonfig?: {};
      rules?: ConfigRule[];
      deploymentTargets?: DeploymentTarget[];
    } = {},
  ) {
    // 1. Set deployment targets, if any
    this.deploymentTargets = options.deploymentTargets || defaultDeploymentTargets;

    // 2. This step uses deployment targets, if set
    this.initializeMulticonfig(options.initialMulticonfig || {});

    // 3. This step uses deployment targets, if set
    if (options.rules) {
      this.addRules(options.rules);
    }
  }

  // Add a rule and update multiconfig
  public addRule(rule: ConfigRule) {
    this.rules.push(rule);
    this.adoptRule(rule);

    const [scopeIdentifier, settings] = rule;
    if (scopeIdentifier === 'universal') {
      const otherScopes: ConfigScope[] = this.identifyNonuniversalScopes();

      if (otherScopes.length > 0) {
        // Adopt the same rule into all other scopes
        this.adoptRule([otherScopes, settings]);
      }
    }
  }

  // Add multiple rules at once
  public addRules(rules: ConfigRule[]) {
    for (const rule of rules) {
      this.addRule(rule);
    }
  }

  public getMulticonfig() {
    return this.multiconfig;
  }

  // Resolve the config for a particular deployment
  public getResolvedConfig(options: ConfigOptions = {}) {
    return resolveConfig(this.multiconfig, options);
  }
}
