import * as angular from 'angular';

import { stripOriginFromUrl } from '@submodules/cawst-core/util';

angular
  .module('app') //
  .run([
    '$location',
    '$rootScope',
    // tslint:disable-next-line:only-arrow-functions
    function($location, $rootScope) {
      // //////////////////////////////////////////////////////////////////////////
      // Ripped from: http://www.markcampbell.me/tutorial/2013/10/08/preventing-navigation-in-an-angularjs-project.html

      let preventNavigation = false;
      let preventNavigationUrl = null;

      $rootScope.allowNavigation = () => {
        preventNavigation = false;
      };

      $rootScope.preventNavigation = () => {
        preventNavigation = true;
        preventNavigationUrl = $location.absUrl();
      };

      $rootScope.$on('$locationChangeStart', (_event, _newUrl, oldUrl) => {
        // if(newUrl.indexOf('/add')!=-1 || newUrl.indexOf('/edit?')!=-1) {
        // 	$rootScope.allowNavigation();
        // 	return;
        // }
        //
        // if(oldUrl.indexOf('/edit?')==-1 && oldUrl.indexOf('/add')==-1) {
        // 	$rootScope.allowNavigation();
        // 	return;
        // }
        //
        // if (preventNavigationUrl != oldUrl || preventNavigationUrl == null) {
        // 	$rootScope.allowNavigation();
        // 	return;
        // }
        //
        // if (preventNavigation && !confirm("This will discard any changes. Select 'OK' to continue.")) {
        // 	event.preventDefault();
        // }
        // else {
        // 	$rootScope.allowNavigation();
        // }
        if (oldUrl.indexOf('/add') !== -1 || oldUrl.indexOf('/edit') !== -1) {
          // save a copy of the form when the user navigates away from the page
          saveChangedForm(oldUrl);
        }
      });

      function saveChangedForm(url) {
        const itemFormCtrl: any = angular.element(document.getElementById('itemForm')).scope();
        if (itemFormCtrl && itemFormCtrl.saveChangedForm) {
          itemFormCtrl.saveChangedForm(stripOriginFromUrl(url));
        }
      }

      window.onbeforeunload = () => {
        if (preventNavigation && $location.absUrl() === preventNavigationUrl) {
          saveChangedForm($location.absUrl());
          return 'This will discard any changes.';
        }
      };
    },
  ]);
