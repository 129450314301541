window.jQuery(() => {
  window.jQuery('#side-menu').metisMenu();
});

// Loads the correct sidebar on window load,
// collapses the sidebar on window resize.
// Sets the min-height of #page-wrapper to window size
window.jQuery(() => {
  window.jQuery(window).bind('load resize', function() {
    let topOffset = 50;
    const width = this.window.innerWidth > 0 ? this.window.innerWidth : this.screen.width;
    if (width < 768) {
      $('div.navbar-collapse').addClass('collapse');
      topOffset = 100; // 2-row-menu
    } else {
      $('div.navbar-collapse').removeClass('collapse');
    }

    let height = (this.window.innerHeight > 0 ? this.window.innerHeight : this.screen.height) - 1;
    height = height - topOffset;
    if (height < 1) {
      height = 1;
    }
    if (height > topOffset) {
      window.jQuery('#page-wrapper').css('min-height', height + 'px');
    }
  });

  const url = window.location;
  const element = window
    .jQuery('ul.nav a')
    .filter(function() {
      return this.href === url || url.href.indexOf(this.href) === 0;
    })
    .addClass('active')
    .parent()
    .parent()
    .addClass('in')
    .parent();
  if (element.is('li')) {
    element.addClass('active');
  }
});
