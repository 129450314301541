/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';

import { cloneDeep } from 'lodash';

angular
  .module('app') //
  .directive('prodTechItem', [
    'HWTS_Utilities',
    'SweetAlertUtil',
    function(HWTS_Utilities, SweetAlertUtil) {
      return {
        restrict: 'AE',
        templateUrl: 'scripts/directives/hwts/prod-tech-item.tpl.html',
        scope: {
          inputType: '=', // 'range', 'text', 'scheme'
          multiple: '=', // boolean
          label: '=', // text
          model: '=', // ng-model
          units: '=', // array
          location: '=', // boolean
          searchItems: '=', // searchItems() function
        },
        link(scope: any, _element, _attribute) {
          let itemClone = null;
          scope.getLocation = HWTS_Utilities.getLocation;

          scope.onAddItem = function() {
            // Create empty model
            // "multiple" means multiple objects in an array
            if (scope.multiple) {
              if (!scope.model) scope.model = [];
              scope.model.push({});
            } else {
              scope.model = {};
            }
          };

          scope.onRemoveItem = function($index) {
            // Remove object from model
            SweetAlertUtil.confirm('Are you sure you want to remove this item?').then(
              (confirm) => {
                if (confirm) {
                  if (scope.multiple && $index) {
                    scope.model.splice($index, 1);
                  } else {
                    scope.model = null;
                  }
                }
              },
              (_p) => {
                /* fail */
              },
            );
          };

          scope.onChangeItem = function($index, item) {
            // Update the item on change, need to manually do this
            scope.model[$index] = item;
          };

          scope.showRangeFields = function(item, _idx) {
            // *** NOTE: Required for legacy fields
            // Clone item so we have access to original, un-edited data
            if (!itemClone) {
              itemClone = cloneDeep(item);
            }

            let ret = false;
            if (scope.inputType === 'range') {
              ret = true;
            }
            if (item && item.lowerRange) {
              ret = true;
            }
            if (item && item.upperRange) {
              ret = true;
            }
            if (itemClone && itemClone.lowerRange) {
              ret = true;
            }
            if (itemClone && itemClone.upperRange) {
              ret = true;
            }
            return ret;
          };

          scope.showTextField = function(item, _idx) {
            // *** NOTE: Required for legacy fields
            // Clone item so we have access to original, un-edited data
            if (!itemClone) itemClone = cloneDeep(item);

            let ret = false;
            if (scope.inputType === 'text') {
              ret = true;
            }
            if (item && item.value) {
              ret = true;
            }
            if (itemClone && itemClone.value) {
              ret = true;
            }
            return ret;
          };
        },
      };
    },
  ]);
