import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.services') //
  .service('WASH_Resource', [
    '$http',
    '$state',
    'WASH_HttpWrapper',
    // tslint:disable-next-line:only-arrow-functions
    function($http, $state, WASH_HttpWrapper) {
      const httpWrapper = WASH_HttpWrapper.httpWrapper;
      const base = '/resource';

      return {
        getResource(resourceId, cb) {
          if (resourceId) {
            const command = base + '/' + resourceId;
            httpWrapper('GET', command, cb);
          } else {
            console.error('resourceId required to get resource');
            if (cb) {
              cb(null);
            }
          }
        },

        getResourceByDocument(documentId, cb) {
          if (documentId) {
            const command = deploymentConfig.api.origin + '/wash/public/document/?id=' + documentId;

            $http.get(command).then(
              (response) => {
                if (cb) {
                  cb(response.data);
                }
              },
              (err) => {
                if (err.status === 401) {
                  $state.go('signin');
                } else {
                  console.error(err);
                  if (cb) {
                    cb(null);
                  }
                }
              },
            );
          } else {
            console.error('documentId required to get resource by document');
            if (cb) {
              cb(null);
            }
          }
        },

        editResource(resource, cb) {
          if (resource) {
            const command = base;
            httpWrapper('POST', command, cb, resource);
          } else {
            console.error('resource required to edit resource');
            if (cb) {
              cb(null);
            }
          }
        },

        addResource(resource, cb) {
          if (resource) {
            const command = base;
            httpWrapper('POST', command, cb, resource);
          } else {
            console.error('resource required to add resource');
            if (cb) {
              cb(null);
            }
          }
        },

        removeResource(resourceId, cb) {
          if (resourceId) {
            const command = base + '/' + resourceId;
            httpWrapper('DELETE', command, cb);
          } else {
            console.error('resourceId required to remove resource');
            if (cb) {
              cb(null);
            }
          }
        },

        addResourceChildren(resourceId, childrenArray, cb) {
          if (resourceId && childrenArray) {
            const command = base + '/' + resourceId + '/children';
            httpWrapper('POST', command, cb, childrenArray);
          } else {
            console.error('resourceId and array of children required to add children to resource');
            if (cb) {
              cb(null);
            }
          }
        },

        removeResourceChildren(resourceId, childrenArray, cb) {
          if (resourceId && childrenArray) {
            const command = base + '/' + resourceId + '/children';
            httpWrapper('DELETE', command, cb, childrenArray);
          } else {
            /* tslint:disable-next-line:prettier */
            console.error('resourceId and array of children required to remove children to resource');
            if (cb) {
              cb(null);
            }
          }
        },
      };
    },
  ]);
