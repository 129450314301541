/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { toArray } from 'lodash';
import * as moment from 'moment';

angular
  .module('app.controllers') //
  .controller('URLRedirectsCtrl', [
    '$scope',
    'fbutil',
    function($scope, fbutil) {
      $scope.spin_loading = true;
      const path = 'redirects/mainwebsite';

      $scope.reload = function() {
        $scope.spin_loading = true;
        fbutil.get(path + '/all', (data) => {
          for (const key of Object.keys(data)) {
            data[key].id = key;
          }
          $scope.urlList = toArray(data);
          $scope.spin_loading = false;
        });
      };

      $scope.newRedirect = function() {
        $scope.adding = true;
        $scope.newpath = { datemodified: moment().valueOf() };
      };
      $scope.saveNewRedirect = function(newpath) {
        fbutil.push(path, newpath, () => {
          $scope.reload();
          $scope.adding = false;
          $scope.newpath = {};
        });
      };

      $scope.remove = function(item) {
        fbutil.remove(path, item.id, () => {
          $scope.reload();
        });
      };

      $scope.save = function(item) {
        fbutil.set(path, item.id, item, () => {
          $scope.reload();
        });
      };

      $scope.reload();
    },
  ]);
