/* tslint:disable:no-console */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.controllers') //
  .controller('SearchIndexController', [
    '$http',
    '$scope',
    '$window',
    function($http, $scope, $window) {
      $scope.updateIndexAll = function() {
        console.log('Rebuild all search indexes started... (updateIndexAll)');
        const url = deploymentConfig.api.origin + '/vwet/secure/searchindex/rebuildAll';

        $http.get(url).then(
          (response) => {
            console.log(response);
          },
          (err) => {
            $window.alert(JSON.stringify(err.data));
          },
        );
      };
    },
  ]);
