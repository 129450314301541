/* tslint:disable:only-arrow-functions */
import * as angular from 'angular';

angular
  .module('app.services') //
  .service('WASH_Document', [
    'WASH_HttpWrapper',
    function(WASH_HttpWrapper) {
      const httpWrapper = WASH_HttpWrapper.httpWrapper;
      const base = '/resource';

      return {
        createNewDocument(resourceId, newDocument, cb) {
          if (resourceId && newDocument) {
            const command = base + '/' + resourceId + '/document';
            httpWrapper('POST', command, cb, newDocument);
          } else {
            console.error('resourceId and newDocument required to create new document');
            if (cb) {
              cb(null);
            }
          }
        },

        editDocument(resourceId, document, cb) {
          if (resourceId && document) {
            const command = base + '/' + resourceId + '/document';
            httpWrapper('POST', command, cb, document);
          } else {
            console.error('resourceId and document required to edit document');
            if (cb) {
              cb(null);
            }
          }
        },

        removeDocument(documentId, cb) {
          if (documentId) {
            const command = '/document/' + documentId;
            httpWrapper('DELETE', command, cb);
          } else {
            console.error('documentId required to remove document');
            if (cb) {
              cb(null);
            }
          }
        },

        zipCollection(resourceId: string, language: string, region, filename: string, cb) {
          if (resourceId && language && filename) {

            const sanitizedRegion = region === 'Generic' ? null : region;

            // prettier-ignore
            const command =
              base +
              '/' +
              resourceId +
              '/zip/' + language +
              '?filename=' + filename +
              '&region=' + sanitizedRegion;

            httpWrapper('POST', command, cb);
          } else {
            console.error('missing information to zip collection');
            if (cb) {
              cb(null);
            }
          }
        },
      };
    },
  ]);
