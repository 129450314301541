/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { find } from 'lodash';
import * as moment from 'moment';

import { deploymentConfig } from '@src/client/config/deploymentConfig';
import { countryCodesAndNames } from '@submodules/cawst-core/data/geography/countries';

angular
  .module('app.controllers') //
  .controller('VWetStatsCtrl', [
    '$scope',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'VWetStats',
    function($scope, $rootScope, $state, $window, authService, VWetStats) {
      $scope.vwetStatsPage = {};

      $scope.vwetStatsPage.stats = {
        segment: $state.params.segment,
        pageSize: 20,
        page: $state.params.page ? $state.params.page : 1,
        totalItems: null,
        totalPages: null,
        items: {},
      };

      $scope.vwetStatsPage.filters = {
        groupby: $state.params.groupby ? $state.params.groupby : null,
        orderby: $state.params.orderby ? $state.params.orderby : 'date',
        country: $state.params.country ? $state.params.country : '',
        countries: $state.params.countries ? $state.params.countries : '',
        dateStart: $state.params.dateStart ? $state.params.dateStart : '',
        dateEnd: $state.params.dateEnd ? $state.params.dateEnd : '',
        email: $state.params.email ? $state.params.email : '',
        region: $state.params.region ? $state.params.region : '',
        orgName: $state.params.orgName ? $state.params.orgName : '',
        userLang: $state.params.userLang ? $state.params.userLang : '',
      };

      $scope.vwetStatsPage.downloadGroups = [
        { value: 'country', label: 'Country' },
        { value: 'user', label: 'User' },
        { value: 'organization', label: 'Organization' },
        { value: 'resource', label: 'Resource' },
        { value: 'file', label: 'Resource file' },
        { value: 'history', label: 'Resource history' },
        { value: 'type', label: 'Resource type' },
        { value: 'region', label: 'Resource region' },
        { value: 'category', label: 'Category' },
      ];

      $scope.vwetStatsPage.userGroups = [{ value: 'country', label: 'Country' }];

      $scope.vwetStatsPage.region = [
        { value: '', label: 'Any region' },
        { value: 'Africa', label: 'Africa' },
        { value: 'Caribbean', label: 'Caribbean' },
        { value: 'Latin America', label: 'Latin America' },
        { value: 'South Asia', label: 'South Asia' },
        { value: 'South East Asia', label: 'South East Asia' },
        { value: 'South West Asia', label: 'South West Asia' },
      ];

      $scope.vwetStatsPage.userLangOptions = [
        { value: '', label: 'Any language' },
        { value: 'en', label: 'English' },
        { value: 'fr', label: 'French' },
        { value: 'es', label: 'Spanish' },
      ];

      // Get stats /////////////////////////////////////////////////////////////
      $scope.vwetStatsPage.GetStats = function() {
        $scope.vwetStatsPage.loadingStats = true;

        const page = $scope.vwetStatsPage.stats.page - 1;
        const pageSize = $scope.vwetStatsPage.stats.pageSize;
        const filters = $scope.vwetStatsPage.filters;

        switch ($scope.vwetStatsPage.stats.segment) {
          case 'downloads':
            VWetStats.getDownloadStats(page, pageSize, filters, (data) => {
              $scope.vwetStatsPage.loadingStats = false;
              if (data) {
                $scope.vwetStatsPage.stats.items = data.Stats;
                $scope.vwetStatsPage.stats.totalItems = data.TotalItems;
                $scope.vwetStatsPage.stats.totalPages = data.MaxPages;
              }
            });
            break;

          case 'users':
            VWetStats.getUserStats(page, pageSize, filters, (data) => {
              $scope.vwetStatsPage.loadingStats = false;
              if (data) {
                $scope.vwetStatsPage.stats.items = data.Stats;
                $scope.vwetStatsPage.stats.totalItems = data.TotalItems;
                $scope.vwetStatsPage.stats.totalPages = data.MaxPages;
              }
            });
            break;

          default:
            $scope.vwetStatsPage.loadingStats = false;
            $scope.vwetStatsPage.stats.items = {};
            $scope.vwetStatsPage.stats.totalItems = null;
            $scope.vwetStatsPage.stats.totalPages = null;
        }
      };

      $scope.vwetStatsPage.SubmitQuery = function(queryType) {
        if (queryType !== 'pageChange') {
          $scope.vwetStatsPage.stats.page = 1;
        }
        const params = $scope.vwetStatsPage.filters;
        params.page = $scope.vwetStatsPage.stats.page;
        params.segment = $scope.vwetStatsPage.stats.segment;

        $state.go('vwetstats', params);
        $scope.vwetStatsPage.GetStats();
      };

      $scope.vwetStatsPage.DownloadCsv = function() {
        const view = $scope.vwetStatsPage.filters.groupby
          ? 'group'
          : $scope.vwetStatsPage.stats.segment;
        VWetStats.generateCsv(
          $scope.vwetStatsPage.stats.segment,
          view,
          $scope.vwetStatsPage.filters,
        );
      };

      // Date picker ///////////////////////////////////////////////////////////
      const vwetStartDate = new Date(2013, 3, 1);
      const today = new Date();

      $scope.vwetStatsPage.datePicker = {
        format: 'dd-MMMM-yyyy',
        minDate: vwetStartDate,
        maxDate: today,
        startDate: $scope.vwetStatsPage.filters.dateStart
          ? new Date($scope.vwetStatsPage.filters.dateStart)
          : vwetStartDate,
        endDate: $scope.vwetStatsPage.filters.dateEnd
          ? new Date($scope.vwetStatsPage.filters.dateEnd)
          : today,
        startOpened: false,
        endOpened: false,
      };

      $scope.vwetStatsPage.datePicker.Open = function(datePicker) {
        if (datePicker === 'startDate') $scope.vwetStatsPage.datePicker.startOpened = true;
        else if (datePicker === 'endDate') $scope.vwetStatsPage.datePicker.endOpened = true;
      };

      $scope.vwetStatsPage.datePicker.ApplyDates = function() {
        const startDate = moment($scope.vwetStatsPage.datePicker.startDate);
        const endDate = moment($scope.vwetStatsPage.datePicker.endDate);
        const dateFormat = 'YYYY-MM-DD';
        $scope.vwetStatsPage.filters.dateStart = startDate.format(dateFormat);
        $scope.vwetStatsPage.filters.dateEnd = endDate.format(dateFormat);
        $scope.vwetStatsPage.SubmitQuery();
      };

      // Misc functions ////////////////////////////////////////////////////////
      $scope.vwetStatsPage.DownloadFile = function(fileUri, app, fileType) {
        // prettier-ignore
        const baseUrl = app === 'hwts'
          ? deploymentConfig.api.baseUrls.hwts
          : deploymentConfig.api.baseUrls.wash;

        $window.open(baseUrl + '/file?uri=' + fileUri + '&authorization=' + authService.retrieveAccessToken());

        if (fileType === 'link') {
          $window.open(fileUri); // should be `fileUri`?  #Manager-26
        }
      };

      $scope.vwetStatsPage.UriToFileName = function(fileUri) {
        const regex = /files\//;
        return fileUri.replace(regex, '');
      };

      $scope.vwetStatsPage.GroupByDisplay = function(groupby) {
        const item = find($scope.vwetStatsPage.downloadGroups, { value: groupby });
        return item && item.label ? item.label : 'n/a';
      };

      $scope.vwetStatsPage.ClearFilters = function() {
        $scope.vwetStatsPage.filters = {
          country: '',
          countries: '',
          email: '',
          region: '',
          orgName: '',
        };
        $scope.vwetStatsPage.SubmitQuery();
      };

      $scope.vwetStatsPage.SegmentView = function(view) {
        return view === $scope.vwetStatsPage.stats.segment;
      };

      $scope.vwetStatsPage.title = function() {
        let title = 'VWET Stats';
        if ($scope.vwetStatsPage.stats.segment === 'downloads') {
          title = 'Downloads';
        }
        if ($scope.vwetStatsPage.stats.segment === 'users') {
          title = 'Users';
        }
        if ($scope.vwetStatsPage.filters.groupby) {
          title +=
            ' grouped by ' +
            $scope.vwetStatsPage.GroupByDisplay($scope.vwetStatsPage.filters.groupby);
        }
        return title;
      };

      // Run on load ///////////////////////////////////////////////////////////
      $scope.vwetStatsPage.GetStats();

      $scope.countryList = countryCodesAndNames;
    },
  ]);
