import * as angular from 'angular';
import * as moment from 'moment';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.controllers') //
  .controller('CountryItemController', [
    '$http',
    '$scope',
    '$state',
    '$window',
    '$rootScope',
    // tslint:disable-next-line:only-arrow-functions
    function($http, $scope, $state, $window, $rootScope) {
      $scope.loading = true;
      $rootScope.item = null;
      $scope.hwts_image_serve_url = deploymentConfig.resources.imageBaseUrls.hwts + '/';
      $scope.countryCollaborators = ['e4c'];

      $scope.getItem = () => {
        const url = deploymentConfig.api.baseUrls.vwet + '/country/' + $state.params.id;
        $http.get(url).then(
          (response) => {
            $scope.loading = false;
            $rootScope.item = response.data;
          },
          (err) => {
            $window.alert(err.data);
            $scope.loading = false;
          },
        );
      };

      $scope.searchItems = (type, value) => {
        return $http
          .get(deploymentConfig.api.baseUrls.hwts + '/' + type + '/search?title=' + value)
          .then((response) => {
            return response.data;
          });
      };

      $scope.getKeyItems = (value) => {
        return $http
          .get(deploymentConfig.api.baseUrls.hwts + '/keyItems/search?title=' + value)
          .then((response) => {
            return response.data;
          });
      };

      $scope.hwtsCollaborators = (value) => {
        const results = [];
        $scope.countryCollaborators.forEach((collaborator) => {
          if (collaborator.includes(value)) {
            results.push(collaborator);
          }
        });
        return results;
      };

      // TODO: This function is misnamed: it does not save properties, it adds them
      // to the `item` object.
      $scope.saveDateProperties = (item) => {
        for (const i of Object.keys($rootScope.dateProperties)) {
          const prop = $rootScope.dateProperties[i];
          if (item[prop]) {
            item[prop] = moment(item[prop]).toISOString();
          }
        }
        return item;
      };

      $scope.save = (rawItem) => {
        $scope.saving = true;

        let item = JSON.parse(JSON.stringify(rawItem));

        // TODO: Remove? Seems to be unnecessary: there are no date properties on the country page
        item = $scope.saveDateProperties(item);

        $http.post(deploymentConfig.api.baseUrls.vwet + '/country/' + item.id, item).then(
          (_response) => {
            $scope.saving = false;
            $rootScope.allowNavigation();
            $state.go('^', {}, { reload: true });
          },
          (_err) => {
            $scope.saving = false;
            $window.alert('Unable to save changes');
          },
        );
      };

      $scope.getItem();
    },
  ]);
