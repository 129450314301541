/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';
import { URL } from '@submodules/cawst-core/types';
import { Pagination } from '../types';

interface CountryDetails {
  code: string;
  flag: string;
  id: string;
  name: string;
}

interface CountryPagination extends Pagination {
  items: CountryDetails[];
}

angular.module('app.controllers').controller('CountryListController', [
  '$http',
  '$rootScope',
  '$scope',
  '$state',
  '$window',
  function($http, $rootScope, $scope, $state, $window) {
    $scope.type = 'country';
    $scope.pagination = {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 10,
      items: [],
      filterValue: '',
    } as CountryPagination;
    $scope.loading = true;

    $scope.getItemsForCurrentPage = function() {
      const filterPostFix = $scope.pagination.filterValue
        ? '?filter=' + $scope.pagination.filterValue
        : '';
      const url: URL =
        deploymentConfig.api.baseUrls.vwet +
        '/country/paginate/' +
        $scope.pagination.currentPage +
        '/' +
        $scope.pagination.itemsPerPage;
      $http.get(url + filterPostFix).then(
        (response) => {
          $scope.pagination.items = response.data.items;
          $scope.pagination.itemCount = response.data.totalItemCount;
          $scope.loading = false;

          $scope.pagination.items.forEach((item, i) => {
            $scope.pagination.items[i].rolesMap = {};

            /*
            // $scope.roles isn't defined
            for (const j of Object.keys($scope.roles)) {
              $scope.pagination.items[i].rolesMap[$scope.roles[j]] =
                $scope.pagination.items[i].roles.indexOf($scope.roles[j]) !== -1;
            }
             */
          });
        },
        (err) => {
          $window.alert(err.data);
          $scope.loading = false;
        },
      );
    };

    $scope.onPageChange = function(newPageNumber) {
      $scope.pagination.currentPage = newPageNumber;
      $scope.getItemsForCurrentPage();
    };

    $scope.getItemsForCurrentPage();
  },
]);
