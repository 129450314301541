import { DeploymentTarget } from '@submodules/cawst-core/config/ConfigMerger';
import { Origin, URL } from '@submodules/cawst-core/types';

export const deploymentTargets: DeploymentTarget[] = ['dev', 'prod', 'local'];

export interface ProjectData {
  apiBaseRoute?: string;
  assetBaseUrls?: {
    dev: URL;
    prod: URL;
  };
  deploymentTargetOrigins: {
    dev: Origin;
    prod: Origin;
    local?: Origin;
  };
  port: string;
  shortName?: string;
  urls?: { [target: string]: URL };
}

export interface Projects {
  [projectKey: string]: ProjectData;
}

// Add local deployment target origins, which can be computed automatically
function addLocalDeploymentTargetOrigins(projs: Projects) {
  for (const projectKey of Object.keys(projs)) {
    const project = projs[projectKey];
    project.deploymentTargetOrigins.local = `http://localhost:${project.port}`;
  }
}

const projects: Projects = {
  'bsf-kb-site': {
    apiBaseRoute: '/bsfkb',
    assetBaseUrls: {
      dev: 'https://s3-us-west-2.amazonaws.com/cawst-bsf-dev/',
      prod: 'https://s3-us-west-2.amazonaws.com/cawst-bsf-prod/',
    },
    deploymentTargetOrigins: {
      dev: 'https://bsfkb.cawstdev.org',
      prod: 'https://biosandfilters.info',
    },
    port: '3006',
    shortName: 'bsfkb',
  },
  'hwts-site': {
    apiBaseRoute: '/hwts',
    assetBaseUrls: {
      dev: 'https://s3-us-west-2.amazonaws.com/cawst-hwts-dev/',
      prod: 'https://s3-us-west-2.amazonaws.com/cawst-hwts-prod/',
    },
    deploymentTargetOrigins: {
      dev: 'https://hwts.cawstdev.org',
      prod: 'https://hwts.info',
    },
    port: '3002',
    shortName: 'hwts',
  },
  'main-site': {
    apiBaseRoute: '/main/public',
    assetBaseUrls: {
      dev: 'https://cawst-mainwebsite-dev-public.s3.amazonaws.com/',
      prod: 'https://cawst-mainwebsite-public.s3.amazonaws.com/',
    },
    deploymentTargetOrigins: {
      dev: 'https://www.cawstdev.org',
      prod: 'https://www.cawst.org',
    },
    port: '3000',
    shortName: 'main',
  },
  'vwet-api': {
    deploymentTargetOrigins: {
      dev: 'https://api.cawstdev.org',
      prod: 'https://api.cawst.org',
    },
    port: '3003',
  },
  'wash-resources-site': {
    apiBaseRoute: '/wash',
    assetBaseUrls: {
      dev: 'https://s3-us-west-2.amazonaws.com/cawst-wash-dev/',
      prod: 'https://s3-us-west-2.amazonaws.com/cawst-wash-prod/',
    },
    deploymentTargetOrigins: {
      dev: 'https://resources.cawstdev.org',
      prod: 'https://resources.cawst.org',
    },
    port: '3004',
    shortName: 'wash',
  },
  'web-manager-site': {
    // aka `MAIN_IMAGE_URI_PREFIX`
    assetBaseUrls: {
      dev: 'https://cawst-mainwebsite-dev-public.s3.amazonaws.com/',
      prod: 'https://cawst-mainwebsite-public.s3.amazonaws.com/',
    },
    deploymentTargetOrigins: {
      dev: 'https://manager.cawstdev.org',
      prod: 'https://manager.cawst.org',
    },
    port: '3001',
    shortName: 'manager',
  },
};

addLocalDeploymentTargetOrigins(projects);

export { projects };
