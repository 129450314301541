/* tslint:disable:no-console */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { clone, cloneDeep, findIndex } from 'lodash';
import * as moment from 'moment';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular.module('app.controllers').controller('WashItemController', [
  '$element',
  '$http',
  '$rootScope',
  '$scope',
  '$state',
  '$uiViewScroll',
  '$window',
  'authService',
  'GlobalMessageService',
  'SweetAlertUtil',
  'WASH_Data',
  'WASH_Utilities',
  'WASH_Resource',
  'WASH_Document',
  function(
    $element,
    $http,
    $rootScope,
    $scope,
    $state,
    $uiViewScroll,
    $window,
    authService,
    GlobalMessageService,
    SweetAlertUtil,
    WASH_Data,
    WASH_Utilities,
    WASH_Resource,
    WASH_Document,
  ) {
    $scope.loading = true;
    $scope.language = 'en';
    $scope.resource = {};
    $scope.regions = WASH_Data.regions;
    $scope.wash_url = deploymentConfig.origins.washSite;

    $scope.viewResource = {
      filterChildren: '',
      headerText: 'View and edit resource',
      removeEditFunctionality: false,
      washDefinitions: WASH_Data.washDefinitions,
    };

    $scope.scrollToAnchor = function(elementId) {
      $uiViewScroll($element.find('#' + elementId));
    };
    // /////////////////////////////////////////////////////////////////////////
    // # LOAD RESOURCE
    $scope.loadResource = function() {
      const resourceId = $state.params.resourceId;

      WASH_Resource.getResource(resourceId, (data) => {
        $scope.resource = data ? data : null;
        $scope.loading = false;

        if ($scope.resource.publicationStatus === 'archived') {
          $scope.viewResource.removeEditFunctionality = true;
        }
      });
    };

    $scope.loadResource();
    // /////////////////////////////////////////////////////////////////////////
    // --- # Archived states ---
    $scope.viewResource.showDocumentArchived = function(document) {
      if (document.publicationStatus !== 'published') {
        return true;
      }
      return $scope.resource.publicationStatus !== 'published';
    };

    // --- # Recently modified ---
    $scope.viewResource.recentlyModified = function(modifiedDate) {
      const compareDate = moment().subtract(1, 'day');
      return moment(modifiedDate).isAfter(compareDate);
    };

    // --- # Sub resource states ---
    $scope.viewResource.requiresChildren = function() {
      let required = false;
      if ($scope.resource.hasOwnProperty('category') && $scope.resource.hasOwnProperty('type')) {
        if (
          $scope.resource.category !== 'Simple' &&
          $scope.resource.category !== null &&
          $scope.resource.type !== 'Appendix' &&
          $scope.resource.type !== 'Section'
        ) {
          required = true;
        }
      }
      return required;
    };

    $scope.viewResource.hasChildren = function() {
      return (
        $scope.resource.hasOwnProperty('children') &&
        typeof $scope.resource.children === 'object' &&
        $scope.resource.children.length > 0
      );
    };

    $scope.viewResource.requiresOrHasChildren = function() {
      return !!($scope.viewResource.requiresChildren() || $scope.viewResource.hasChildren());
    };

    $scope.viewResource.requiresAndNoChildren = function() {
      return $scope.viewResource.requiresChildren() && !$scope.viewResource.hasChildren();
    };

    // --- # Form states ---
    // NOTE: Can only have one processing message at a time.
    // Remove edit capabilities in form while processing.
    let processingMessageIndex;

    const removeProcessingMessage = function() {
      // console.log('Removing index:', processingMessageIndex);
      if (processingMessageIndex >= 0) {
        GlobalMessageService.clearMessage(processingMessageIndex, (_allMessages) => {
          // console.log(allMessages);
        });
      } else {
        // console.log('No processing message to remove.');
      }
    };

    const viewResourceFormProcessing = function(message) {
      $scope.viewResource.removeEditFunctionality = true;
      GlobalMessageService.addMessage(message, 'processing', false, (index) => {
        // console.log('viewResourceFormProcessing, index:', index);
        processingMessageIndex = index;
      });
    };

    const viewResourceFormSuccess = function(message) {
      $scope.viewResource.removeEditFunctionality = false;
      removeProcessingMessage();
      GlobalMessageService.addMessage(message, 'success', true, (_index) => {
        // console.log('viewResourceFormSuccess, index:', index);
      });
    };

    const viewResourceFormError = function(message) {
      $scope.viewResource.removeEditFunctionality = false;
      removeProcessingMessage();
      GlobalMessageService.addMessage(message, 'error', true, (_index) => {
        // console.log('viewResourceFormError, index:', index);
      });
    };

    $scope.viewResource.delete = function() {
      SweetAlertUtil.confirm(
        'Are you sure you want to delete this resource? This change is PERMANENT. Select OK to continue.',
      ).then(
        (confirm) => {
          if (confirm) {
            WASH_Resource.removeResource($scope.resource.id, () => {
              $state.go('wash.list');
            });
          }
        },
        (p) => {
          /* fail */
        },
      );
    };

    $scope.viewResource.deleteDocument = function(documentId) {
      SweetAlertUtil.confirm('Are you sure you want to delete this document?').then(
        (confirm) => {
          if (confirm) {
            viewResourceFormProcessing('Removing document...');
            WASH_Document.removeDocument(documentId, (response) => {
              if (response) {
                for (let i = 0; i < $scope.resource.documents.length; i += 1) {
                  if ($scope.resource.documents[i].id === documentId) {
                    $scope.resource.documents.splice(i, 1);
                    break;
                  }
                }
                viewResourceFormSuccess('Removed document.');
              } else {
                viewResourceFormError('Could not remove document.');
              }
            });
          }
        },
        (p) => {
          /* fail */
        },
      );
    };

    $scope.viewResource.archiveDocument = function(doc) {
      SweetAlertUtil.confirm('Are you sure you want to archive this document?').then(
        (confirm) => {
          if (confirm) {
            viewResourceFormProcessing('Archiving document...');
            const clonedDoc = cloneDeep(doc);
            clonedDoc.publicationStatus = 'archived';
            WASH_Document.editDocument($scope.resource.id, clonedDoc, (_data) => {
              viewResourceFormSuccess('Document archived.');
              doc.publicationStatus = 'archived';
            });
          }
        },
        (p) => {
          /* fail */
        },
      );
    };

    $scope.viewResource.publishDocument = function(doc) {
      SweetAlertUtil.confirm('Are you sure you want to publish this document?').then(
        (confirm) => {
          if (confirm) {
            viewResourceFormProcessing('Publishing document...');
            const clonedDoc = cloneDeep(doc);
            clonedDoc.publicationStatus = 'published';
            WASH_Document.editDocument($scope.resource.id, clonedDoc, (data) => {
              viewResourceFormSuccess('Document published.');
              doc.publicationStatus = 'published';
            });
          }
        },
        (p) => {
          /* fail */
        },
      );
    };

    $scope.viewResource.zipCollection = function(lang, region) {
      SweetAlertUtil.prompt('What do you want the filename to be?', 'filename_es').then(
        (value) => {
          console.log('value:', value);
          if (value) {
            const filename = value.endsWith('.zip') ? value : value + '.zip';
            SweetAlertUtil.success('Creating ' + filename);
            viewResourceFormProcessing('Sending request to zip collection...');
            WASH_Document.zipCollection($scope.resource.id, lang, region, filename, (data) => {
              if (data) {
                viewResourceFormSuccess(
                  'Successfully sent request to zip collection. The zipped collection will be available in between 10s and 10 min.',
                );
              } else {
                viewResourceFormError('Could not create zip collection.');
              }
            });
          }
        },
        (e) => {
          /* fail */
        },
      );
    };

    // --- # Change publication status ---
    $scope.viewResource.changeResourceStatus = function(status) {
      const resourceToChange = clone($scope.resource);

      if (status === 'archived') {
        SweetAlertUtil.confirm(
          'Are you sure you want to archive this resource? This resource and documents will no longer be available on WASH Resources.',
        ).then(
          (confirm) => {
            if (confirm) {
              viewResourceFormProcessing('Archiving ' + resourceToChange.title + '...');
              resourceToChange.publicationStatus = 'archived';
              WASH_Resource.editResource(resourceToChange, (data) => {
                if (data) {
                  viewResourceFormSuccess(resourceToChange.title + ' archived!');
                  $scope.resource = data;
                } else {
                  viewResourceFormError('Error: Could not archive ' + resourceToChange.title);
                }
              });
            }
          },
          (p) => {
            /* fail */
          },
        );
      } else if (status === 'published') {
        if (resourceToChange.documents.length <= 0) {
          viewResourceFormError('At least one document is required to publish.');
          return;
        }
        if ($scope.viewResource.requiresAndNoChildren()) {
          viewResourceFormError('At least one sub resource is required to publish.');
          return;
        }
        SweetAlertUtil.confirm(
          'Are you sure you want to publish this resource? It will be available on WASH Resources.',
        ).then(
          (confirm) => {
            if (confirm) {
              viewResourceFormProcessing('Publishing ' + resourceToChange.title + '...');
              resourceToChange.publicationStatus = 'published';
              WASH_Resource.editResource(resourceToChange, (data) => {
                if (data) {
                  viewResourceFormSuccess(resourceToChange.title + ' published!');
                  $scope.resource = data;
                } else {
                  viewResourceFormError('Error: Could not publish ' + resourceToChange.title);
                }
              });
            }
          },
          (p) => {
            /* fail */
          },
        );
      }
    };

    // --- # Add/remove sub resources ---
    $scope.viewResource.removeChild = function(resourceId, childId) {
      const childrenArray = [childId];
      const childIndex = findIndex($scope.resource.children, { id: childId });
      const childTitle = $scope.resource.children[childIndex].title;

      SweetAlertUtil.confirm('Are you sure you want to remove this sub resource?').then(
        (confirm) => {
          if (confirm) {
            $scope.scrollToAnchor('viewResourceTopAnchor');
            viewResourceFormProcessing('Removing ' + childTitle + '.');
            WASH_Resource.removeResourceChildren(resourceId, childrenArray, (response) => {
              if (response) {
                viewResourceFormSuccess(
                  'Successfully removed ' + childTitle + ' from sub resources.',
                );
                $scope.resource = response;
              } else {
                viewResourceFormError('Could not remove ' + childTitle + '.');
              }
            });
          }
        },
        (p) => {
          /* fail */
        },
      );
    };

    // --- # Misc page functions ---
    $scope.formatDate = function(date) {
      return moment(date).format('MMMM, YYYY');
    };

    $scope.formatDateLong = function(date) {
      return moment(date).format('LLL');
    };

    $scope.removeFilePath = function(fileUri) {
      // File path is 'files/', i.e. 'files/HWTS Product Sheet_2011-06_en.pdf'
      const fileName = fileUri.replace('files/', '');
      return fileName;
    };

    $scope.downloadFile = function(file) {
      if (file.type === 'link') {
        $window.open(file.uri);
      } else {
        $window.open(
          deploymentConfig.api.baseUrls.wash +
            '/file?uri=' +
            file.uri +
            '&authorization=' +
            authService.retrieveAccessToken(),
        );
      }
    };

    $scope.createSnapshot = function() {
      SweetAlertUtil.prompt('Please enter a descriptive name for the snapshot:', '').then(
        (value) => {
          if (value) {
            SweetAlertUtil.success('Snapshot created');
            WASH_Utilities.createSnapshot($state.params.resourceId, value, (response) => {
              $window.location.reload();
            });
          }
        },
        (e) => {
          /* fail */
        },
      );
    };
  },
]); // end WASH Controllers
