import * as angular from 'angular';

angular
  .module('app') //
  .run([
    '$rootScope',
    // tslint:disable-next-line:only-arrow-functions
    function($rootScope) {
      // //////////////////////////////////////////////////////////////////////////
      $rootScope.dateProperties = [
        'dateCreated',
        'lastModified',
        'endDate',
        'startDate',
        'publicationDate',
      ];
    },
  ]);
