/* tslint:disable:no-console */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import * as moment from 'moment';

angular.module('app.controllers').controller('HwtsFileCtrl', [
  '$uibModal',
  '$scope',
  '$state',
  '$http',
  'HWTS_Utilities',
  '$stateParams',
  '$window',
  'store',
  '$sce',
  '$q',
  '$rootScope',
  function(
    $uibModal,
    $scope,
    $state,
    $http,
    HWTS_Utilities,
    $stateParams,
    $window,
    store,
    $sce,
    $q,
    $rootScope,
  ) {
    $rootScope.fileController = $scope;
    $scope.flowUploading = false;

    $scope.onLoadItem = function() {
      // do nothing
    };

    $scope.downloadFile = function(file) {
      console.log(file);
      if (file.new) HWTS_Utilities.downloadUploadedFile(file.uri);
      else HWTS_Utilities.downloadFile(file.uri);
    };

    $scope.removeFile = function(file) {
      if (!$window.confirm('Are you sure you want to delete this file?')) return;

      for (const i of Object.keys($rootScope.item.files)) {
        if ($rootScope.item.files[i].uri === file.uri) {
          $rootScope.item.files.splice(i, 1);
          break;
        }
      }

      if (file.new) {
        HWTS_Utilities.removeUploadedFile(file.uri, (_res) => {
          $scope.onFileRemoved();
        });
      } else {
        $scope.onFileRemoved();
      }
    };

    $scope.onFileAdded = function($file, $message, $flow) {
      if ($file.size >= 50 * 1024 * 1024) {
        $window.alert('File exceeds size limit of 50MB.');
        return false;
      }
      $scope.flowUploading = true;
      HWTS_Utilities.addFileUpload($file.name, (res) => {
        $file.url = res.url;
        $file.uri = HWTS_Utilities.getUriFromUrl(res.url);
        $flow.opts.target = res.url;
        $flow.upload();
      });
    };
    $scope.onFileRemoved = function() {
      // do nothing
    };
    $scope.onFileSuccess = function($file, $message, $flow) {
      if (!$rootScope.item.files) $rootScope.item.files = [];

      $rootScope.item.files.push({
        name: $file.name,
        type: $file.file.type,
        uri: $file.uri,
        size: $file.size,
        upload_time: moment().toISOString(),

        title: '',

        new: true,
      });
      $file.cancel();
      $scope.fileListFocusIndex = $rootScope.item.files.length - 1;
      $scope.flowUploading = false;

      console.log($rootScope.item);
    };
    $scope.onFileError = function($file, $message, $flow) {
      console.error($file, $message);
    };
  },
]);
