import * as angular from 'angular';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.services')
  .service('ApiRequestService', [
    'store',
    // Don't use an arrow function here (or in any Angular constructor call)
    // tslint:disable-next-line:only-arrow-functions
    function(store) {
      function setToken(accessToken: string): void {
        store.set('access_token', accessToken);
      }

      const whitelistedURLs = [deploymentConfig.api.origin];

      function addAuthorizationHeader(config) {
        const accessToken: string = getToken();
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }

      function addUserIdHeader(config): void {
        const profile = store.get('profile');
        if (profile && profile.userId) {
          config.headers['X-User-ID'] = profile.userId;
        }
      }

      function getToken(): string {
        return store.get('access_token');
      }

      function isWhitelisted(url: string): boolean {
        for (const whitelistedURL of whitelistedURLs) {
          if (url.includes(whitelistedURL)) {
            return true;
          }
        }
        return false;
      }

      function request(config) {
        // TODO: Add headers only when user is logged in.
        if (isWhitelisted(config.url)) {
          addAuthorizationHeader(config);
          addUserIdHeader(config);
        }
        return config;
      }

      return {
        setToken,
        getToken,
        request,
      };
    },
  ])

  .config([
    '$httpProvider',
    ($httpProvider) => {
      $httpProvider.interceptors.push('ApiRequestService');
    },
  ]);
