/* tslint:disable:no-parameter-reassignment */
/* tslint:disable:only-arrow-functions */
import * as angular from 'angular';
import { find } from 'lodash';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.services') //
  .service('WASH_Utilities', [
    '$http',
    '$rootScope',
    '$state',
    '$window',
    'authService',
    'WASH_Data',
    'WASH_HttpWrapper',
    function($http, $rootScope, $state, $window, authService, WASH_Data, WASH_HttpWrapper) {
      const httpWrapper = WASH_HttpWrapper.httpWrapper;

      return {
        // /////////////////////////////////////////////////////////////////////
        getUriFromUrl(url) {
          let ret = url.substr(0, url.indexOf('?'));
          // The next lines fails because window.uri_prefix does not always match the
          // prefix of the passed URL.
          // ret = ret.substr(window.uri_prefix.length, ret.length - window.uri_prefix.length);

          // Not thoroughly tested.
          // But this should work for all prefixes in the format 'http[s]://domain.tld/'.
          ret = ret.replace(/^.*\/\/[^\/]+/, '').slice(1);
          return decodeURIComponent(ret);
        },

        getUrlFromUri(uri) {
          return deploymentConfig.resources.assetBaseUrls.hwts + '/' + uri;
        },
        // /////////////////////////////////////////////////////////////////////
        // # FILE DOWNLOADS
        addFileUpload(filenames, cb) {
          httpWrapper('PUT', '/file', cb, filenames);
        },

        addLogoUpload(filenames, cb) {
          httpWrapper('PUT', '/logo', cb, filenames);
        },

        downloadFile(uri) {
          $window.open(
            deploymentConfig.api.baseUrls.wash +
              '/file?uri=' +
              uri +
              '&authorization=' +
              authService.retrieveAccessToken(),
          );
        },

        downloadUploadedFile(uri) {
          $window.open(
            deploymentConfig.api.baseUrls.wash +
              '/file?uri=' +
              uri +
              '&authorization=' +
              authService.retrieveAccessToken() +
              '&uploaded=true',
          );
        },

        removeUploadedFile(uri, cb) {
          httpWrapper('DELETE', '/file?uri=' + uri, cb);
        },

        removeSavedFile(uri, cb) {
          httpWrapper('DELETE', '/file?uri=' + uri, cb);
        },
        // /////////////////////////////////////////////////////////////////////
        // # SNAPSHOTS
        createSnapshot(id, name, cb) {
          const url =
            deploymentConfig.api.baseUrls.wash +
            '/resource/' +
            id +
            '/snapshot?name=' +
            encodeURIComponent(name);

          $http.post(url).then(
            (response) => {
              cb(response.data);
            },
            (err) => {
              return err ? err.data : 'Error: createSnapshot';
            },
          );
        },

        getSnapshots(id, cb) {
          const url = deploymentConfig.api.baseUrls.wash + '/resource/' + id + '/snapshot';

          $http.get(url).then(
            (response) => {
              cb(response.data);
            },
            (err) => {
              return err ? err.data : 'Error: getSnapshots';
            },
          );
        },
        // /////////////////////////////////////////////////////////////////////
        getFileIconClass(fileType) {
          const fileMimeType = WASH_Data.fileMimeType;
          let iconClass = 'fa-file-o';
          if (fileType) {
            for (const key of Object.keys(fileMimeType)) {
              const mimeType = fileMimeType[key];
              if (fileType === mimeType.type) {
                iconClass = mimeType.icon;
              }
            }
          }
          return iconClass;
        },

        hasHighResImage(docFiles) {
          const highResImageTypes = WASH_Data.highResImageTypes;
          let hasFile = false;
          if (docFiles) {
            for (const key of Object.keys(docFiles)) {
              if (highResImageTypes.indexOf(docFiles[key].type) >= 0) hasFile = true;
            }
          }
          return hasFile;
        },

        hasInstructions(docFiles) {
          const uploadInstructionTypes = WASH_Data.uploadInstructionTypes;
          let hasFile = false;
          if (docFiles) {
            for (const key of Object.keys(docFiles)) {
              if (uploadInstructionTypes.indexOf(docFiles[key].type) >= 0) {
                hasFile = true;
              }
            }
          }
          return hasFile;
        },

        isHighResImage(mimetype) {
          const highResImageTypes = WASH_Data.highResImageTypes;
          return highResImageTypes.indexOf(mimetype) >= 0;
        },

        isImageMimeType(mimetype) {
          const imageMimeTypes = WASH_Data.imageMimeTypes;
          return imageMimeTypes.indexOf(mimetype) >= 0;
        },

        isImageType(resourceType) {
          const resourceTypes = WASH_Data.resourceTypes;
          let isImage = false;
          if (resourceType) {
            const typeDetails = resourceTypes.find((type) => {
              return type.value === resourceType;
            });
            isImage = typeDetails.image;
          }
          return isImage;
        },

        isInstructions(mimetype) {
          const uploadInstructionTypes = WASH_Data.uploadInstructionTypes;
          return uploadInstructionTypes.indexOf(mimetype) >= 0;
        },
        // /////////////////////////////////////////////////////////////////////
      };
    },
  ]);
