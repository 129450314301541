/* tslint:disable:no-bitwise */
/* tslint:disable:no-console */
/* tslint:disable:no-parameter-reassignment */
/* tslint:disable:no-this-assignment */

((factory) => {
  /* global define */
  // @ts-ignore
  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module.
    // @ts-ignore
    define(['jquery'], factory);
  } else if (typeof module === 'object' && module.exports) {
    // Node/CommonJS
    module.exports = factory(require('jquery'));
  } else {
    // Browser globals
    factory(window.jQuery);
  }
})(($) => {
  $.extend($.summernote.plugins, {
    reference(context) {
      const self = this;
      const ui = $.summernote.ui;

      const $editor = context.layoutInfo.editor;
      const options = context.options;
      const $scope = angular.element(document.getElementById('itemForm')).scope();

      context.memo('button.reference', () => {
        return ui
          .button({
            contents: '<i class="fa fa-at">',
            tooltip: 'Add CAWST Reference',
            click: self.onClickToolbarButton,
          })
          .render();
      });

      this.onClickToolbarButton = () => {
        self.showDialog();
      };

      this.insertReference = (ref) => {
        if (ref.referenceGuid) {
          $($editor)
            .find('reference')
            .each(function() {
              const referenceGuid = $(this).attr('reference-guid');
              if (ref.referenceGuid === referenceGuid) {
                $(this).attr('reference-id', ref.referenceId);
                $(this).attr('reference-type', ref.referenceType);
                context.triggerEvent('change');
                return false;
              }
            });
        } else {
          ref = $(
            '<span><reference style="text-decoration:underline;" reference-guid="' +
              self.generateUUID() +
              '"reference-id="' +
              ref.referenceId +
              '"reference-type="' +
              ref.referenceType +
              '">@</reference><span>&nbsp;</span></span>',
          );
          console.log(ref);
          context.invoke('editor.insertNode', ref.get(0));
        }
      };

      this.showDialog = (ref) => {
        if (!ref) {
          ref = {
            referenceGuid: null,
            referenceId: null,
          };
        }
        context.invoke('editor.saveRange');
        // @ts-ignore
        $scope.showReferenceDialog(ref, (response) => {
          context.invoke('editor.restoreRange');
          if (response) self.insertReference(response);
        });
      };

      this.attachHandlers = () => {
        $editor.find('reference').each(function() {
          $(this).unbind();
          $(this).removeAttr('reference-guid');
          $(this).attr('reference-guid', self.generateUUID());
        });
        $editor.find('reference').click(function() {
          const referenceGuid = $(this).attr('reference-guid');
          const referenceId = $(this).attr('reference-id');
          const referenceType = $(this).attr('reference-type');
          if (referenceGuid) {
            self.showDialog({
              referenceGuid,
              referenceId,
              referenceType,
            });
          }
        });
      };

      this.events = {
        'summernote.change': (_we, _e) => {
          setTimeout(() => {
            self.attachHandlers();
          }, 0);
        },
      };

      this.initialize = () => {
        // do nothing
      };

      this.generateUUID = () => {
        let d = new Date().getTime();
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          const r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        });
        return uuid;
      };
    },
  });
});
