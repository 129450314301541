/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';

angular
  .module('app.controllers') //
  .controller('SummernoteReferenceDialogCtrl', [
    '$http',
    '$scope',
    '$uibModalInstance',
    'HWTS_Utilities',
    'referenceGuid',
    'referenceId',
    'referenceType',
    function(
      $http,
      $scope,
      $uibModalInstance,
      HWTS_Utilities,
      referenceGuid,
      referenceId,
      referenceType,
    ) {
      $scope.referenceGuid = referenceGuid;
      $scope.referenceId = referenceId;
      $scope.referenceType = referenceType;
      $scope.document = null;
      $scope.documentText = null;
      $scope.itemCtrlScope = angular.element(document.getElementById('itemForm')).scope();

      if ($scope.referenceType && $scope.referenceId) {
        HWTS_Utilities.get($scope.referenceType, $scope.referenceId, (response) => {
          if (response) $scope.document = response;
        });
      }

      $scope.ok = function() {
        $uibModalInstance.close({
          referenceId: $scope.referenceId,
          referenceGuid: $scope.referenceGuid,
          referenceType: $scope.referenceType,
        });
      };

      $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.searchItems = function(type, value) {
        return $scope.itemCtrlScope.searchItems(type, value);
      };

      $scope.onSelect = function($item, $model, $label, $event) {
        $scope.document = $item;
        $scope.referenceId = $item.id;
        $scope.referenceType = $item.type;
        $scope.updateDocumentText();
      };

      $scope.updateDocumentText = function() {
        if ($scope.document) $scope.documentText = JSON.stringify($scope.document, null, '   ');
        else $scope.documentText = '';
      };
    },
  ]);
