/* tslint:disable:only-arrow-functions */
/* tslint:disable:prefer-for-of */

import * as angular from 'angular';
import * as moment from 'moment';

import { deploymentConfig } from '@src/client/config/deploymentConfig';
import { getFileSize } from '@submodules/cawst-angularjs/utils/file/getFileSize';
import { URL } from '@submodules/cawst-core/types';

function isUrl(possibleUrl: string): boolean {
  // TODO: Also test for '#'?
  return possibleUrl.slice(0, 4) === 'http';
}

// Given a URL, return the last segment
function getLastSegmentFromUrl(url: URL): string {
  const segments: string[] = url.split('/');
  return segments.slice(-1)[0];
}

// if (washId.indexOf('http') !== -1 || washId.indexOf('#') !== -1) {
//   const urlArr = washId.split('/');
//   const idIndex = urlArr.length - 1;
//   washId = urlArr[idIndex];
// }

angular.module('app.controllers').controller('partnersClientsDetailCtrl', [
  '$http',
  '$rootScope',
  '$scope',
  '$state',
  '$window',
  'PartnersClients_Utilities',
  'User',
  function($http, $rootScope, $scope, $state, $window, PartnersClients_Utilities, User) {
    $scope.previewUrl = deploymentConfig.origins.mainSite + '/about/partners-clients';

    const centreId = $state.params.id;
    const socialMedias = [
      {
        type: 'Facebook',
        url: '',
      },
      {
        type: 'Twitter',
        url: '',
      },
      {
        type: 'YouTube',
        url: '',
      },
      {
        type: 'Instagram',
        url: '',
      },
      {
        type: 'LinkedIn',
        url: '',
      },
    ];
    const stats = [
      {
        number: null,
        phrase: 'People with better water & sanitation',
      },
      {
        number: null,
        phrase: 'People trained',
      },
      {
        number: null,
        phrase: 'People implementing',
      },
    ];
    $scope.loading = true;
    $scope.loadingWashDoc = false;
    $scope.action = $state.current.name.split('.')[1];
    $scope.saving = false;
    $scope.saveSuccessMessage = '';
    $scope.saveErrorMessage = '';
    $scope.imagesPrefix = deploymentConfig.resources.imageBaseUrls.main + '/';
    $scope.editingResource = false;
    $scope.editingExistingResource = false;
    $scope.resource = {};
    $scope.foundWashDoc = null;
    $scope.resourceIndex = null;
    $rootScope.centreDetails = null;
    $scope.training = {};
    $scope.editingTraining = false;
    $scope.editingExistingTraining = false;
    $scope.trainingIndex = null;
    $scope.partnersClientsTypeMap = PartnersClients_Utilities.partnersClientsTypeMap;
    // /////////////////////////////////////////////////////////////////////////
    function loadItem(item) {
      item.lastModified = moment(item.lastModified).toDate();
      return item;
    }
    // /////////////////////////////////////////////////////////////////////////
    function saveModifiedProperties(item) {
      if (!item.lastModified) {
        item.lastModified = moment();
      }
      item.lastModified = moment(item.lastModified).toISOString();
      item.lastModifiedBy = User.retrieveProfile().email || '';
      return item;
    }
    // /////////////////////////////////////////////////////////////////////////
    function getCountries() {
      const url = deploymentConfig.api.baseUrls.vwet + '/country/all';
      const countryList = [];
      $http.get(url).then(
        (response) => {
          response.data.forEach((country) => {
            countryList.push(country.name);
          });
          $scope.countryList = countryList;
        },
        (err) => {
          $window.alert(err.data);
        },
      );
    }
    // /////////////////////////////////////////////////////////////////////////
    PartnersClients_Utilities.getOne(centreId, (response) => {
      if (response) {
        $rootScope.centreDetails = loadItem(response);
        if (!$rootScope.centreDetails.hasOwnProperty('social_media')) {
          $rootScope.centreDetails.social_media = socialMedias;
        } else if ($rootScope.centreDetails.social_media !== socialMedias.length) {
          const currentSocialTypes = [];
          const missingSocials = [];
          for (let i = 0; i < $rootScope.centreDetails.social_media.length; i += 1) {
            currentSocialTypes.push($rootScope.centreDetails.social_media[i].type);
          }
          for (let j = 0; j < socialMedias.length; j += 1) {
            if (currentSocialTypes.indexOf(socialMedias[j].type) === -1) {
              missingSocials.push(socialMedias[j]);
            }
          }
          missingSocials.forEach((item) => {
            $rootScope.centreDetails.social_media.push(item);
          });
        }
        if (!$rootScope.centreDetails.hasOwnProperty('stats')) {
          $rootScope.centreDetails.stats = stats;
        }
        if (!$rootScope.centreDetails.hasOwnProperty('resources')) {
          $rootScope.centreDetails.resources = [];
        }
        if (!$rootScope.centreDetails.hasOwnProperty('externalTraining')) {
          $rootScope.centreDetails.externalTraining = [];
        }
        if ($rootScope.centreDetails.altResources) {
          $scope.altResources = true;
        }
        $scope.loading = false;
      } else {
        $window.alert('Item unavailable');
        $state.go('partnersclients.list', {}, { reload: true });
      }
      getCountries();
    });
    // /////////////////////////////////////////////////////////////////////////
    $scope.save = function() {
      $scope.saveSuccessMessage = '';
      $scope.saveErrorMessage = '';
      $scope.saving = true;

      let item = $rootScope.centreDetails;

      item = saveModifiedProperties(item);

      if (item.website.indexOf('mailto:') !== -1) {
        item.website_pretty = 'Not Available';
      } else {
        item.website_pretty = item.website.replace(/(^\w+:|^)\/\//, '');
      }

      if ($scope.action === 'edit') {
        PartnersClients_Utilities.updateItem(item, (response) => {
          if (response) {
            $scope.saving = false;
            $scope.saveSuccessMessage = 'Changes saved.';
          } else {
            $scope.saving = false;
            $scope.saveErrorMessage =
              'Unable to save your changes. Contact Virtual Services for assistance.';
          }
        });
      }
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.cancel = function() {
      $scope.cancelResource();
      $scope.cancelTraining();
      $state.go('partnersclients.list', {}, { reload: true });
    };
    // /////////////////////////////////////////////////////////////////////////////
    // # Resources Management
    // /////////////////////////////////////////////////////////////////////////////
    $scope.saveResource = function() {
      if (!$scope.editingExistingResource) {
        const newResource: any = {}; // TOTYPE
        if ($scope.resourceType === 'external') {
          newResource.doc_title = $scope.resource.doc_title || '';
          newResource.doc_description = $scope.resource.doc_description || '';
          newResource.doc_url = $scope.resource.doc_url || '';
          newResource.doc_type = 'external';
        } else if ($scope.resourceType === 'wash') {
          newResource.doc_title = $scope.foundWashDoc.title || '';
          newResource.doc_description = $scope.foundWashDoc.description || '';
          newResource.doc_url = $scope.foundWashDoc.url || '';
          newResource.doc_type = 'wash';
          newResource.wash_doc_id = $scope.foundWashDoc.id || '';
        }
        $rootScope.centreDetails.resources.push(newResource);
        $scope.resource = {};
        $scope.foundWashDoc = null;
      } else {
        $rootScope.centreDetails.resources[$scope.resourceIndex] = $scope.resource;
        $scope.editingExistingResource = false;
      }
      $scope.editingResource = false;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.editResource = (type, index?, resource?) => {
      if (resource) {
        $scope.resource = resource;
        $scope.resourceIndex = index;
        $scope.editingExistingResource = true;
        $scope.resourceBtnMessage = 'Save';
        $scope.resourceEditMessage =
          'Edit ' + type.charAt(0).toUpperCase() + type.slice(1) + ' Resource';
      } else {
        $scope.resourceBtnMessage = 'Add';
        $scope.resourceEditMessage =
          'Add ' + type.charAt(0).toUpperCase() + type.slice(1) + ' Resource';
      }
      $scope.editingResource = true;
      $scope.resourceType = type;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.cancelResource = function() {
      $scope.editingResource = false;
      $scope.resource.wash_doc_id = '';
      $scope.foundWashDoc = null;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.deleteResource = function(index) {
      $rootScope.centreDetails.resources.splice(index, 1);
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.toggleAltResources = function() {
      $scope.altResources = !$scope.altResources;
      if ($rootScope.centreDetails.altResources && !$scope.altResources) {
        $rootScope.centreDetails.altResources = null;
      }
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.LookUpWashResource = function() {
      $scope.loadingWashDoc = true;
      let washId = $scope.resource.wash_doc_id;

      // If the resource is a URL, parse the ID from it.
      if (isUrl(washId)) {
        // The ID must be the last segment of the path
        washId = getLastSegmentFromUrl(washId);
      }

      PartnersClients_Utilities.getWashResource(washId, (response) => {
        if (response) {
          $scope.foundWashDoc = response;
          $scope.foundWashDoc.url =
            deploymentConfig.origins.washSite +
            '/' +
            response.slugType +
            '/' +
            response.slugId +
            '/' +
            response.slug; // build URL
          $scope.loadingWashDoc = false;
        } else {
          $scope.loadingWashDoc = false;
          $window.alert('No resource was found.');
          $scope.cancelResource();
        }
      });
    };
    // /////////////////////////////////////////////////////////////////////////////
    // EXTERNAL Training MANAGEMENT
    // /////////////////////////////////////////////////////////////////////////////
    $scope.saveTraining = function() {
      if (!$scope.editingExistingTraining) {
        const newTraining: any = {}; // TOTYPE
        newTraining.training_title = $scope.training.training_title || '';
        newTraining.training_description = $scope.training.training_description || '';
        newTraining.training_url = $scope.training.training_url || '';
        $rootScope.centreDetails.externalTraining.push(newTraining);
        $scope.training = {};
      } else {
        $rootScope.centreDetails.externalTraining[$scope.trainingIndex] = $scope.training;
        $scope.editingExistingTraining = false;
      }
      $scope.editingTraining = false;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.editTraining = function(index, training) {
      if (training) {
        $scope.training = training;
        $scope.trainingIndex = index;
        $scope.editingExistingTraining = true;
        $scope.trainingBtnMessage = 'Save';
        $scope.trainingEditMessage = 'Edit Training';
      } else {
        $scope.trainingBtnMessage = 'Add';
        $scope.trainingEditMessage = 'Add Training';
      }
      $scope.editingTraining = true;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.cancelTraining = function() {
      $scope.editingTraining = false;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.deleteTraining = function(index) {
      $rootScope.centreDetails.externalTraining.splice(index, 1);
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.sort = function(prop, direction, index) {
      // index + direction (new array placement)
      $rootScope.centreDetails[prop].splice(
        index + direction,
        0,
        $rootScope.centreDetails[prop].splice(index, 1)[0],
      );
    };
    // /////////////////////////////////////////////////////////////////////////////
    // # Image Upload
    // /////////////////////////////////////////////////////////////////////////////
    function deleteFileProperties() {
      if ($rootScope.centreDetails.feature_img) {
        delete $rootScope.centreDetails.feature_img;
      }
    }
    // /////////////////////////////////////////////////////////////////////////
    $scope.deleteImage = function(profile) {
      if (!$window.confirm('Are you sure you want to delete this image?')) {
        return;
      }
      const imageUri = profile.feature_img;
      PartnersClients_Utilities.deleteImage(imageUri, (res) => {
        if (res) {
          deleteFileProperties();
        } else {
          $scope.errorMessage =
            'there was an issue deleting the image, please contact the web team for support.';
        }
      });
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.onImageAdded = function($file, $message, $flow) {
      if ($file.file.type.indexOf('image/') !== 0) {
        $window.alert('Only image files are allowed.');
        return false;
      }

      if ($file.size >= 5 * 1024 * 1024) {
        $window.alert('File exceeds size limit of 5MB.');
        return false;
      }

      PartnersClients_Utilities.getSignedUploadUrlForImage($file.name, (res) => {
        if (res) {
          $scope.file = $file;
          $file.url = res.url;
          $file.uri = PartnersClients_Utilities.getUriFromUrl(res.url);
          $flow.opts.target = res.url;
          $flow.upload();
        } else {
          $scope.errorMessage =
            'There was an issue uploading the file, please contact the web team for support.';
        }
      });
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.onImageSuccess = function($file, $message, $flow) {
      $rootScope.centreDetails.feature_img = $file.uri;
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.formatProgress = function(decimal) {
      return Math.round(decimal * 100) + '%';
    };
    // /////////////////////////////////////////////////////////////////////////
    $scope.getFileSize = getFileSize;
    // /////////////////////////////////////////////////////////////////////////
  },
]);
