// Details of CAWST

const organizationDetails = {
  address: {
    unit: 'B12',
    number: '6020',
    street: '2 Street SE',
    city: 'Calgary',
    province: 'Alberta',
    country: 'Canada',
    postalCode: 'T2H 2L8',

    provinceAbbrev: 'AB',

    latitude: 50.998414,
    longitude: -114.059343,
  },
  addressLines: {
    short: ['B12, 6020 – 2 Street SE', 'Calgary Alberta, T2H 2L8 Canada'],
    standard: ['B12, 6020 – 2 Street SE', 'Calgary, Alberta, T2H 2L8, Canada'],
    long: ['B12, 6020 – 2 Street SE', 'Calgary, Alberta', 'T2H 2L8, Canada'],
  },
  emails: {
    support: 'support@cawst.org',
  },
  phoneNumbers: {
    main: {
      formatted: '1 (403) 243-3285',
      number: 14032433285
    }
  },
  name: 'Centre for Affordable Water and Sanitation Technology',
  mapUrls: {
    google:
      'https://www.google.ca/maps/place/CAWST/@50.9984096,-114.0615499,17z/data=!3m1!4b1!4m13!1m7!3m6!1s0x537170f7ab884b9d:0x3ef3e6ddd0e05d14!2s6020+2+St+SE+B12,+Calgary,+AB+T2H+2L8!3b1!8m2!3d50.9984096!4d-114.0593612!3m4!1s0x53717ad59dcd0189:0xc81f25a27a461ed2!8m2!3d50.9984096!4d-114.0593612',
  },
  shortName: 'CAWST',
  charityNumber: '863 751 616 RR0001',
  apegaNumber: 'P-8757'
};

export { organizationDetails };
