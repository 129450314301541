// Given a list of items `Reference` and a list of items `Actual`, create an object consisting of
// { key: boolean value } pairs indicating whether items in `Actual` are contained in `Reference`

export function createInclusionMap(
  referenceList: string[],
  actualList: string[],
): { [key: string]: boolean } {
  const flags = {};
  referenceList.forEach((item) => {
    flags[item] = actualList.includes(item);
  });
  return flags;
}
