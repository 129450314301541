/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import * as moment from 'moment';

import { deploymentConfig } from '@src/client/config/deploymentConfig';

angular
  .module('app.controllers') //
  .controller('WashListController', [
    '$scope',
    'HWTS_Utilities',
    'WASH_Utilities',
    '$state',
    '$rootScope',
    '$http',
    'store',
    'SweetAlertUtil',
    function($scope, HWTS_Utilities, WASH_Utilities, $state, $rootScope, $http, store, SweetAlertUtil) {
      const defaultOrderBy = 'lastModified';

      $scope.washList = {}; // model for this controller so same named variables don't get mixed up in other controllers
      $scope.washList.headerText = 'Search and edit resources';

      // --- # Set filters and query based on $state ---
      $scope.pagination = {
        currentPage: Number($state.params.page) ? Number($state.params.page) : 1,
        itemCount: 0,
        itemsPerPage: 10,
        items: [],
      };

      $scope.searchTitle = $state.params.title ? $state.params.title : '';
      $scope.selectedOrderBy = $state.params.orderBy ? $state.params.orderBy : defaultOrderBy;
      $scope.selectedFilters = {};
      $scope.selectedFilters.status = $state.params.status ? $state.params.status : null;
      $scope.selectedFilters.featured = $state.params.featured ? $state.params.featured : null;
      $scope.selectedFilters.type = $state.params.type ? $state.params.type : null;
      $scope.selectedFilters.category = $state.params.category ? $state.params.category : null;
      $scope.selectedFilters.topic = $state.params.topic ? $state.params.topic : null;
      $scope.selectedFilters.region = $state.params.region ? $state.params.region : null;
      $scope.selectedFilters.language = $state.params.language ? $state.params.language : null;
      $scope.selectedFilters.theme = $state.params.theme ? $state.params.theme : null;

      // --- # Get list of resources based on search query and filters ---
      $scope.getItemsForCurrentPage = function() {
        $scope.loading = true;
        const page = $scope.pagination.currentPage ? $scope.pagination.currentPage : 1;
        const orderBy = $scope.selectedOrderBy ? $scope.selectedOrderBy : defaultOrderBy;
        const title = $scope.searchTitle ? $scope.searchTitle : '';
        const status = $scope.selectedFilters.status ? $scope.selectedFilters.status : '';
        const featured = $scope.selectedFilters.featured ? $scope.selectedFilters.featured : '';
        const type = $scope.selectedFilters.type ? $scope.selectedFilters.type : '';
        const category = $scope.selectedFilters.category ? $scope.selectedFilters.category : '';
        const topic = $scope.selectedFilters.topic ? $scope.selectedFilters.topic : '';
        const region = $scope.selectedFilters.region ? $scope.selectedFilters.region : '';
        const language = $scope.selectedFilters.language ? $scope.selectedFilters.language : '';

        const url =
          deploymentConfig.api.baseUrls.wash +
          '/resource/paginate/' +
          page +
          '/' +
          $scope.pagination.itemsPerPage;

        $state.go('wash.list', {
          page,
          orderBy,
          title,
          status,
          featured,
          type,
          category,
          topic,
          region,
          language,
        });

        $http
          .get(
            url +
              '?orderBy=' +
              orderBy +
              '&publicationStatus=' +
              status +
              '&title=' +
              title +
              '&featured=' +
              featured +
              '&type=' +
              type +
              '&category=' +
              category +
              '&topic=' +
              topic +
              '&region=' +
              region +
              '&language=' +
              language,
          )
          .then(
            (response) => {
              $scope.pagination.items = response.data.items;
              $scope.pagination.itemCount = response.data.totalItemCount;
              $scope.loading = false;
            },
            (err) => {
              $scope.loading = false;
              if (err.data) SweetAlertUtil.alert('Error retrieving results: ' + err.data);
            },
          );
      };

      $scope.onPageChange = function(newPageNumber) {
        $scope.pagination.currentPage = newPageNumber;
        $scope.getItemsForCurrentPage();
      };

      $scope.washList.clearFilters = function() {
        $scope.selectedOrderBy = defaultOrderBy;
        $scope.selectedFilters = {};
        $scope.getItemsForCurrentPage();
      };

      // --- # Misc page functions ---
      $scope.formatDate = function(date) {
        return moment(date).format('MMMM, YYYY');
      };

      $scope.formatDateLong = function(date) {
        return moment(date).format('LLL');
      };

      $scope.isFeaturedItem = function(item) {
        return item.featured.length > 0;
      };

      // --- # Run search on page load ---
      $scope.$on('$viewContentLoaded', () => {
        $scope.getItemsForCurrentPage();
      });
    },
  ]); // end WASH Controllers
