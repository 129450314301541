/* tslint:disable:only-arrow-functions */

(() => {
  // prettier-ignore
  angular
    .module('app')
    .config([
      'angularAuth0Provider',
      function(angularAuth0Provider) {
        // Initialization for the angular-auth0 library
        // Reference: https://auth0.com/docs/quickstart/spa/angularjs
        angularAuth0Provider.init({
          // If an audience is specified, claims must be explicitly specified; the only
          // profile data revealed by the access token is `sub` (ID provider plus ID, e.g.,
          // { sub: 'google-oauth2|108711907140321858875' }.

          // If no audience is specified, it defaults to 'cawst.auth0.com/userinfo' and
          // the access token reveals a variety of user details:
          // audience: window.appSettings.auth0.audience,
          clientID: window.appSettings.auth0.clientId,
          domain: window.appSettings.auth0.domain,
          redirectUri: window.location.origin + '/callback',
          responseType: 'token id_token',
          scope: 'openid',
        });
      },
    ]);
})();
