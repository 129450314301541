/* tslint:disable:no-parameter-reassignment */
/* tslint:disable:only-arrow-functions */

import * as angular from 'angular';
import { find, has, toArray } from 'lodash';
import * as moment from 'moment';

angular
  .module('app.services') //
  .service('TrainingEvents', [
    'fbutil',
    function(fbutil) {
      const topicIconSet = {
        HWTS: 'hwts',
        CWP: 'cwp',
      };

      const syncMyRegList = []; // FB syncarray
      const syncMyReginProg = false; // used to avoid too many sync fb calls at once

      function addId(obj) {
        for (const key of Object.keys(obj)) {
          obj[key].id = key;
        }
        return obj;
      }

      return {
        // /////////////////////////////////////////////////////////////////////
        removeEvent(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.remove('events/event_list', item.id, (_data) => {
            cb(_data);
          });
        },
        // /////////////////////////////////////////////////////////////////////
        addEvent(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.push('events/event_list', item, (_data) => {
            cb(_data);
          });
        },
        // /////////////////////////////////////////////////////////////////////
        getEvent(id, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          this.getEvents((eventsList) => {
            const foundEvent = find(eventsList, { id });
            cb(foundEvent);
          });
        },
        // /////////////////////////////////////////////////////////////////////
        updateEvent(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.set('events/event_list', item.id, item, (_data) => {
            cb(_data);
          });
        },
        // /////////////////////////////////////////////////////////////////////
        getEvents(cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.get('events/event_list/all', (data) => {
            cb(toArray(addId(data)));
          });
        },
        // /////////////////////////////////////////////////////////////////////
        getEventOrganizers(cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.get('events/event_organizers', (data) => {
            cb(toArray(addId(data)));
          });
        },
        // /////////////////////////////////////////////////////////////////////
        addEventOrganizer(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.push('events/event_organizers', item, (_data) => {
            cb(_data);
          });
        },
        // /////////////////////////////////////////////////////////////////////
        getEventVenues(cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.get('events/event_venues', (data) => {
            cb(toArray(addId(data)));
          });
        },
        // /////////////////////////////////////////////////////////////////////
        addEventVenue(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.push('events/event_venues', item, (data) => {
            cb(data);
          });
        },
        // /////////////////////////////////////////////////////////////////////
        getEventHosts(cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.get('events/event_hosts', (data) => {
            cb(toArray(addId(data)));
          });
        },
        // /////////////////////////////////////////////////////////////////////
        addEventHost(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.push('events/event_hosts', item, (_data) => {
            cb(_data);
          });
        },
        // /////////////////////////////////////////////////////////////////////
        getLanguages(cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.get('languages', (data) => {
            cb(toArray(addId(data)));
          });
        },
        // /////////////////////////////////////////////////////////////////////
        getTrainingTemplates(cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.get('training_templates', (data) => {
            cb(addId(data));
          });
        },
        // /////////////////////////////////////////////////////////////////////
        updateTemplateMaster(item, cb) {
          cb =
            cb ||
            function() {
              // do nothing
            };
          fbutil.set('training_templates', item.id, item, (_data) => {
            cb(_data);
          });
        },
        // /////////////////////////////////////////////////////////////////////
        niceEventDate(event) {
          if (has(event, 'date_start')) {
            // to avoid errors before data is available

            if (has(event, 'dateNotConfirmed') && event.dateNotConfirmed == true) {
              // if date not confirmed
              if (
                has(event, 'date_end') &&
                moment(event.date_end).month() > moment(event.date_start).month()
              ) {
                return (
                  moment(event.date_start).format('MMM') +
                  '-' +
                  moment(event.date_end).format('MMM, YYYY')
                );
              }
              return moment(event.date_start).format('MMM, YYYY');
            }

            if (!has(event, 'date_end')) {
              // if no end date
              return moment(event.date_start).format('MMM D, YYYY');
            }

            if (moment(event.date_start).format('M D') === moment(event.date_end).format('M D')) {
              // if day and month are the same only show one day
              return moment(event.date_start).format('MMM D, YYYY');
            }
            if (moment(event.date_start).format('M') === moment(event.date_end).format('M')) {
              // if same month hide month
              return (
                moment(event.date_start).format('MMM D') +
                '-' +
                moment(event.date_end).format('D, YYYY')
              );
            }
            return (
              moment(event.date_start).format('MMM D') +
              ' - ' +
              moment(event.date_end).format('MMM D, YYYY')
            );
          }
        },
        // /////////////////////////////////////////////////////////////////////
        niceDuration(event) {
          let value = null;
          let unit = null;
          if (has(event, 'date_start')) {
            if (!has(event, 'date_end')) {
              event.date_end = event.date_start;
            }
            const start = moment(event.date_start).startOf('day');
            const end = moment(event.date_end).startOf('day');
            value = end.diff(start, 'days') + 1;
            value === 1 ? (unit = ' day') : (unit = ' days');
          }
          return {
            unit,
            value,
          };
        },
        // /////////////////////////////////////////////////////////////////////
        topicIconSet,
        // /////////////////////////////////////////////////////////////////////
        topicIcon(tag) {
          if (has(topicIconSet, tag)) {
            return topicIconSet[tag];
          }
          return 'defaultTopic'; // defaultTopic.png is the default icon
        },
        // /////////////////////////////////////////////////////////////////////
      };
    },
  ]);
